import React, { useEffect, useState } from "react";
import { getDashboardData } from "helpers/mb-api_helper";

interface Stat {
  type: string;
  count: number;
}

const colors: string[] = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4CAF50",
  "#FFC107",
  "#03A9F4",
  "#E91E63",
  "#9C27B0",
  "#8BC34A",
  "#FF9800",
  "#00BCD4",
  "#673AB7",
  "#CDDC39",
  "#607D8B",
  "#795548",
];

const Dashboard: React.FC = () => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiData = await getDashboardData();
      setData(apiData);
    };

    fetchData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const totalAssets = data.assetsStats.reduce(
    (acc: number, item: Stat) => acc + item.count,
    0
  );
  const totalDraftAssets = data.draftAssetStats.reduce(
    (acc: number, item: Stat) => acc + item.count,
    0
  );
  const totalCmsStats = data.cmsStats.reduce(
    (acc: number, item: Stat) => acc + item.count,
    0
  );

  return (
    <div className="p-6 min-h-screen">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">CMS Dashboard</h1>

      {/* Assets Stats Card */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Column Chart for CMS Stats */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-bold text-gray-700 mb-4">CMS Stats</h2>

          <svg width="100%" height="250">
            {" "}
            {/* Increased height to fit titles and counts */}
            {data.cmsStats.map((item: Stat, idx: number) => {
              const barHeight = item.count * 2; // Adjust the height based on the count
              return (
                <g key={idx}>
                  {/* Bar */}
                  <rect
                    x={idx * 50} // Space columns apart
                    y={200 - barHeight} // Y-position based on the height
                    width="30" // Width of each column
                    height={barHeight} // Height based on the count
                    fill="#4CAF50" // Color for the bars
                  />

                  {/* Title (Type) */}
                  <text
                    x={idx * 50 + 15} // Center text horizontally
                    y={210} // Position text below the bar
                    textAnchor="middle" // Align text to the center
                    className="text-xs text-gray-700" // Tailwind classes for text styling
                  >
                    {item.type}
                  </text>

                  {/* Count */}
                  <text
                    x={idx * 50 + 15} // Center text horizontally
                    y={200 - barHeight - 5} // Position text above the bar
                    textAnchor="middle" // Align text to the center
                    className="text-sm font-bold text-gray-800"
                  >
                    {item.count}
                  </text>
                </g>
              );
            })}
          </svg>
        </div>

        {/* Pie Chart for Assets Stats */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-bold text-gray-700 mb-4">Assets Stats</h2>
          <svg viewBox="0 0 36 36" className="w-40 h-40 mx-auto">
            {data.assetsStats.reduce(
              (prev: JSX.Element[], item: Stat, idx: number) => {
                const radius = 16;
                const circumference = 2 * Math.PI * radius;
                const offset = prev.reduce(
                  (acc, cur) =>
                    acc + parseFloat(cur.props.strokeDasharray.split(" ")[0]),
                  0
                );
                const segmentLength =
                  (item.count / totalAssets) * circumference;
                const dashArray = `${segmentLength} ${
                  circumference - segmentLength
                }`;

                return [
                  ...prev,
                  <circle
                    key={idx}
                    r={radius}
                    cx="18"
                    cy="18"
                    fill="transparent"
                    stroke={colors[idx % colors.length]}
                    strokeWidth="2"
                    strokeDasharray={dashArray}
                    strokeDashoffset={-offset}
                    transform="rotate(-90 18 18)"
                  />,
                ];
              },
              []
            )}
          </svg>
          <div className="mt-6 space-y-2">
            {data.assetsStats.map((item: Stat, idx: number) => (
              <div key={idx} className="flex items-center gap-2">
                <span
                  className="block w-4 h-4 rounded-full"
                  style={{ backgroundColor: colors[idx % colors.length] }}
                ></span>
                <p className="text-sm text-gray-700">
                  {item.type}: <span className="font-bold">{item.count}</span>
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Draft Assets Stats Card */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-bold text-gray-700 mb-4">
            Draft Assets Stats
          </h2>
          <svg viewBox="0 0 36 36" className="w-40 h-40 mx-auto">
            {data.draftAssetStats.reduce(
              (prev: JSX.Element[], item: Stat, idx: number) => {
                const radius = 16;
                const circumference = 2 * Math.PI * radius;
                const offset = prev.reduce(
                  (acc, cur) =>
                    acc + parseFloat(cur.props.strokeDasharray.split(" ")[0]),
                  0
                );
                const segmentLength =
                  (item.count / totalDraftAssets) * circumference;
                const dashArray = `${segmentLength} ${
                  circumference - segmentLength
                }`;

                return [
                  ...prev,
                  <circle
                    key={idx}
                    r={radius}
                    cx="18"
                    cy="18"
                    fill="transparent"
                    stroke={colors[idx % colors.length]}
                    strokeWidth="2"
                    strokeDasharray={dashArray}
                    strokeDashoffset={-offset}
                    transform="rotate(-90 18 18)"
                  />,
                ];
              },
              []
            )}
          </svg>
          <div className="mt-6 space-y-2">
            {data.draftAssetStats.map((item: Stat, idx: number) => (
              <div key={idx} className="flex items-center gap-2">
                <span
                  className="block w-4 h-4 rounded-full"
                  style={{ backgroundColor: colors[idx % colors.length] }}
                ></span>
                <p className="text-sm text-gray-700">
                  {item.type}: <span className="font-bold">{item.count}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Additional Info Card */}
      <div className="bg-white p-6 rounded-lg shadow-md mt-6">
        <h2 className="text-lg font-bold text-gray-700 mb-4">
          Additional Information
        </h2>
        <div className="space-y-2">
          <p className="text-sm text-gray-700">
            Total Assets: {data.assetsCount}
          </p>
          <p className="text-sm text-gray-700">
            Ad Rules Count: {data.entAdsRulesCounts}
          </p>
          <p className="text-sm text-gray-700">
            Rules Count: {data.entRulesCounts}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
