import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import BreadCrumb from "Common/BreadCrumb";
import {
  deleteMenuById,
  deleteViewById,
  getAllMenu,
  getMenuByPageNumber,
  getUpdatedMenusByFilter,
} from "helpers/mb-api_helper";
// Icons
import {
  Search,
  FileEdit,
  CheckCircle,
  Loader,
  Plus,
  Settings,
  ChevronsRight,
  ChevronRight,
  ChevronLeft,
  ChevronsLeft,
  Trash2Icon,
  BadgeX,
  BadgeCheck,
  Edit3Icon,
  X,
  ArrowDownUp,
} from "lucide-react";
import isoLangs from "lib/languages";
import Popup from "reactjs-popup";
import { formatDate } from "coustumFuntions/capitalFirstLetter";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}

// const tableHeadersOptions = [
//   { label: "Title", value: "title" },
//   { label: "Status", value: "status" },
//   { label: "Platform", value: "platform" },
//   { label: "Id", value: "id" },
//   { label: "Category", value: "category" },
//   { label: "UpdateDate", value: "updateDate" },
//   { label: "Language", value: "language" },
// ];

const tableHeadersOptions = [
  "title",
  "status",
  "platform",
  "id",
  // "category",
  "updateDate",
  // "language",
];

const sortableData = [
  { title: "Title", value: "title" },
  { title: "Updated At", value: "v_tt" },
];

const ListViews: React.FC = () => {
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      acl: user.acl,
    })
  );

  const { acl } = useSelector(selectProperties);

  const [editAccess, setEditAccess] = useState<any>(false);
  const [deleteAccess, setDeleteAccess] = useState<any>(false);
  const [openDeleteViewPopup, setOpenDeleteViewPopup] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [platforms, setPlatforms] = useState<any>([]); // State to store platforms data
  const [totalCount, setTotalCount] = useState<number>(0); // State to store total
  const [colData, setColData] = useState<any>([]);
  const [tableHeaders, setTableHeaders] = useState([
    "title",
    "id",
    "platform",
    "status",
  ]);
  const [sort, setSort] = useState("v_tt:desc");
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<any>(20);
  const [pagination, setPagination] = useState<AnyObject>({
    size: 20,
    count: 1,
    from: 1,
    to: 20,
  });

  const columnObject: any = useMemo(
    () => ({
      title: {
        header: "Title",
        accessorKey: "title",
        enableColumnFilter: false,
      },
      slug: {
        header: "Slug",
        accessorKey: "slug",
        enableColumnFilter: false,
      },
      platform: {
        header: "Platform",
        accessorKey: "v_platform",
        enableColumnFilter: false,
      },
      id: {
        header: "Id",
        accessorKey: "id",
        enableColumnFilter: false,
      },
      updateDate: {
        header: "UpdateDate",
        accessorKey: "v_tt",
        enableColumnFilter: false,
      },
      genres: {
        header: "Genres",
        accessorKey: "genres",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.original.genres?.join(" | "),
      },
      category: {
        header: "Category",
        accessorKey: "category",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.original.catogory?.join(" | "),
      },
      language: {
        header: "Language",
        accessorKey: "defaultLanguage",
        enableColumnFilter: false,
        cell: (cell: any) =>
          cell.row.original.defaultLanguage
            ? isoLangs[cell.row.original.defaultLanguage]["nativeName"]
            : "",
      },
      status: {
        header: "Status",
        accessorKey: "v_status",
        enableColumnFilter: false,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      action: {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-4">
            <Link
              className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
              to={`/platforms/menus/${cell.row.original.v_platform}/${cell.row.original.id}`}
            >
              <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
              {""}
              <span className="align-middle">Edit</span>
            </Link>
          </div>
        ),
      },
    }),
    []
  );

  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case "live":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
            <CheckCircle className="size-3 mr-1.5" />
            {item}
          </span>
        );
      case "draft":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
            <Loader className="size-3 mr-1.5" />
            {item}
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
            <CheckCircle className="size-3 mr-1.5" />
            {item}
          </span>
        );
    }
  };

  const handleSearchViews = async (e: any) => {
    const searchKey = e.target.value;
    setSearchTerm(searchKey);
    setPageNumber(1);
    setSort("v_tt:desc");
  };

  const handleCloseDeletePopup = () => {
    setSelectedId("");
    setOpenDeleteViewPopup(false);
    setDeleteError("");
  };
  const handlePageChange = async (value: number) => {
    if (value !== pageNumber) setPageNumber(value);
  };

  const handleDeleteView = async () => {
    try {
      await deleteMenuById(selectedId);
      const updatedData = platforms?.filter(
        (platform: any) => platform?.id !== selectedId
      );
      setPlatforms(updatedData);
      handleCloseDeletePopup();
    } catch (e) {
      console.error("error", e);
      setDeleteError("Unauthorized: you don't have permission to delete");
    }
  };

  const manageHeaders = (e: any) => {
    if (e.target.checked) {
      setTableHeaders([...tableHeaders, e.target.name]);
    } else {
      setTableHeaders(tableHeaders.filter((h) => h !== e.target.name));
    }
  };

  const handleHeaderOptionChange = (selectedOption: string[]) => {
    setTableHeaders(selectedOption);
  };

  useEffect(() => {
    const updateView = async () => {
      try {
        const response: any = await getUpdatedMenusByFilter({
          // filters: searchTerm ? `title:${searchTerm}` : null,
          text: searchTerm ?? "",
          page: pageNumber,
          sort: sort,
        });

        setPlatforms(response.list);
        setTotalCount(response.total);
        let pages = Math.ceil(response.total / pagination.size);
        let f = (pageNumber - 1) * 20 + 1;
        let t = pageNumber * 20;
        if (response.total < t) t = response.total;
        setPagination({ ...pagination, count: pages, from: f, to: t });
      } catch (e) {
        console.error("Error fetching assets:", e);
      }
    };

    updateView();
  }, [sort, pageNumber, searchTerm]);

  useEffect(() => {
    const getPlatformData = async () => {
      try {
        const response: any = await getAllMenu(); // Await the API call to fetch platforms
        setPlatforms(response.list); // Extract the data from the response and set it in the state
        setTotalCount(response.total); // Set the total count
        let pages = Math.ceil(response.total / pagination.size);
        let f = (pageNumber - 1) * 20 + 1;
        let t = pageNumber * 20;
        if (response.total < t) t = response.total;
        setPagination({ ...pagination, count: pages, from: f, to: t });
      } catch (error) {
        console.error("Error fetching views:", error);
      }
    };
    getPlatformData();
  }, [show]);

  useEffect(() => {
    setColData(tableHeaders.map((header: any) => columnObject[header]));
  }, [tableHeaders, columnObject]);

  useEffect(() => {
    setEditAccess(() => {
      return (
        acl.includes("ALL") ||
        acl.includes("DRAFT_MENU.CREATE") ||
        acl.includes("DRAFT_MENU.UPDATE")
      );
    });
    setDeleteAccess(() => {
      return acl.includes("ALL") || acl.includes("MENU.DELETE");
    });
  }, [acl]);
  return (
    <React.Fragment>
      <BreadCrumb title="Manage Menus" pageTitle="Storefront" />
      {/* <ToastContainer closeButton={false} limit={1} /> */}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12 text-xs">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="search-filters card p-4 flex items-center justify-between gap-4">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchViews}
                  name="search"
                  placeholder="Search by menu name..."
                  className="h-8 w-1/2 px-4 border border-gray-300 rounded-md grow-0"
                />

                <div className="flex flex-row gap-4 justify-end items-center">
                  <Popup
                    arrow
                    on={"click"}
                    position={"bottom right"}
                    trigger={
                      <p title={`Sort`}>
                        <ArrowDownUp
                          size={15}
                          className="text-mb-blue cursor-pointer"
                        ></ArrowDownUp>
                      </p>
                    }
                  >
                    <div className="w-[200px] flex flex-col gap-0 border border-gray-300 bg-white mt-2 rounded-md overflow-hidden">
                      <div className="bg-mb-blue text-white py-1 px-2 font-bold">
                        Choose Sort Order
                      </div>
                      {sortableData.map((sortField: any, i: number) => {
                        return (
                          <div key={i}>
                            <div className="flex items-center gap-4  px-4 py-1">
                              <input
                                onChange={(e) => {
                                  e.target.checked
                                    ? setSort(`${sortField?.value}:asc`)
                                    : setSort("");
                                  setPageNumber(1);
                                }}
                                checked={sort === `${sortField.value}:asc`}
                                type="checkbox"
                                id={`sortable-asc-${sortField.value}`}
                              />
                              <label
                                htmlFor={`sortable-asc-${sortField.value}`}
                              >
                                {sortField.title} ASC
                              </label>
                            </div>
                            <div className="flex items-center gap-4  px-4 py-1">
                              <input
                                onChange={(e) => {
                                  e.target.checked
                                    ? setSort(`${sortField.value}:desc`)
                                    : setSort("");
                                  setPageNumber(1);
                                }}
                                checked={sort === `${sortField.value}:desc`}
                                type="checkbox"
                                id={`sortable-desc-${sortField.value}`}
                              />
                              <label
                                htmlFor={`sortable-desc-${sortField.value}`}
                              >
                                {sortField.title} Desc
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Popup>
                </div>
              </div>
              {platforms && platforms.length > 0 ? (
                <>
                  <div className="overflow-x-auto border border-slate-200 rounded-md">
                    <table className="w-full">
                      <thead className="capitalize ltr:text-left rtl:text-right ">
                        <tr>
                          {tableHeaders.map((header: any, i: number) => (
                            <th
                              key={i}
                              className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                            >
                              {header}
                            </th>
                          ))}
                          {editAccess && (
                            <th className="px-3.5 sticky right-0 min-w-[100px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                              Actions
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {platforms.map((platform: any, i: number) => (
                          <tr
                            key={platform.id + i}
                            className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                          >
                            {tableHeaders.map((header: any, j: number) => {
                              return (
                                <td
                                  key={header}
                                  className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500"
                                >
                                  {(j === 0 || header === "id") &&
                                  header !== "title" ? (
                                    editAccess ? (
                                      <Link
                                        className="transition-all duration-150 ease-linear text-mb-blue hover:text-mb-blue/60"
                                        to={`/platforms/menus/${platform.v_platform}/${platform.id}`}
                                      >
                                        {
                                          platform[
                                            columnObject[header].accessorKey
                                          ]
                                        }
                                      </Link>
                                    ) : (
                                      <p>
                                        {
                                          platform[
                                            columnObject[header].accessorKey
                                          ]
                                        }
                                      </p>
                                    )
                                  ) : columnObject[header].accessorKey ===
                                    "v_tt" ? (
                                    formatDate(
                                      platform[columnObject[header].accessorKey]
                                    ).toString()
                                  ) : columnObject[header].accessorKey ===
                                    "v_status" ? (
                                    <span
                                      className={`${
                                        platform[
                                          columnObject[header].accessorKey
                                        ] === "draft"
                                          ? "bg-gray-200"
                                          : "bg-mb-green"
                                      } p-1`}
                                    >
                                      {platform[
                                        columnObject[header].accessorKey
                                      ].toUpperCase()}
                                    </span>
                                  ) : (
                                    platform[columnObject[header].accessorKey]
                                  )}
                                </td>
                              );
                            })}
                            {editAccess && (
                              <td className="px-3.5 py-2.5 sticky right-0 border z-50 bg-white border-slate-200 dark:border-zink-500">
                                <div className="w-full flex gap-2 justify-end">
                                  <div>
                                    <Link
                                      // to={`views/${platform.id}`}
                                      title={`Edit ${platform.title}`}
                                      to={`/platforms/menus/${platform.v_platform}/${platform.id}`}
                                      className="w-auto py-0.5 font-bold text-mb-blue"
                                    >
                                      <Edit3Icon
                                        className="inline-block mr-2"
                                        size={15}
                                      />
                                    </Link>
                                  </div>
                                  {deleteAccess && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          setSelectedId(platform?.id);
                                          setOpenDeleteViewPopup(true);
                                        }}
                                        title={`Delete ${platform.title}`}
                                        className="w-auto py-0.5 font-bold text-mb-red"
                                      >
                                        <Trash2Icon
                                          className="inline-block mr-2"
                                          size={15}
                                        />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {pagination.count > 0 && (
                    <div className="pagination flex items-center justify-between py-4">
                      <div className="flex flex-col gap-2">
                        <p className="">
                          Showing From {pagination.from} to {pagination.to} of{" "}
                          {totalCount}
                        </p>
                        <div className="flex gap-2">
                          <p className="">Go to Page</p>
                          <select
                            className=""
                            onChange={(e: any) =>
                              handlePageChange(e.target.value)
                            }
                          >
                            {[...Array(pagination.count).keys()].map((int) => (
                              <option
                                selected={pageNumber === int + 1}
                                value={int + 1}
                              >
                                {int + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <ul className="flex flex-wrap items-center gap-2">
                        {pageNumber > 1 && (
                          <li>
                            <button
                              onClick={() => handlePageChange(1)}
                              className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                            >
                              <ChevronsLeft className="size-4 rtl:rotate-180" />
                            </button>
                          </li>
                        )}
                        {pageNumber > 1 && (
                          <li>
                            <button
                              onClick={() => handlePageChange(pageNumber - 1)}
                              className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                            >
                              <ChevronLeft className="size-4 rtl:rotate-180" />
                            </button>
                          </li>
                        )}
                        {[...Array(pagination.count).keys()].map((int) => {
                          return int + 1 >= pageNumber - 1 &&
                            int + 1 <= pageNumber + 1 ? (
                            <li
                              className={pageNumber === int + 1 ? "active" : ""}
                            >
                              <button
                                onClick={() => handlePageChange(int + 1)}
                                className={`${
                                  pageNumber === int + 1 ? "active" : ""
                                } inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto`}
                              >
                                {int + 1}
                              </button>
                            </li>
                          ) : (
                            <li>.</li>
                          );
                        })}

                        {pageNumber < pagination.count && (
                          <li>
                            <button
                              onClick={() => handlePageChange(pageNumber + 1)}
                              className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                            >
                              <ChevronRight className="size-4 rtl:rotate-180" />
                            </button>
                          </li>
                        )}
                        {pageNumber < pagination.count && (
                          <li>
                            <button
                              onClick={() => handlePageChange(pagination.count)}
                              className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                            >
                              <ChevronsRight className="size-4 rtl:rotate-180" />
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </>
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-mb-blue fill-sky-100 dark:mb-blue/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Popup
        open={openDeleteViewPopup}
        onClose={handleCloseDeletePopup}
        modal
        lockScroll
        closeOnDocumentClick
        overlayStyle={{ background: "rgba(0,0,0,0.6)", zIndex: 999999 }}
      >
        <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
          <button
            onClick={handleCloseDeletePopup}
            className="absolute right-4 top-4"
          >
            <X size={30} />
          </button>
          <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
            Delete Selected Item
          </div>
          <div className="text-sm text-gray-900 p-4">
            Are you sure you want to delete the selected item. This action
            cannot be undone.
          </div>
          <div className="flex justify-end gap-4 p-4">
            <button
              className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1 "
              onClick={handleDeleteView}
            >
              Delete
            </button>
            <button
              className="bg-gray-300 text-gray-900 rounded-md hover:bg-gray-300/30 hover:text-gray-900 px-4 py-2 flex items-center gap-1"
              onClick={handleCloseDeletePopup}
            >
              Cancel
            </button>
          </div>
          <div className="flex justify-end p-4">
            {deleteError && <p className="text-red-500"> {deleteError}</p>}
          </div>
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default ListViews;
