import { createSelector } from "@reduxjs/toolkit";
import GetComponent from "components/commonComponents/getComponent";
import { slugify } from "helpers/utility";
import { Info, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { TagInput } from "rsuite";

interface InputProps {
  name: string;
  props: any;
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  i18n: string;
  disablesFields: boolean;
}
interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}
const ObjectBox: React.FC<InputProps> = ({
  name,
  props,
  formData = {},
  setFormData,
  i18n,
  disablesFields,
}) => {
  const [showHelper, setShowHelper] = useState(false);
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);
  const [show, setShow] = useState(false);
  const [internalFormData, setInternalFormData] = useState<AnyObject>({});
  useEffect(() => {
    setShow(!show);
  }, [i18n]);
  useEffect(() => {
    if (i18n === meta.lang) {
      setInternalFormData(formData[name] || {});
      setShow(true);
    } else {
      if (!formData.i18n) {
        setInternalFormData({ ...formData[name], i18n: { [i18n]: {} } });
        setShow(true);
      } else if (!formData.i18n[i18n]) {
        setInternalFormData({ ...formData[name], i18n: { [i18n]: {} } });
        setShow(true);
      } else if (!formData.i18n[i18n][name]) {
        setInternalFormData({ ...formData[name], i18n: { [i18n]: {} } });
        setShow(true);
      } else {
        setInternalFormData({
          ...formData[name],
          i18n: { [i18n]: { ...formData.i18n[i18n][name] } },
        });
        setShow(true);
      }
    }
  }, [show]);

  useEffect(() => {
    if (Object.keys(internalFormData).length > 0 && show) {
      if (i18n !== meta.lang) {
        let newFormData = formData;
        if (!newFormData["i18n"]) {
          newFormData = {
            ...newFormData,
            i18n: { [i18n]: { [name]: { ...internalFormData.i18n[i18n] } } },
          };
        } else if (!newFormData.i18n[i18n]) {
          newFormData.i18n = {
            ...newFormData.i18n,
            [i18n]: { [name]: { ...internalFormData.i18n[i18n] } },
          };
        } else if (!newFormData.i18n[i18n][name]) {
          newFormData.i18n[i18n] = {
            ...newFormData.i18n[i18n],
            [name]: { ...internalFormData.i18n[i18n] },
          };
        } else {
          newFormData.i18n[i18n][name] = { ...internalFormData.i18n[i18n] };
        }
        setFormData({ ...newFormData });
      } else {
        setFormData({ ...formData, [name]: { ...internalFormData } });
      }
    }
  }, [internalFormData]);
  return (
    <>
      {show && (
        <div
          className={`flex card bg-gray-50 p-4 flex-col gap-4 ${
            props.cols ? `lg:col-span-${props.cols}` : "lg:col-span-12"
          }`}
        >
          <div className="flex gap-1 items-center">
            <label className="text-sm font-bold">{props.name}</label>
            {props.longDesc && (
              <>
                <button
                  className="text-mb-blue"
                  onClick={() => setShowHelper(true)}
                >
                  <Info size={15} />
                </button>
                <Popup
                  open={showHelper}
                  onClose={() => setShowHelper(false)}
                  modal
                  lockScroll
                  closeOnDocumentClick
                  contentStyle={{
                    top: 0,
                    right: 0,
                    height: "100vh",
                    position: "fixed",
                  }}
                  overlayStyle={{
                    background: "rgba(0,0,0,0.6)",
                    zIndex: 999999,
                  }}
                >
                  <div className="h-screen bg-white w-[20rem] relative">
                    <button
                      onClick={() => setShowHelper(false)}
                      className="absolute right-4 top-4"
                    >
                      <X size={30} />
                    </button>
                    <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
                      {props.longDesc.title}
                    </div>
                    <div
                      className="text-sm text-gray-900 p-4 flex flex-col gap-4"
                      dangerouslySetInnerHTML={{ __html: props.longDesc.body }}
                    ></div>
                  </div>
                </Popup>
              </>
            )}
          </div>
          {Object.keys(props.properties).map((k: string, i: number) => {
            return (
              <GetComponent
                key={k + i}
                disablesFields={disablesFields}
                name={k}
                formData={internalFormData}
                setFormData={setInternalFormData}
                props={props.properties[k]}
                i18n={i18n}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default ObjectBox;
