import { createSelector } from "@reduxjs/toolkit";
import BreadCrumb from "Common/BreadCrumb";
import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import {
  createDraftMenu,
  getDraftMenuById,
  getMenuListTags,
  getPreSignedUrl,
  getViews,
  publishMenu,
  updateDraftMenu,
  uploadFile,
} from "helpers/mb-api_helper";
import isoLangs from "lib/languages";
import { ShieldCheck } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { ReactSortable } from "react-sortablejs";
import { TagPicker } from "rsuite";
import { v4 as uuidv4 } from "uuid";

interface I18nEntry {
  language: String;
  title: string;
}
interface ActiveTabs {
  [key: number]: number;
}
interface FormValues {
  title: string;
  url: string;
  view: string;
  id: number;
  file: string;
  i18n: I18nEntry[];
  svg: string;
  css: string;
  tags: string[];
}

const PlatformMenuIdEdit = () => {
  const { id, menuId } = useParams();
  const navigate = useNavigate();
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      acl: user.acl,
      meta: user.meta,
    })
  );

  const { meta, acl } = useSelector(selectProperties);

  const [access, setAccess] = useState<any>({});
  const [menuItemData, setMenuItemData] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<any>(false);
  const [publishSuccess, setPublishSuccess] = useState<any>(false);
  const [tagsData, setTagsData] = useState<any>([]);
  const [cacheTagData, setCacheTagData] = useState<any>([]); //Option to cache value when searching asynchronously
  const [fields, setFields] = useState<Array<FormValues>>([
    {
      title: "",
      url: "",
      view: "",
      i18n: [],
      id: 0,
      file: "",
      svg: "",
      css: "",
      tags: [],
    },
  ]);
  const [mainText, setMainText] = useState<string>("");
  const [options, setOptions] = useState<
    { placement: string; value: string }[]
  >([]);
  const [activeTabs, setActiveTabs] = useState<ActiveTabs>({
    "0": 0,
  });

  const handleSelectTag = (value: any, item: any, event: any) => {
    setCacheTagData([...cacheTagData, item]);
  };

  const handleFeatTags = async (searchKey: string) => {
    try {
      const response: any = await getMenuListTags(searchKey);
      if (response?.list?.length > 0) {
        setTagsData(response?.list);
      }
    } catch (err) {}
  };

  const handleTabChange = (fieldIndex: number, langIndex: number) => {
    setActiveTabs((prevActiveTabs: any) => ({
      ...prevActiveTabs,
      [fieldIndex]: langIndex,
    }));
  };

  const onUpload = async (e: any, index: number) => {
    let file = e.target.files[0];
    let fileName = uuidv4();
    try {
      let signedUrl: any = await getPreSignedUrl(
        `images/${fileName}.${file.type.replace("image/", "")}`
      );
      await uploadFile(signedUrl.url, file);
      handleFieldChange(index, "file", signedUrl.file);
    } catch (e) {
      console.error(e);
    }
  };

  const handlePublishData = async () => {
    try {
      const data = await publishMenu(menuId);
      if (data) {
        setPublishSuccess(true);
        setTimeout(() => {
          navigate(`/menus/${id}`);
        }, 1000);
      }
    } catch (err) {}
  };

  const handleInputChange = (value: string, index: number) => {
    const activeLang = meta.languages[activeTabs[index]];
    setFields((prevValues: FormValues[]) => {
      const updatedValues = prevValues.map((item, i) => {
        if (i === index) {
          // Ensure i18n exists
          const updatedI18n = item.i18n || [];

          // Update the i18n array
          const updatedEntries = updatedI18n.map((entry) => {
            if (entry.language === activeLang) {
              return { ...entry, title: value };
            }
            return entry;
          });

          // If no entry for the active language exists, add one
          const hasActiveLang = updatedEntries.some(
            (entry) => entry.language === activeLang
          );
          if (!hasActiveLang) {
            updatedEntries.push({
              language: activeLang,
              title: value,
            });
          }

          // If the current language is the default language, update the title directly
          if (meta.lang === activeLang) {
            return {
              ...item,
              title: value,
              i18n: updatedEntries,
            };
          }

          return {
            ...item,
            i18n: updatedEntries,
          };
        }

        return item;
      });

      return updatedValues;
    });
  };

  const addMoreFields = () => {
    setFields([
      ...fields,
      {
        title: "",
        url: "",
        view: "",
        i18n: [],
        id: fields.length + 1,
        file: "",
        svg: "",
        css: "",
        tags: [],
      },
    ]);
    setActiveTabs((prevValue: any) => ({
      ...prevValue,
      [fields.length]: 0,
    }));
  };

  const handleDeleteField = (index: number) => {
    setFields(fields.filter((_, i) => i !== index));
    setActiveTabs((prevState: ActiveTabs) => {
      const { [index]: _, ...newState } = prevState;
      // Reindex the keys
      const reindexedState = Object.keys(newState).reduce(
        (acc: any, key: any, index: number) => {
          acc[index] = newState[key];
          return acc;
        },
        {}
      );

      return reindexedState;
    });
  };

  const handleFieldChange = (index: number, field: string, value: string) => {
    const newFields = [...fields];
    newFields[index] = { ...newFields[index], [field]: value };
    setFields(newFields);
  };

  const handleChange = (selectedOption: any, fieldIndex: number) => {
    if (!selectedOption) {
      console.error("Selected option is undefined");
      return;
    }

    // Update the state with the new selected option
    setFields((prevFields) =>
      prevFields.map((field, i) => {
        if (i === fieldIndex) {
          return { ...field, view: selectedOption.value }; // Update the `view` field
        }
        return field;
      })
    );
  };

  const handleSubmit = async () => {
    const formData = {
      ...menuItemData,
      title: mainText,
      items: fields,
      //   placement: menuItemData.placement,
      //   v_platform: menuItemData.v_platform,
    };
    try {
      const res = await updateDraftMenu(menuId, {
        ...formData,
      });
      const data: any = res;
      if (data) {
        setSubmitSuccess(true);
      }
    } catch (e) {}
  };

  useEffect(() => {
    const getData = async () => {
      handleFeatTags("");
      try {
        const currPlatFilters: any = {
          v_platform: id,
        };
        const currViewRes: any = await getViews(currPlatFilters); // Await the API call to fetch view by platform
        let currViewRecords = currViewRes.list;
        const recordOptions = currViewRecords.map(
          (record: { name: string; slug: string }) => ({
            label: record.name,
            value: record.slug,
          })
        );
        setOptions(recordOptions);
      } catch (e) {}
    };
    getData();
  }, [id]);

  useEffect(() => {
    const getMenuDataById = async () => {
      try {
        const response = await getDraftMenuById(menuId);
        const data: any = response;
        setMenuItemData(data);
        setFields(
          data.items || [{ title: "", url: "", view: "", i18n: [], id: 0 }]
        );
        setActiveTabs(() =>
          // Initialize state based on the length of data.items
          {
            return data?.items?.length > 0
              ? data.items.reduce((acc: any, _: any, index: any) => {
                  acc[index] = 0;
                  return acc;
                }, {})
              : { "0": 0 };
          }
        );
        setMainText(data.title || "");
      } catch (e) {
        const carateDraft = await createDraftMenu(menuId);
        const data: any = carateDraft;
        setMenuItemData(data);
        setFields(
          data.items || [{ title: "", url: "", view: "", i18n: [], id: 0 }]
        );
        setActiveTabs(() =>
          // Initialize state based on the length of data.items
          {
            return data?.items?.length > 0
              ? data.items.reduce((acc: any, _: any, index: any) => {
                  acc[index] = 0;
                  return acc;
                }, {})
              : { "0": 0 };
          }
        );
        setMainText(data.title || "");
      }
    };
    getMenuDataById();
  }, [menuId]);

  useEffect(() => {
    if (submitSuccess) setSubmitSuccess(false);
  }, [fields]);

  useEffect(() => {
    setAccess(() => ({
      edit:
        acl.includes("ALL") ||
        acl.includes("DRAFT_MENU.CREATE") ||
        acl.includes("DRAFT_MENU.UPDATE"),
      create: acl.includes("ALL") || acl.includes("MENU.CREATE"),
      publish: acl.includes("ALL") || acl.includes("MENU.UPDATE"),
    }));
  }, [acl]);

  return (
    <React.Fragment>
      <BreadCrumb
        backRoute={`/menus/${id}`}
        title={`Manage Menus - ${id}`}
        pageTitle="menus Management"
      />
      {/* <ToastContainer closeButton={false} limit={1} /> */}
      <div className=" p-4 border rounded-lg shadow-lg">
        <div className="p-4 mb-4 border border-slate-300">
          <label className="inline-block mb-2 text-base font-medium">
            Title
          </label>
          <input
            type="text"
            className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
            value={mainText}
            onChange={(e) => setMainText(e.target.value)}
          />

          <ReactSortable list={fields} setList={setFields}>
            {fields.map((field, index) => (
              <div
                key={index}
                className="text-sm grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-12 mt-6 p-4 border rounded-lg shadow-lg bg-white"
              >
                <div className="lg:col-span-2 xl:col-span-12">
                  <div className="flex justify-between">
                    <label
                      htmlFor="productNameInput"
                      className="inline-block mb-1 text-base font-medium"
                    >
                      Menu
                    </label>
                    {fields.length > 1 && (
                      <button
                        className="text-mb-red/90 hover:text-mb-red group disabled:text-gray-100"
                        onClick={() => handleDeleteField(index)}
                      >
                        <svg
                          className="w-6 h-6 "
                          fill="currentColor"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="CancelIcon"
                        >
                          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"></path>
                        </svg>
                      </button>
                    )}
                  </div>
                  <div className="flex gap-3 flex-wrap">
                    {meta.languages.map((lang: any, i: number) => (
                      <p
                        key={i}
                        className={`p-2 ${
                          activeTabs[index] === i
                            ? "underline text-mb-blue font-bold"
                            : "text-black"
                        } `}
                        onClick={() => handleTabChange(index, i)}
                      >
                        {isoLangs[lang].name}
                      </p>
                    ))}
                  </div>
                  <div>
                    <input
                      type="text"
                      value={
                        field.i18n
                          ? field.i18n.find(
                              (langObj: I18nEntry) =>
                                langObj.language ===
                                meta.languages[activeTabs[index]]
                            )?.title || ""
                          : ""
                      }
                      onChange={(e) => handleInputChange(e.target.value, index)}
                      className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                      placeholder="Product title"
                    />
                  </div>
                </div>

                <div className="xl:col-span-6">
                  <label className="inline-block mb-2 text-base font-medium">
                    URL:
                  </label>
                  <input
                    type="text"
                    className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                    value={field.url}
                    onChange={(e) =>
                      handleFieldChange(index, "url", e.target.value)
                    }
                  />
                </div>

                <div className="xl:col-span-6">
                  <label className="inline-block mb-2 text-base font-medium">
                    View
                  </label>
                  <Select
                    className="border !border-mb-blue !text-black rounded-md w-full z-50"
                    id="choices-single-no-sorting"
                    name="choices-single-no-sorting"
                    options={options}
                    onChange={(selectedOption) =>
                      handleChange(selectedOption, index)
                    }
                    value={options.find(
                      (option) => option.value === field.view
                    )}
                  />
                </div>

                <div className="xl:col-span-6">
                  <label className="inline-block mb-2 mr-4 text-base font-medium">
                    Icon
                  </label>
                  <input type="file" onChange={(e) => onUpload(e, index)} />
                  {field.file && field.file !== "" && (
                    <div className="relative mt-2">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_CDN}${field.file}`}
                        width={100}
                        height={100}
                        alt="uploadedfile"
                      />
                      <button
                        className="text-mb-red/90 hover:text-mb-red group disabled:text-gray-100 absolute -left-1 -top-1"
                        onClick={() => handleFieldChange(index, "file", "")}
                      >
                        <svg
                          className="w-4 h-4 "
                          fill="currentColor"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="CancelIcon"
                        >
                          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"></path>
                        </svg>
                      </button>
                    </div>
                  )}
                </div>

                <div className="xl:col-span-6">
                  <label className="inline-block mb-2 mr-4 text-base font-medium">
                    CSS Class
                  </label>
                  <input
                    className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                    value={field.css}
                    onChange={(e) =>
                      handleFieldChange(index, "css", e.target.value)
                    }
                  />
                </div>

                <div className="xl:col-span-6">
                  <label className="inline-block mb-2 mr-4 text-base font-medium">
                    SVG
                  </label>
                  <textarea
                    className="px-1 py-2 h-32 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                    value={field.svg}
                    onChange={(e) =>
                      handleFieldChange(index, "svg", e.target.value)
                    }
                  />
                </div>

                <div className="xl:col-span-6">
                  <label className="block mb-2 mr-4 text-base font-medium">
                    Tags
                  </label>
                  <TagPicker
                    className="p-1 border !border-mb-blue rounded-md w-full"
                    creatable
                    data={tagsData?.map((l: any) => ({
                      login: l,
                      id: l,
                    }))}
                    cacheData={cacheTagData} //Option to cache value when searching asynchronously
                    value={field?.tags || []}
                    style={{ width: 300 }}
                    labelKey="login"
                    valueKey="id"
                    onChange={(data) => {
                      handleFieldChange(index, "tags", data);
                    }}
                    onSearch={handleFeatTags}
                    onSelect={handleSelectTag}
                  />
                </div>
              </div>
            ))}
          </ReactSortable>

          <div className="w-full mt-4">
            <button
              type="button"
              className="bg-mb-blue px-4 py-2 text-gray-50 rounded-lg disabled:bg-gray-100 disabled:text-gray-300"
              onClick={addMoreFields}
            >
              Add More
            </button>
          </div>
        </div>

        <div className="w-full flex justify-end px-5 pb-5">
          {submitSuccess
            ? access?.publish &&
              (publishSuccess ? (
                <button
                  type="button"
                  disabled={publishSuccess}
                  className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                  // onClick={handlePublishData}
                >
                  Published
                </button>
              ) : (
                <button
                  type="button"
                  className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                  onClick={handlePublishData}
                >
                  Publish
                </button>
              ))
            : access?.edit && (
                <button
                  type="button"
                  className="bg-mb-green rounded-md text-white hover:bg-mb-green/30 hover:text-mb-green px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                  onClick={handleSubmit}
                >
                  <ShieldCheck size={15} /> Save
                </button>
              )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlatformMenuIdEdit;
