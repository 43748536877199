import React, { useEffect, useState } from "react";
import Sidebar from "./VerticalLayout/Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import RightSidebar from "./RightSidebar";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Settings } from "lucide-react";
import CartDrawer from "Common/CartDrawer";
import {
  changeLayout,
  changeLayoutSemiDark,
  changeSkin,
  changeLayoutMode,
  changeDirection,
  changeLayoutContentWidth,
  setUserData,
  changeNavigation,
  changeLeftSidebarColorType,
  changeLayoutTopbarColor,
  changeLeftsidebarSizeType,
} from "../slices/thunk";

const Layout = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch<any>();
  const handleToggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const [show, setShow] = React.useState<boolean>(false);
  const handleDrawer = () => setShow(!show);

  const selectLayoutState = (state: any) => state.Layout;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout.layoutType,
      layoutSemiDarkType: layout.layoutSemiDarkType,
      layoutSkintype: layout.layoutSkintype,
      layoutModeType: layout.layoutModeType,
      layoutDirectionType: layout.layoutDirectionType,
      layoutContentWidthType: layout.layoutContentWidthType,
      layoutSidebarSizeType: layout.layoutSidebarSizeType,
      layoutNavigationType: layout.layoutNavigationType,
      layoutSidebarColorType: layout.layoutSidebarColorType,
      layoutTopbarColorType: layout.layoutTopbarColorType,
    })
  );

  // Inside your component
  const {
    layoutType,
    layoutSemiDarkType,
    layoutSkintype,
    layoutModeType,
    layoutDirectionType,
    layoutContentWidthType,
    layoutSidebarSizeType,
    layoutNavigationType,
    layoutSidebarColorType,
    layoutTopbarColorType,
  } = useSelector(selectLayoutProperties);

  const selectUserState = (state: any) => state.User;
  const selectUserProperties = createSelector(selectUserState, (user) => ({
    account: user.account,
    acl: user.acl,
    name: user.name,
    meta: user.meta,
    id: user.id,
  }));
  const { account, meta, acl, id, name } = useSelector(selectUserProperties);

  useEffect(() => {
    if (!account || !meta) dispatch(setUserData());
  }, [account, meta]);

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      layoutSemiDarkType ||
      layoutSkintype ||
      layoutModeType ||
      layoutDirectionType ||
      layoutContentWidthType ||
      layoutSidebarSizeType ||
      layoutNavigationType ||
      layoutSidebarColorType ||
      layoutTopbarColorType
    ) {
      window.dispatchEvent(new Event("resize"));
      dispatch(changeLayout(layoutType));
      dispatch(changeLayoutSemiDark(layoutSemiDarkType));
      dispatch(changeSkin(layoutSkintype));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeDirection(layoutDirectionType));
      dispatch(changeLayoutContentWidth(layoutContentWidthType));
      dispatch(changeLeftsidebarSizeType(layoutSidebarSizeType));
      dispatch(changeNavigation(layoutNavigationType));
      dispatch(changeLeftSidebarColorType(layoutSidebarColorType));
      dispatch(changeLayoutTopbarColor(layoutTopbarColorType));
    }
  }, [
    layoutType,
    layoutSemiDarkType,
    layoutSkintype,
    layoutModeType,
    layoutDirectionType,
    layoutContentWidthType,
    layoutSidebarSizeType,
    layoutNavigationType,
    layoutSidebarColorType,
    layoutTopbarColorType,
    dispatch,
  ]);

  useEffect(() => {
    document.documentElement.classList.add("scroll-smooth", "group");
    document.body.classList.add(
      "text-base",
      "bg-body-bg",
      "text-body",
      "font-public",
      "group-data-[skin=bordered]:bg-body-bordered"
    );
    return () => {
      document.documentElement.classList.remove("scroll-smooth", "group");
      document.body.classList.remove(
        "text-base",
        "bg-body-bg",
        "text-body",
        "font-public",
        "group-data-[skin=bordered]:bg-body-bordered"
      );
    };
  }, []);

  return (
    <>
      {meta && account && (
        <>
          <div className="group-data-[sidebar-size=sm]:min-h-sm group-data-[sidebar-size=sm]:relative">
            <Sidebar
              layoutType={layoutType}
              layoutSidebarSizeType={layoutSidebarSizeType}
            />
            <Header
              handleToggleDrawer={handleToggleDrawer}
              handleDrawer={handleDrawer}
            />
            <div className="relative min-h-screen">
              <div className="group-data-[sidebar-size=lg]:ltr:md:ml-vertical-menu group-data-[sidebar-size=sm]:ltr:ml-vertical-menu-sm pt-[calc(theme('spacing.header')_*_1)] pb-[calc(theme('spacing.header')_*_0.8)] px-4 group-data-[navbar=bordered]:pt-[calc(theme('spacing.header')_*_1.3)] group-data-[navbar=hidden]:pt-0 group-data-[layout=horizontal]:mx-auto">
                <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
                  {children}
                </div>
              </div>
              <Footer />
            </div>
          </div>
          <RightSidebar
            isOpen={isOpen}
            handleToggleDrawer={handleToggleDrawer}
          />
        </>
      )}
    </>
  );
};

export default Layout;
