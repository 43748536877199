import BreadCrumb from "Common/BreadCrumb";
import { getAudit, getAuditByFilters } from "helpers/mb-api_helper";
import {
  ChevronsRight,
  ChevronRight,
  ChevronLeft,
  ChevronsLeft,
  ArrowDownUp,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

interface AuditData {
  action: string;
  userName: string;
  params: { [key: string]: string };
  v_tt: number;
}
interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}
const Audit = () => {
  const [auditData, setAuditData] = useState<AuditData[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState<number>(0); // State to store total
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState<AnyObject>({
    size: 20,
    count: 1,
    from: 1,
    to: 20,
  });

  const handlePageChange = async (value: number) => {
    if (value !== pageNumber) setPageNumber(value);
  };

  // Function to format timestamp into date string
  const formatTimestampToDate = (timestamp: number): any => {
    const date = new Date(timestamp);
    return `${date}`;
  };

  useEffect(() => {
    const updateView = async () => {
      try {
        const response: any = await getAuditByFilters({
          filters: searchTerm ? `params.id:${searchTerm ?? ""}` : "",
          page: pageNumber,
          sort: "v_tt:desc",
        });
        setAuditData(response?.list);
        setTotalCount(response.total);
        let pages = Math.ceil(response.total / pagination.size);
        let f = (pageNumber - 1) * 20 + 1;
        let t = pageNumber * 20;
        if (response.total < t) t = response.total;
        setPagination({ ...pagination, count: pages, from: f, to: t });
      } catch (e) {
        console.error("Error fetching assets:", e);
      }
    };

    updateView();
  }, [pageNumber, searchTerm]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response: any = await getAuditByFilters({
          page: pageNumber,
          sort: "v_tt:desc",
        });
        const data: any = response;
        setAuditData(data.list);
        setTotalCount(data.total);
        let pages = Math.ceil(data.total / pagination.size);
        let f = (pageNumber - 1) * 20 + 1;
        let t = pageNumber * 20;
        if (data.total < t) t = data.total;
        setPagination({ ...pagination, count: pages, from: f, to: t });
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };
    getData();
  }, []);
  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col text-xs">
        <BreadCrumb title="Audit" pageTitle="Audit" />

        <div className="card">
          <div className="card-body">
            <div className="search-filters card p-4 flex items-center justify-between gap-4">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                name="search"
                placeholder="Search or Filter anything..."
                className="h-8 w-1/2 px-4 border border-gray-300 rounded-md grow-0"
              />
            </div>

            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <table className="table-auto w-full border-collapse">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="border px-4 py-2 text-left">Action</th>
                    <th className="border px-4 py-2 text-left">Username</th>
                    <th className="border px-4 py-2 text-left">Params</th>
                    <th className="border px-4 py-2 text-left">Updated Date</th>
                  </tr>
                </thead>
                <tbody>
                  {auditData.map((item, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="border px-4 py-2">{item.action}</td>
                      <td className="border px-4 py-2">{item.userName}</td>
                      <td className="border px-4 py-2">
                        {Object.entries(item.params).map(([key, value]) => (
                          <div key={key}>
                            <span className="font-medium">{key}</span>: {value}
                          </div>
                        ))}
                      </td>
                      <td className="border px-4 py-2">
                        {formatTimestampToDate(item?.v_tt)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {pagination.count > 0 && (
          <div className="pagination flex items-center justify-between py-4">
            <div className="flex flex-col gap-2">
              <p className="">
                Showing From {pagination.from} to {pagination.to} of{" "}
                {totalCount}
              </p>
              <div className="flex gap-2">
                <p className="">Go to Page</p>
                <select
                  className=""
                  onChange={(e: any) => handlePageChange(e.target.value)}
                >
                  {[...Array(pagination.count).keys()].map((int) => (
                    <option selected={pageNumber === int + 1} value={int + 1}>
                      {int + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <ul className="flex flex-wrap items-center gap-2">
              {pageNumber > 1 && (
                <li>
                  <button
                    onClick={() => handlePageChange(1)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronsLeft className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
              {pageNumber > 1 && (
                <li>
                  <button
                    onClick={() => handlePageChange(pageNumber - 1)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronLeft className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}

              {pageNumber < pagination.count && (
                <li>
                  <button
                    onClick={() => handlePageChange(+pageNumber + 1)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronRight className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
              {pageNumber < pagination.count && (
                <li>
                  <button
                    onClick={() => handlePageChange(pagination.count)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronsRight className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Audit;
