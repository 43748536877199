import React, { useCallback, useEffect } from "react";
import { UploadCloud } from "lucide-react";
import Dropzone from "react-dropzone";
import CreatableSelect from "react-select/creatable";
import RightDrawer from "Layout/RightDrawer";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  getPreSignedUrl,
  getProfiles,
  uploadFile,
} from "helpers/mb-api_helper";
import { v4 as uuidv4 } from "uuid";
import { Promise } from "bluebird";

interface InputProps {
  name: string;
  props: any;
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  i18n?: string;
  disablesFields: boolean;
  required?: string[];
}

const MediaUploader: React.FC<InputProps> = ({
  name,
  props,
  formData = {},
  setFormData,
  i18n,
  required,
  disablesFields,
}) => {
  console.log("this is a data of media name", name);
  console.log("this is a data of media formData", formData);

  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);
  const isRequired = required
    ? required?.length > 1
      ? required.includes(name)
      : false
    : false;

  const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const [selectedFiles, setSelectedFiles] = React.useState<any>([]);
  const [allProfilesData, setAllProfilesData] = React.useState<any>([]);
  const [playbackType, setPlaybackType] = React.useState<string>(
    formData.playbackType
  );

  const handleTextChange = (i: any, e: any, keyName: string) => {
    let newFormValues = { ...formData };
    let PlayUrls: any = [...newFormValues[name].playurls];
    // Ensure the index `i` exists and is an object
    if (!PlayUrls[i]) {
      PlayUrls[i] = {}; // Initialize it as an empty object if not already defined
    }
    PlayUrls[i][keyName] = e;
    newFormValues[name].playurls = PlayUrls;
    setFormData({ ...newFormValues });
    //setChangeTheFormData(true);
  };

  const handleDropdownChange = (e: any, i: any, keyName: string) => {
    let newFormValues = { ...formData };
    let PlayUrls = [...newFormValues[name].playurls];
    // Ensure the index `i` exists and is an object
    if (!PlayUrls[i]) {
      PlayUrls[i] = {}; // Initialize it as an empty object if not already defined
    }
    PlayUrls[i][keyName] = e.value;
    newFormValues[name].playurls = PlayUrls;
    setFormData({ ...newFormValues });
  };

  const addNew = () => {
    let playUrls =
      formData[name] && formData[name].playurls ? formData[name].playurls : [];
    let newPlayUrls = [...playUrls, { url: "", type: "", profile: "" }];
    let newFormValues = { ...formData };
    newFormValues[name] = {
      playurls: [],
    };
    newFormValues[name].playurls = newPlayUrls;
    setFormData({ ...newFormValues });
  };

  const handleRemove = (i: number) => {
    let playUrls =
      formData[name] && formData[name].playurls ? formData[name].playurls : [];
    let newFormValues = { ...formData };
    let newPlayUrls = [...playUrls];
    newPlayUrls.splice(i, 1);
    newFormValues[name] = {
      playurls: [],
    };
    newFormValues[name].playurls = newPlayUrls;
    setFormData({ ...newFormValues });
  };

  const handleAcceptedFiles = (files: any) => {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
  };
  const startUpload = useCallback(async () => {
    let fileResults = await Promise.mapSeries(
      selectedFiles,
      async (file: any, index: number) => {
        // setCurrentlyUploading(index);
        let contentId = formData.id;
        let name = uuidv4();
        let signedUrl: any;
        signedUrl = await getPreSignedUrl(
          `${contentId}/original/${name}.${file?.type?.replace("image/", "")}`
        );
        let uploadFileRes = await uploadFile(signedUrl.url, file);

        if (uploadFileRes.status === 200) {
          file.uploaded = true;
          file.uploadPath = signedUrl.file;
          file.url = signedUrl.url;
        } else {
          file.uploaded = false;
        }
        // setProgress(Math.round(((index + 1) * 100) / selectedFiles.length));
        // setCurrentlyUploading(-1);
        return file;
      }
    );

    const newUploadedFiles = fileResults.map((file: any) => ({
      url: file.url,
    }));
    console.log("this is a newUploadedFiles ==== ", newUploadedFiles);

    let newFormValues = { ...formData };
    newFormValues[name] = {
      ...(newFormValues[name] ? newFormValues[name] : {}),
      filePath: newUploadedFiles[0].url,
    };
    // setValueToForm(field, [
    //   ...(newFormValues[field] ? newFormValues[field] : []),
    //   ...newUploadedFiles,
    // ]);
    setFormData(newFormValues);
    // setSelectedFiles([]);
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedFiles.length > 0) startUpload();
  }, [selectedFiles]);

  useEffect(() => {
    if (formData.playbackType !== playbackType)
      setFormData((preVal: any) => ({ ...preVal, [name]: {} }));
  }, [formData.playbackType]);

  useEffect(() => {
    setPlaybackType(formData.playbackType);
    if (formData.playbackType !== "Hosted" && !formData[name]) {
      addNew();
    }
  }, [formData]);
  useEffect(() => {
    const getProfilesData = async () => {
      try {
        const response: any = await getProfiles();

        // Check if response and its structure are valid
        if (response?.list && Array.isArray(response.list)) {
          setAllProfilesData(
            response.list.map((profile: { name: string; id: string }) => ({
              label: profile.name,
              value: profile.id,
            }))
          );
        } else {
          setAllProfilesData([]); // Set an empty array if no data is available
        }
      } catch (error) {
        console.error("Error fetching profiles data:", error);
      }
    };

    getProfilesData();
  }, []);
  return (
    <div className={`lg:col-span-${props.cols || 12}`}>
      <div className="w-full">
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <h6>
              {props.name}{" "}
              {isRequired && <span className="text-red-600">*</span>}
            </h6>
            {props.longDesc && (
              <RightDrawer
                title={props.longDesc.title}
                body={props.longDesc.body}
              />
            )}
          </div>
          {props.shortDesc && props.shortDesc !== "" && (
            <span className="text-sm">{props.shortDesc}</span>
          )}
        </div>
        {playbackType === "Hosted" && (
          <>
            <div className="flex items-center justify-center border rounded-md cursor-pointer bg-slate-100 dropzone border-slate-200 dark:bg-zink-600 dark:border-zink-500 dz-clickable">
              <Dropzone
                onDrop={(acceptedFiles: any) => {
                  handleAcceptedFiles(acceptedFiles);
                }}
                disabled={
                  disablesFields ||
                  i18n !== meta.lang ||
                  formData[name].filePath !== ""
                }
              >
                {({ getRootProps, getInputProps }: any) => (
                  <div
                    className="w-full py-5 text-lg text-center dz-message needsclick"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                    </div>

                    <h5 className="mb-0 font-normal text-slate-500 text-15">
                      {disablesFields ||
                      i18n !== meta.lang ||
                      formData[name].filePath !== ""
                        ? "Hosted Files are disabled currently. Please try again later."
                        : "Drag and drop your files "}
                    </h5>
                  </div>
                )}
              </Dropzone>
            </div>

            {formData[name].filePath && formData[name].filePath !== "" && (
              <ul className="mb-0" id="dropzone-preview">
                <li className="mt-2" id="dropzone-preview-list">
                  <div className="border rounded border-slate-200 dark:border-zink-500">
                    <div className="flex p-2">
                      <div className="shrink-0 me-3"></div>
                      <div className="grow">
                        <div className="pt-1">
                          <h5 className="mb-1 text-15 truncate" data-dz-name>
                            {formData[name].filePath.substring(0, 40) + "..."}
                          </h5>
                        </div>
                      </div>
                      <div className="shrink-0 ms-3">
                        <button
                          data-dz-remove
                          className="px-2 py-1.5 text-xs text-white bg-mb-red border-mb-red btn hover:text-white hover:bg-mb-red/20 hover:border-mb-red/20 focus:text-white focus:bg-mb-red/20 focus:border-mb-red/20 focus:ring focus:ring-red-100 active:text-white active:bg-mb-red/20 active:border-mb-red/20 active:ring active:ring-red-100 dark:ring-custom-400/20"
                          onClick={() => {
                            let newFormValues = { ...formData };
                            newFormValues[name] = {
                              ...(newFormValues[name]
                                ? newFormValues[name]
                                : {}),
                              filePath: "",
                            };

                            setFormData(newFormValues);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            )}
            <div className="flex flex-col gap-2 relative w-full">
              <label className="inline-block text-base font-medium">
                Bucket Url
              </label>
              <span className="text-xs">Enter bucket url here </span>
              <input
                value={formData[name].filePath}
                type="text"
                disabled={
                  disablesFields ||
                  i18n !== meta.lang ||
                  formData[name].filePath !== ""
                }
                onChange={(e: any) => {
                  let newFormValues = { ...formData };
                  newFormValues[name] = {
                    ...(newFormValues[name] ? newFormValues[name] : {}),
                    filePath: e.target.value,
                  };

                  setFormData(newFormValues);
                }}
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              />
            </div>
            <div className="card p-4 grid lg:grid-cols-2 w-full gap-4 items-start justify-center relative">
              <div className="flex flex-col gap-2 relative w-full">
                <label className="inline-block text-base font-medium">
                  Transcoding Profile
                </label>
                <span className="text-xs">Enter transcoding profile </span>
                <CreatableSelect
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="choices-single-no-search"
                  name="choices-single-no-search"
                  isSearchable={false}
                  isDisabled={disablesFields || i18n !== meta.lang}
                  options={allProfilesData}
                  onChange={(selectedOption) => {
                    let newFormValues = { ...formData };
                    newFormValues[name] = {
                      ...(newFormValues[name] ? newFormValues[name] : {}),
                      transcoding_profile: selectedOption?.value,
                    };

                    setFormData(newFormValues);
                  }}
                  value={
                    allProfilesData.find(
                      (option:{value:string}) =>
                        option.value ===
                        formData[name]?.transcoding_profile
                    ) || null
                  }
                />
              </div>

              <div className="flex flex-col gap-2 relative w-full">
                <label className="inline-block text-base font-medium">
                  Content Type
                </label>
                <span className="text-xs">
                  Select content type for this media (Ignore for subtitles)
                </span>
                <CreatableSelect
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="choices-single-no-search"
                  name="choices-single-no-search"
                  isSearchable={false}
                  isDisabled={disablesFields || i18n !== meta.lang}
                  options={[
                    { label: "VOD", value: "VOD" },
                    { label: "LIVE", value: "LIVE" },
                  ]}
                  onChange={(selectedOption) => {
                    let newFormValues = { ...formData };
                    newFormValues[name] = {
                      ...(newFormValues[name] ? newFormValues[name] : {}),
                      content_type: selectedOption,
                    };

                    setFormData(newFormValues);
                  }}
                  value={
                    [
                      { label: "VOD", value: "VOD" },
                      { label: "LIVE", value: "LIVE" },
                    ].find(
                      (option) =>
                        option.value === formData[name]?.content_type?.value
                    ) || null
                  }
                />
              </div>
            </div>
          </>
        )}
        {(playbackType === "Live" ||
          playbackType === "External" ||
          playbackType === "Deeplink") && (
          <div className="flex flex-col items-start justify-start w-full gap-6">
            {formData[name] &&
              formData[name]?.playurls?.map((pu: any, i: number) => (
                <div className="card p-4 grid lg:grid-cols-2 w-full gap-4 items-start justify-center relative">
                  <div className={`absolute top-2 right-2 z-50`}>
                    <button
                      disabled={disablesFields || i18n !== meta.lang}
                      className="text-mb-red/90 hover:text-mb-red group disabled:text-gray-100"
                      onClick={() => handleRemove(i)}
                    >
                      {" "}
                      <svg
                        className="w-6 h-6 "
                        fill="currentColor"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="CancelIcon"
                      >
                        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="inline-block text-base font-medium">
                      File URL
                    </label>
                    <span className="text-xs">
                      Enter the transcoded url (can be HLS, DASH or MSS)
                    </span>
                    <input
                      value={pu.url}
                      type="text"
                      disabled={disablesFields || i18n !== meta.lang}
                      onChange={(e: any) =>
                        handleTextChange(i, e.target.value, "url")
                      }
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="inline-block text-base font-medium">
                      Type
                    </label>
                    <span className="text-xs">
                      Select if it is an audio, video or subtitle file
                    </span>

                    <CreatableSelect
                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      id="choices-single-no-search"
                      name="choices-single-no-search"
                      isSearchable={false}
                      isDisabled={disablesFields || i18n !== meta.lang}
                      options={[
                        { label: "Audio", value: "audio" },
                        { label: "Video", value: "video" },
                        { label: "Subtitle", value: "subtitle" },
                      ]}
                      onChange={(selectedOption: any) => {
                        handleDropdownChange(selectedOption, i, "type");
                      }}
                      value={
                        [
                          { label: "Audio", value: "audio" },
                          { label: "Video", value: "video" },
                          { label: "Subtitle", value: "subtitle" },
                        ].find(
                          (option) =>
                            option.value === formData[name].playurls[i].type
                        ) || null
                      }
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <label className="inline-block text-base font-medium">
                      Format
                    </label>
                    <span className="text-xs">
                      Select encoding format for this external URL
                    </span>
                    <CreatableSelect
                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      id="choices-single-no-search"
                      name="choices-single-no-search"
                      isSearchable={false}
                      isDisabled={disablesFields || i18n !== meta.lang}
                      options={[
                        { label: "Apple HLS", value: "HLS" },
                        { label: "MPEG Dash", value: "DASH" },
                        { label: "Microsoft Smooth Streaming", value: "MSS" },
                        { label: "Others", value: "others" },
                      ]}
                      onChange={(selectedOption: any) => {
                        handleDropdownChange(
                          selectedOption,
                          i,
                          "streaming_format"
                        );
                      }}
                      value={
                        [
                          { label: "Apple HLS", value: "HLS" },
                          { label: "MPEG Dash", value: "DASH" },
                          { label: "Microsoft Smooth Streaming", value: "MSS" },
                          { label: "Others", value: "others" },
                        ].find(
                          (option) =>
                            option.value ===
                            formData[name].playurls[i].streaming_format
                        ) || null
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2 relative w-full">
                    <label className="inline-block text-base font-medium">
                      Profile
                    </label>
                    <span className="text-xs">
                      Select profile for this media (Ignore for subtitles)
                    </span>
                    <CreatableSelect
                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      id="choices-single-no-search"
                      name="choices-single-no-search"
                      isSearchable={false}
                      isDisabled={disablesFields || i18n !== meta.lang}
                      options={[
                        { label: "SD", value: "SD" },
                        { label: "HD", value: "HD" },
                        { label: "FHD", value: "FHD" },
                        { label: "4K", value: "4K" },
                        { label: "UNKNOWN", value: "UNKNOWN" },
                      ]}
                      onChange={(selectedOption: any) => {
                        handleDropdownChange(selectedOption, i, "profile");
                      }}
                      value={
                        [
                          { label: "SD", value: "SD" },
                          { label: "HD", value: "HD" },
                          { label: "FHD", value: "FHD" },
                          { label: "4K", value: "4K" },
                          { label: "UNKNOWN", value: "UNKNOWN" },
                        ].find(
                          (option) =>
                            option.value === formData[name].playurls[i].profile
                        ) || null
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2 relative w-full">
                    <label className="inline-block text-base font-medium">
                      Content Type
                    </label>
                    <span className="text-xs">
                      Select content type for this media (Ignore for subtitles)
                    </span>
                    <CreatableSelect
                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      id="choices-single-no-search"
                      name="choices-single-no-search"
                      isSearchable={false}
                      isDisabled={disablesFields || i18n !== meta.lang}
                      options={[
                        { label: "VOD", value: "VOD" },
                        { label: "LIVE", value: "LIVE" },
                      ]}
                      onChange={(selectedOption: any) => {
                        handleDropdownChange(selectedOption, i, "content_type");
                      }}
                      value={
                        [
                          { label: "VOD", value: "VOD" },
                          { label: "LIVE", value: "LIVE" },
                        ].find(
                          (option) =>
                            option.value ===
                            formData[name].playurls[i].content_type
                        ) || null
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2 relative w-full">
                    <label className="inline-block text-base font-medium">
                      Package Id
                    </label>
                    <span className="text-xs">
                      Enter package id for this media (Ignore for subtitles)
                    </span>
                    <input
                      value={pu.package_id}
                      type="text"
                      disabled={disablesFields || i18n !== meta.lang}
                      onChange={(e: any) =>
                        handleTextChange(i, e.target.value, "package_id")
                      }
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-mb-blue disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    />
                  </div>
                </div>
              ))}

            <button
              type="button"
              onClick={addNew}
              disabled={disablesFields || i18n !== meta.lang}
              className="bg-mb-blue px-4 py-2 text-gray-50 rounded-lg disabled:bg-gray-100 disabled:text-gray-300"
            >
              Add New
            </button>
          </div>
        )}
        {!playbackType && (
          <p className="text-mb-red">Please Select Playback Type</p>
        )}
      </div>
    </div>
  );
};

export default MediaUploader;
