// LOGIN
export const LOGIN = "/user/login";
export const GET_USER_DATA = "/user/me";
export const GET_META_CONFIG = "/meta/config";
export const GET_ASSET_INFO = "/asset/";
export const GET_DRAFT_ASSET_INFO = "/draft/asset/";
export const PUBLISH_ASSET = "/publish/asset/";
export const UNPUBLISH_ASSET = "/unpublish/asset/";
export const UPDATE_ASSET_INFO = "/bulk/assets";
export const GET_META_UI = "/meta/ui.";
export const GET_META_TEMPLATE = "/meta/template.";
export const GET_DASHBOARD_DATA = "/dashboard/stats";
export const PLATFORM = "/platform";
export const PLATFORM_DRAFT = "/draft/platform";
export const PLATFORM_PUBLISH = "/publish/platform";
export const VIEW = "/view";
export const MENU = "/menu";
export const DRAFT_MENU = "/draft/menu";
export const PUBLISH_MENU = "/publish/menu";
export const MENU_LIST_TAGS = "/terms/cms/rails.list.tags";
export const SEARCH_ASSETS = "/search/assets";
export const PRESIGNED_URL = "/storage/signed-url";
export const BULK_UPLOAD_MAPPING = "/mapping";
export const BULK_UPLOAD_JOB = "/job";
export const BULK_IMPORT_JOB = "/job/bulkImport";
export const PROFILE = "/profile";

export const ROLE = "/admin/role";
export const USER = "/admin/user";

export const AUDIT = "/audit";

export const RAIL = "/rail";
export const RAILS_LIST_TAGS = "/terms/cms/rails.list.tags";
export const DRAFT_VIEW = "/draft/view";
export const PUBLISH_VIEW = "/publish/view";
export const RAIL_INSTANCE = "/rail-instance";
