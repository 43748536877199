import { APIClient } from "./api_helper";

import * as url from "./mbentcms_url_helper";

const api = new APIClient();

export const getMetaConfig = () => api.get(url.GET_META_CONFIG);

export const getRules = () => api.get(url.RULE);

export const getRulesByFilters = (filters: any) => {
  let filtersString: string = "";
  Object.keys(filters).forEach((f: any) => {
    if (filters[f])
      if (filtersString) {
        filtersString += `&${f}=${filters[f]}`;
      } else {
        filtersString = `${f}=${filters[f]}`;
      }
  });
  return api.get(`${url.RULE}?${filtersString}`);
};

export const createRuleByName = (title: string) =>
  api.create(url.RULE, { title: title });

export const updateRule = (values: any, id: string | undefined) =>
  api.put(`${url.RULE}/${id}`, values);

export const deleteRule = (id: string | undefined) =>
  api.delete(`${url.RULE}/${id}`, { id });

export const getRulesById = (id: string | undefined) =>
  api.get(`${url.RULE}/${id}`);

export const getAdRules = () => {
  return api.get(url.AD_RULE);
};

export const getAdRulesByFilters = (filters: any) => {
  let filtersString: string = "";
  Object.keys(filters).forEach((f: any) => {
    if (filters[f])
      if (filtersString) {
        filtersString += `&${f}=${filters[f]}`;
      } else {
        filtersString = `${f}=${filters[f]}`;
      }
  });
  return api.get(`${url.AD_RULE}?${filtersString}`);
};

export const createAdRules = (title: string) => {
  return api.create(url.AD_RULE, { title });
};

export const updateAdRules = (values: any, id: string | undefined) => {
  return api.put(`${url.AD_RULE}/${id}`, values);
};

export const deleteAdRules = (id: string | undefined) => {
  return api.delete(`${url.AD_RULE}/${id}`, { id });
};

export const getAdRulesById = (id: string | undefined) => {
  return api.get(`${url.AD_RULE}/${id}`);
};

export const updateAdRule = (values: any, id: string | undefined) =>
  api.put(`${url.AD_RULE}/${id}`, values);
