import { createSelector } from "@reduxjs/toolkit";
import BreadCrumb from "Common/BreadCrumb";
import { formatDate } from "coustumFuntions/capitalFirstLetter";
import {
  getJobBulkUploadByPageNumber,
  getJobBulkUploadData,
} from "helpers/mb-api_helper";
import {
  Plus,
  ChevronsRight,
  ChevronsLeft,
  ChevronRight,
  ChevronLeft,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}

const columnObject: any = {
  id: {
    header: "Id",
    accessorKey: "id",
    enableColumnFilter: false,
    enableSorting: true,
  },
  file_url: {
    header: "File Url",
    accessorKey: "file",
    enableColumnFilter: false,
    enableSorting: true,
  },
  content_type: {
    header: "Content Type",
    accessorKey: "content_type",
    enableColumnFilter: false,
    enableSorting: true,
  },
  status: {
    header: "Status",
    accessorKey: "status",
    enableColumnFilter: false,
    enableSorting: true,
  },
  success_file_url: {
    header: "Success File Url",
    accessorKey: "successfile",
    enableColumnFilter: false,
    enableSorting: true,
  },
  failed_file_url: {
    header: "Failed File Url",
    accessorKey: "errorfile",
    enableColumnFilter: false,
    enableSorting: true,
  },
  created_by: {
    header: "Created By",
    accessorKey: "created_by",
    enableColumnFilter: false,
    enableSorting: true,
  },
  updated_by: {
    header: "Updated By",
    accessorKey: "updated_by",
    enableColumnFilter: false,
    enableSorting: true,
  },
  created_at: {
    header: "Created At",
    accessorKey: "created_at",
    enableColumnFilter: false,
    enableSorting: true,
  },
  updated_at: {
    header: "Updated At",
    accessorKey: "updated_at",
    enableColumnFilter: false,
    enableSorting: true,
  },
};
const initialHeaders = [
  "id",
  "file_url",
  "content_type",
  "status",
  "success_file_url",
  "failed_file_url",
  // "created_by",
  // "updated_by",
  "created_at",
  "updated_at",
];
const BulkUpload = () => {
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      acl: user.acl,
    })
  );

  const { acl } = useSelector(selectProperties);

  const [editAccess, setEditAccess] = useState<any>(false);
  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState<AnyObject>({
    size: 20,
    count: 1,
    from: 1,
    to: 20,
  });

  const handlePageChange = async (value: number) => {
    if (value !== pageNumber)
      try {
        const response: any = await getJobBulkUploadByPageNumber(value);
        setRowData(response.list);
        setTotalCount(response.total);
        setPageNumber(value);
        let pages = Math.ceil(response.total / pagination.size);
        let f = (pageNumber - 1) * 20 + 1;
        let t = pageNumber * 20;
        if (response.total < t) t = response.total;
        setPagination({ ...pagination, count: pages, from: f, to: t });
      } catch (e) {
        console.error("Error fetching assets:", e);
      }
  };

  useEffect(() => {
    const getMappingData = async () => {
      try {
        const response: any = await getJobBulkUploadData(); // Await the API call to fetch platforms
        setRowData(response?.list); // Extract the data from the response and set it in the state
        setTotalCount(response?.total); // Set the total count
        let pages = Math.ceil(response.total / pagination.size);
        let f = (pageNumber - 1) * 20 + 1;
        let t = pageNumber * 20;
        if (response.total < t) t = response.total;
        setPagination({ ...pagination, count: pages, from: f, to: t });
      } catch (e) {
        console.error("Error: " + e);
      }
    };
    getMappingData();
  }, [show]);

  useEffect(() => {
    setEditAccess(() => {
      return (
        acl.includes("ALL") ||
        acl.includes("JOB.CREATE") ||
        acl.includes("JOB.REVERT")
      );
    });
  }, [acl]);

  return (
    <React.Fragment>
      <BreadCrumb title={"Bulk Import"} pageTitle="Asset Management" />
      <div className="text-xs grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="flex items-center card justify-between p-4">
                <h2 className="text-xl font-bold">Bulk Import Jobs</h2>
                {editAccess && (
                  <div className="flex items-center">
                    <Link to="/bulk-upload/add">
                      <button
                        type="button"
                        className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                      >
                        <span className="align-middle"> Create </span>
                        <Plus className="inline-block size-4" />{" "}
                      </button>
                    </Link>
                  </div>
                )}
              </div>

              <div className="overflow-x-auto border border-slate-200 rounded-md">
                <table className="w-full">
                  <thead className="ltr:text-left rtl:text-right ">
                    <tr>
                      {initialHeaders.map((header: any, i: number) => (
                        <th
                          key={i}
                          className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                        >
                          {columnObject[header].header}
                        </th>
                      ))}
                      {/* <th className="px-3.5 sticky right-0 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                        Actions
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {rowData.map((asset: any, i: number) => {
                      return (
                        <tr
                          key={asset.id}
                          className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                        >
                          {initialHeaders.map((header: any, j: number) => {
                            return (
                              <td
                                key={asset.id + j}
                                className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                              >
                                {header === "failed_file_url" ||
                                header === "success_file_url" ? (
                                  asset[columnObject[header].accessorKey] ? (
                                    <a
                                      href={`${process.env.REACT_APP_FILE_CDN}${
                                        asset[columnObject[header].accessorKey]
                                      }`}
                                      download
                                      className="text-blue-600"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Click here to download the file
                                    </a>
                                  ) : (
                                    <>--</>
                                  )
                                ) : header === "created_at" ||
                                  header === "updated_at" ? (
                                  formatDate(
                                    asset[columnObject[header].accessorKey]
                                  )
                                ) : (
                                  asset[columnObject[header].accessorKey]
                                )}
                              </td>
                            );
                          })}
                          {/* <td className="px-3.5 py-2.5 sticky right-0 border z-50 bg-white border-slate-200 dark:border-zink-500">
                            <div className="w-full flex gap-2 justify-end items-center">
                              <div>
                                <button className="w-auto py-0.5 font-bold text-mb-red">
                                  <Trash2Icon
                                    className="inline-block mr-2"
                                    size={15}
                                  />
                                </button>
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {pagination.count > 0 && (
                <div className="pagination flex items-center justify-between py-4">
                  <div className="flex flex-col gap-2">
                    <p className="">
                      Showing From {pagination.from} to {pagination.to} of{" "}
                      {totalCount}
                    </p>
                    <div className="flex gap-2">
                      <p className="">Go to Page</p>
                      <select
                        className=""
                        onChange={(e: any) => handlePageChange(e.target.value)}
                      >
                        {[...Array(pagination.count).keys()].map((int) => (
                          <option
                            selected={pageNumber === int + 1}
                            value={int + 1}
                          >
                            {int + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <ul className="flex flex-wrap items-center gap-2">
                    {pageNumber > 1 && (
                      <li>
                        <button
                          onClick={() => handlePageChange(1)}
                          className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                        >
                          <ChevronsLeft className="size-4 rtl:rotate-180" />
                        </button>
                      </li>
                    )}
                    {pageNumber > 1 && (
                      <li>
                        <button
                          onClick={() => handlePageChange(pageNumber - 1)}
                          className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                        >
                          <ChevronLeft className="size-4 rtl:rotate-180" />
                        </button>
                      </li>
                    )}
                    {[...Array(pagination.count).keys()].map((int) => {
                      return int + 1 >= pageNumber - 1 &&
                        int + 1 <= pageNumber + 1 ? (
                        <li className={pageNumber === int + 1 ? "active" : ""}>
                          <button
                            onClick={() => handlePageChange(int + 1)}
                            className={`${
                              pageNumber === int + 1 ? "active" : ""
                            } inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto`}
                          >
                            {int + 1}
                          </button>
                        </li>
                      ) : (
                        <li>.</li>
                      );
                    })}

                    {pageNumber < pagination.count && (
                      <li>
                        <button
                          onClick={() => handlePageChange(+pageNumber + 1)}
                          className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                        >
                          <ChevronRight className="size-4 rtl:rotate-180" />
                        </button>
                      </li>
                    )}
                    {pageNumber < pagination.count && (
                      <li>
                        <button
                          onClick={() => handlePageChange(pagination.count)}
                          className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                        >
                          <ChevronsRight className="size-4 rtl:rotate-180" />
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BulkUpload;
