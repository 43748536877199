import { createSelector } from "@reduxjs/toolkit";
import BreadCrumb from "Common/BreadCrumb";
import MbLoader from "Common/Components/Icons/MbLoader";
import {
  createProfile,
  deleteProfileById,
  getProfileByFilters,
  getProfiles,
} from "helpers/mb-api_helper";
import SnackBar from "Layout/SnackBar";
import {
  Edit3Icon,
  Plus,
  Trash2Icon,
  X,
  ChevronsRight,
  ChevronRight,
  ChevronLeft,
  ChevronsLeft,
} from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";

interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}
const Profile = () => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      acl: user.acl,
    })
  );

  const { acl } = useSelector(selectProperties);

  const [editAccess, setEditAccess] = useState<any>(true);
  const [allProfilesData, setAllProfilesData] = useState([]);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [createTitle, setCreateTitle] = useState("");
  const [createError, setCreateError] = useState("");
  const [selectedData, setSelectedData] = useState<any>({});
  const [success, setSuccess] = useState("");
  const [popupError, setPopupError] = useState("");
  const [openDeleteViewPopup, setOpenDeleteViewPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState<number>(0); // State to store total
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState<AnyObject>({
    size: 20,
    count: 1,
    from: 1,
    to: 20,
  });

  const createProfileByName = useCallback(async () => {
    if (createTitle !== "") {
      setLoading(true);
      try {
        const profile: any = await createProfile({ name: createTitle });

        navigate("/profile/" + profile.id);
      } catch (e) {
        console.error("Error creating:", e);
        setCreateError("Failed to create profile");
      }
    } else {
      setCreateError("Please enter title");
    }
  }, [createTitle]);

  const handlePageChange = async (value: number) => {
    if (value !== pageNumber) setPageNumber(value);
  };
  const handleCloseDeletePopup = () => {
    setSelectedData("");
    setOpenDeleteViewPopup(false);
    setPopupError("");
  };

  const handleDeleteRole = async () => {
    try {
      await deleteProfileById(selectedData?.id);
      const updateProfiles: any = allProfilesData.filter(
        (profile: any) => profile.id !== selectedData?.id
      );
      setAllProfilesData(updateProfiles);
      setSuccess("Profile deleted successfully");
    } catch (e) {
      console.error("Error deleting profile:", e);
      setPopupError("Failed to delete profile");
    }
    setOpenDeleteViewPopup(false);
  };

  useEffect(() => {
    const updateView = async () => {
      try {
        const response: any = await getProfileByFilters({
          q: searchTerm ?? "",
          page: pageNumber,
        });
        setAllProfilesData(response?.list);
        setTotalCount(response.total);
        let pages = Math.ceil(response.total / pagination.size);
        let f = (pageNumber - 1) * 20 + 1;
        let t = pageNumber * 20;
        if (response.total < t) t = response.total;
        setPagination({ ...pagination, count: pages, from: f, to: t });
      } catch (e) {
        console.error("Error fetching assets:", e);
      }
    };

    updateView();
  }, [pageNumber, searchTerm]);

  useEffect(() => {
    setTimeout(() => {
      if (openAddPopup && inputRef.current) {
        inputRef.current.focus();
      }
    });
  }, [openAddPopup]);
  useEffect(() => {
    const getProfilesData = async () => {
      setLoading(true);
      try {
        const response: any = await getProfiles();
        setAllProfilesData(response?.list || []);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getProfilesData();
  }, []);
  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col text-xs">
        <BreadCrumb title="Profile" pageTitle="Profile" />
        {success && (
          <>
            <SnackBar message={success} onClose={() => setSuccess("")} />
          </>
        )}
        <div className="card">
          <div className="card-body">
            <div className="search-filters card p-4 flex items-center justify-between gap-4">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                name="search"
                placeholder="Search or Filter anything..."
                className="h-8 w-1/2 px-4 border border-gray-300 rounded-md grow-0"
              />
              {editAccess && (
                <button
                  onClick={() => setOpenAddPopup(true)}
                  className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                >
                  Add New <Plus size={10} />{" "}
                </button>
              )}
              <Popup
                modal
                open={openAddPopup}
                onClose={() => setOpenAddPopup(false)}
                overlayStyle={{
                  background: "rgba(0,0,0,0.7)",
                  zIndex: 9999999999999,
                }}
                lockScroll
                closeOnDocumentClick
              >
                <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
                  <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
                    <h6>Add New</h6>
                    <button onClick={() => setOpenAddPopup(false)}>
                      <X size={15} />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 px-4 py-8">
                    {createError && (
                      <p className="text-xs text-mb-red">{createError}</p>
                    )}
                    <label htmlFor="addTitle">Title</label>
                    <input
                      ref={inputRef}
                      value={createTitle}
                      onChange={(e) => setCreateTitle(e.target.value)}
                      type="text"
                      className="px-1 py-2 h-10 border border-mb-blue rounded-md"
                    />
                  </div>
                  <div className="flex py-2 px-4 border-t border-t-mb-blue/50 justify-end gap-4 items-center">
                    <button
                      className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1"
                      onClick={() => setOpenAddPopup(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                      onClick={createProfileByName}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Popup>
            </div>
            {!loading && (
              <div className="overflow-x-auto border border-slate-200 rounded-md">
                <table className="w-full">
                  <thead className="ltr:text-left rtl:text-right ">
                    <tr>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        Title
                      </th>
                      {editAccess && (
                        <th className="px-3.5 sticky right-0 min-w-[100px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                          Actions
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {allProfilesData.map((asset: any, i: number) => {
                      return (
                        <tr
                          key={asset.id}
                          className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                        >
                          <td className="px-3.5 py-2.5 min-w-[300px] border-y border-slate-200 dark:border-zink-500">
                            {asset.name}
                          </td>
                          {editAccess && (
                            <td className="px-3.5 py-2.5 sticky right-0 border z-50 bg-white border-slate-200 dark:border-zink-500">
                              <div className="w-full flex gap-2 justify-end">
                                <div>
                                  <Link
                                    to={`/profile/${asset.id}`}
                                    className="w-auto py-0.5 font-bold text-mb-blue"
                                    title={`edit ${asset?.title}`}
                                  >
                                    <Edit3Icon
                                      className="inline-block mr-2"
                                      size={15}
                                    />
                                  </Link>
                                </div>
                                <div>
                                  <button
                                    className="w-auto py-0.5 font-bold text-mb-red"
                                    onClick={() => {
                                      setSelectedData(asset);
                                      setOpenDeleteViewPopup(true);
                                    }}
                                  >
                                    <Trash2Icon
                                      className="inline-block mr-2"
                                      size={15}
                                    />
                                  </button>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        {pagination.count > 0 && (
          <div className="pagination flex items-center justify-between py-4">
            <div className="flex flex-col gap-2">
              <p className="">
                Showing From {pagination.from} to {pagination.to} of{" "}
                {totalCount}
              </p>
              <div className="flex gap-2">
                <p className="">Go to Page</p>
                <select
                  className=""
                  onChange={(e: any) => handlePageChange(e.target.value)}
                >
                  {[...Array(pagination.count).keys()].map((int) => (
                    <option selected={pageNumber === int + 1} value={int + 1}>
                      {int + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <ul className="flex flex-wrap items-center gap-2">
              {pageNumber > 1 && (
                <li>
                  <button
                    onClick={() => handlePageChange(1)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronsLeft className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
              {pageNumber > 1 && (
                <li>
                  <button
                    onClick={() => handlePageChange(pageNumber - 1)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronLeft className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
              {[...Array(pagination.count).keys()].map((int) => {
                return int + 1 >= pageNumber - 1 &&
                  int + 1 <= pageNumber + 1 ? (
                  <li className={pageNumber === int + 1 ? "active" : ""}>
                    <button
                      onClick={() => handlePageChange(int + 1)}
                      className={`${
                        pageNumber === int + 1 ? "active" : ""
                      } inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto`}
                    >
                      {int + 1}
                    </button>
                  </li>
                ) : (
                  <li>.</li>
                );
              })}

              {pageNumber < pagination.count && (
                <li>
                  <button
                    onClick={() => handlePageChange(pageNumber + 1)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronRight className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
              {pageNumber < pagination.count && (
                <li>
                  <button
                    onClick={() => handlePageChange(pagination.count)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronsRight className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}

        {loading && <MbLoader />}

        {/* delete Po up */}
        <Popup
          open={openDeleteViewPopup}
          onClose={handleCloseDeletePopup}
          modal
          lockScroll
          closeOnDocumentClick
          overlayStyle={{ background: "rgba(0,0,0,0.6)", zIndex: 999999 }}
        >
          <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
            <button
              onClick={handleCloseDeletePopup}
              className="absolute right-4 top-4"
            >
              <X size={30} />
            </button>
            <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
              Delete {selectedData?.title}
            </div>
            <div className="text-sm text-gray-900 p-4">
              Are you sure you want to delete the {selectedData?.title}. This
              action cannot be undone.
            </div>
            <div className="flex justify-end gap-4 p-4">
              <button
                className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1 "
                onClick={handleDeleteRole}
              >
                Delete
              </button>
              <button
                className="bg-gray-300 text-gray-900 rounded-md hover:bg-gray-300/30 hover:text-gray-900 px-4 py-2 flex items-center gap-1"
                onClick={handleCloseDeletePopup}
              >
                Cancel
              </button>
            </div>
            <div className="flex justify-end p-4">
              {popupError && <p className="text-red-500"> {popupError}</p>}
            </div>
          </div>
        </Popup>
      </div>
    </React.Fragment>
  );
};

export default Profile;
