import { createSelector } from "@reduxjs/toolkit";
import BreadCrumb from "Common/BreadCrumb";
import Alert from "Common/Components/Alert";
import MbLoader from "Common/Components/Icons/MbLoader";
import { getRoles, createRole, getRolesByFilters } from "helpers/mb-api_helper";
import {
  Copy,
  Plus,
  Search,
  FileEdit,
  CheckCircle,
  Loader,
  ChevronsRight,
  ChevronRight,
  ChevronLeft,
  ChevronsLeft,
  Edit3Icon,
  Trash2Icon,
  Settings,
  X,
  ArrowDownUp,
} from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}

const tableHeadersOptions = ["name", "id"];

const sortableData = [
  { title: "Name", value: "name" },
  { title: "Updated At", value: "v_tt" },
];

const ListRoles = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      acl: user.acl,
    })
  );

  const { acl } = useSelector(selectProperties);

  const [editAccess, setEditAccess] = useState<any>(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const [createError, setCreateError] = useState("");
  const [copyRoleData, setCopyRoleData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [rolesData, setRolesData] = useState<any>([]);

  const [totalCount, setTotalCount] = useState<number>(0); // State to store total
  const [tableHeaders, setTableHeaders] = useState(["id", "name"]);
  const [pageNumber, setPageNumber] = useState(1);
  const [show, setShow] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [pageSize, setPageSize] = useState<any>(20);
  const [pagination, setPagination] = useState<AnyObject>({
    size: 20,
    count: 1,
    from: 1,
    to: 20,
  });
  const [sort, setSort] = useState("v_tt:desc");

  const columnObject: any = useMemo(
    () => ({
      name: {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      id: {
        header: "Id",
        accessorKey: "id",
        enableColumnFilter: false,
      },
    }),
    []
  );

  const handleSearchViews = async (e: any) => {
    const searchKey = e.target.value;
    setSearchTerm(searchKey);
    setPageNumber(1);
    setSort("v_tt:desc");
  };

  const handlePageChange = async (value: number) => {
    if (value !== pageNumber) setPageNumber(value);
  };

  const handleCloseAddPopup = () => {
    setOpenAddPopup(false);
    setTitle("");
    setCopyRoleData({});
    setCreateError("");
  };

  const createRoles = async () => {
    if (title === "") {
      setCreateError("Please enter a title");
      return;
    }
    const defaultActions = [
      "META.READ",
      "USER.AUTH",
      "USER.READ",
      "STORAGE.WRITE",
      "TERMS.READ",
    ];
    try {
      if (copyRoleData?.id) {
        const data = await createRole({ acl: copyRoleData?.acl, name: title });
        setRolesData((preVal: any) => [data, ...preVal]);
      } else {
        const data: any = await createRole({
          name: title,
          acl: { actions: defaultActions },
        });
        setRolesData((preVal: any) => [data, ...preVal]);
      }
      setSuccess("Create role successfully ");
      setTimeout(() => {
        setSuccess("");
      }, 5000);
      handleCloseAddPopup();
    } catch (error) {
      setCreateError("Unable to create role");
      setTimeout(() => {
        setCreateError("");
      }, 5000);
      setLoading(false);
    }
  };

  const handleCopyRoleData = async (id: string) => {
    try {
      setOpenAddPopup(true);
      const roles: any = await getRoles(id);
      setCopyRoleData(roles);
    } catch (error) {
      setError("Unable to get role data");
      handleCloseAddPopup();
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  useEffect(() => {
    const updateView = async () => {
      try {
        const response: any = await getRolesByFilters({
          q: searchTerm ?? "",
          page: pageNumber,
          sort: sort,
        });
        setRolesData(response?.list);
        setTotalCount(response.total);
        let pages = Math.ceil(response.total / pagination.size);
        let f = (pageNumber - 1) * 20 + 1;
        let t = pageNumber * 20;
        if (response.total < t) t = response.total;
        setPagination({ ...pagination, count: pages, from: f, to: t });
      } catch (e) {
        console.error("Error fetching assets:", e);
      }
    };

    updateView();
  }, [sort, pageNumber, searchTerm]);

  useEffect(() => {
    setTimeout(() => {
      if (openAddPopup && inputRef.current) {
        inputRef.current.focus();
      }
    });
  }, [openAddPopup]);

  useEffect(() => {
    setEditAccess(() => {
      return (
        acl.includes("ALL") ||
        acl.includes("ADMIN.ROLE.CREATE") ||
        acl.includes("ADMIN.ROLE.UPDATE")
      );
    });
  }, [acl]);

  useEffect(() => {
    const fetchRolesData = async () => {
      try {
        const roles: any = await getRoles();
        setRolesData(roles?.list);
        setLoading(false);
        setSuccess("");
        setTotalCount(roles.total);
        let pages = Math.ceil(roles.total / pagination.size);
        let f = (pageNumber - 1) * 20 + 1;
        let t = pageNumber * 20;
        if (roles.total < t) t = roles.total;
        setPagination({ ...pagination, count: pages, from: f, to: t });
      } catch (error) {
        setError("Unable to get roles list");
        setLoading(false);
      }
    };
    fetchRolesData();
  }, []);

  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col text-xs">
        <BreadCrumb title={`Manage Roles`} pageTitle="Roles" />

        <div className="card">
          <div className="card-body">
            <div className="search-filters card p-4 flex items-center justify-between gap-4">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchViews}
                name="search"
                placeholder="Search by role name..."
                className="h-8 w-1/2 px-4 border border-gray-300 rounded-md grow-0"
              />

              <div className="flex flex-row gap-4 justify-end items-center">
                {/* <Popup
                  arrow
                  on={"click"}
                  position={"bottom right"}
                  trigger={
                    <p title={`Sort`}>
                      <ArrowDownUp
                        size={15}
                        className="text-mb-blue cursor-pointer"
                      ></ArrowDownUp>
                    </p>
                  }
                >
                  <div className="w-[200px] flex flex-col gap-0 border border-gray-300 bg-white mt-2 rounded-md overflow-hidden">
                    <div className="bg-mb-blue text-white py-1 px-2 font-bold">
                      Choose Sort Order
                    </div>
                    {sortableData.map((sortField: any, i: number) => {
                      return (
                        <div key={i}>
                          <div className="flex items-center gap-4  px-4 py-1">
                            <input
                              onChange={(e) => {
                                e.target.checked
                                  ? setSort(`${sortField?.value}:asc`)
                                  : setSort("");
                                setPageNumber(1);
                              }}
                              checked={sort === `${sortField.value}:asc`}
                              type="checkbox"
                              id={`sortable-asc-${sortField.value}`}
                            />
                            <label htmlFor={`sortable-asc-${sortField.value}`}>
                              {sortField.title} ASC
                            </label>
                          </div>
                          <div className="flex items-center gap-4  px-4 py-1">
                            <input
                              onChange={(e) => {
                                e.target.checked
                                  ? setSort(`${sortField.value}:desc`)
                                  : setSort("");
                                setPageNumber(1);
                              }}
                              checked={sort === `${sortField.value}:desc`}
                              type="checkbox"
                              id={`sortable-desc-${sortField.value}`}
                            />
                            <label htmlFor={`sortable-desc-${sortField.value}`}>
                              {sortField.title} Desc
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Popup> */}

                {editAccess && (
                  <button
                    onClick={() => setOpenAddPopup(true)}
                    className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                  >
                    Add New <Plus size={10} />{" "}
                  </button>
                )}
              </div>
            </div>
            {/* <div className="flex justify-end my-2">
              <button
                onClick={() => setOpenAddPopup(true)}
                className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
              >
                Add New <Plus size={10} />{" "}
              </button>
            </div> */}
            <div className="errors success">
              {success && (
                <Alert className="relative px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
                  <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-green-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                  <Alert.Bold>Success! </Alert.Bold>
                  {success}
                </Alert>
              )}
              {error && (
                <Alert className="relative px-4 py-3 text-sm text-orange-500 border border-transparent rounded-md bg-orange-50 dark:bg-orange-400/20">
                  <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-orange-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                  <Alert.Bold>Error! </Alert.Bold>
                  {error}
                </Alert>
              )}
            </div>
            {!loading && (
              <div className="overflow-x-auto scrollbar-hide border border-slate-200 rounded-md">
                <table className="w-full">
                  <thead className="ltr:text-left rtl:text-right ">
                    <tr>
                      {tableHeaders.map((header: any, i: number) => (
                        <th
                          key={i}
                          className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                        >
                          {header}
                        </th>
                      ))}
                      {/* <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        ID
                      </th>
                      <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                        Name
                      </th> */}

                      {editAccess && (
                        <th className="px-3.5 sticky right-0 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                          Actions
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {rolesData.map((asset: any, i: number) => {
                      return (
                        <tr
                          key={asset.id}
                          className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                        >
                          {tableHeaders.map((header: any, j: number) => {
                            return (
                              <td
                                key={header}
                                className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500"
                              >
                                {asset[columnObject[header].accessorKey]}
                              </td>
                            );
                          })}
                          {/* <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {asset?.id}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                            {asset?.name}
                          </td> */}
                          {editAccess && (
                            <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500 flex justify-end gap-4">
                              <button
                                onClick={() => handleCopyRoleData(asset?.id)}
                                className="w-auto py-0.5 font-bold text-mb-blue"
                                title={`create duplicate`}
                              >
                                <Copy className="inline-block mr-2" size={15} />
                              </button>
                              <Link
                                to={`${asset?.id}`}
                                className="w-auto py-0.5 font-bold text-mb-blue"
                                title={`Edit ${asset.name} `}
                              >
                                <Edit3Icon
                                  className="inline-block mr-2"
                                  size={15}
                                />
                              </Link>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {pagination.count > 0 && (
          <div className="pagination flex items-center justify-between py-4">
            <div className="flex flex-col gap-2">
              <p className="">
                Showing From {pagination.from} to {pagination.to} of{" "}
                {totalCount}
              </p>
              <div className="flex gap-2">
                <p className="">Go to Page</p>
                <select
                  className=""
                  onChange={(e: any) => handlePageChange(e.target.value)}
                >
                  {[...Array(pagination.count).keys()].map((int) => (
                    <option selected={pageNumber === int + 1} value={int + 1}>
                      {int + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <ul className="flex flex-wrap items-center gap-2">
              {pageNumber > 1 && (
                <li>
                  <button
                    onClick={() => handlePageChange(1)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronsLeft className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
              {pageNumber > 1 && (
                <li>
                  <button
                    onClick={() => handlePageChange(pageNumber - 1)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronLeft className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
              {[...Array(pagination.count).keys()].map((int) => {
                return int + 1 >= pageNumber - 1 &&
                  int + 1 <= pageNumber + 1 ? (
                  <li className={pageNumber === int + 1 ? "active" : ""}>
                    <button
                      onClick={() => handlePageChange(int + 1)}
                      className={`${
                        pageNumber === int + 1 ? "active" : ""
                      } inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto`}
                    >
                      {int + 1}
                    </button>
                  </li>
                ) : (
                  <li>.</li>
                );
              })}

              {pageNumber < pagination.count && (
                <li>
                  <button
                    onClick={() => handlePageChange(pageNumber + 1)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronRight className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
              {pageNumber < pagination.count && (
                <li>
                  <button
                    onClick={() => handlePageChange(pagination.count)}
                    className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                  >
                    <ChevronsRight className="size-4 rtl:rotate-180" />
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
        {loading && <MbLoader />}

        <Popup
          modal
          overlayStyle={{ background: "rgba(0,0,0,0.7)", zIndex: 9999999999 }}
          lockScroll
          open={openAddPopup}
          onClose={handleCloseAddPopup}
          closeOnDocumentClick
        >
          <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
            <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
              <h6>Add New</h6>
              <button onClick={handleCloseAddPopup}>
                <X size={15} />
              </button>
            </div>
            <div className="flex flex-col gap-2 px-4 py-8">
              {createError && (
                <p className="text-xs text-mb-red">{createError}</p>
              )}
              <label htmlFor="addTitle">Title</label>
              <input
                ref={inputRef}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                className="px-1 py-2 h-10 border border-mb-blue rounded-md"
              />
            </div>
            <div className="flex py-2 px-4 border-t border-t-mb-blue/50 justify-end gap-4 items-center">
              <button
                className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1"
                onClick={() => {
                  handleCloseAddPopup();
                }}
              >
                Cancel
              </button>
              <button
                className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                onClick={createRoles}
              >
                Submit
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </React.Fragment>
  );
};

export default ListRoles;
