import React, { Suspense } from "react";
import TextBox from "../formsComponents/TextBox";
import TextArea from "components/formsComponents/TextArea";
import TagsBox from "components/formsComponents/TagsBox";
import MultiSelect from "components/formsComponents/MultiSelect";
import SingleSelect from "components/formsComponents/SingleSelect";
import EmptyBox from "components/formsComponents/EmptyBox";
import ObjectBox from "components/formsComponents/ObjectBox";
import FileUpload from "components/formsComponents/FileUpload";
import CheckBox from "components/formsComponents/CheckBox";
import DatePickerBox from "components/formsComponents/DatePickerBox";
import ArrayOfObjectBox from "components/formsComponents/ArrayOfObjectBox";
import VideoTimingBox from "components/formsComponents/VideoTimingBox";
import MediaUploader from "components/formsComponents/MediaUploader";
import ImageUploader from "components/formsComponents/ImageUploader";
import AssetSelector from "components/formsComponents/AssetSelector";
import Editor from "components/formsComponents/Editor";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import NumberBox from "components/formsComponents/NumberBox";

interface dynamicFormComponent {
  name: string;
  props: any;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  i18n?: string;
  required?: string[];
  disablesFields: boolean;
}
const componentMap: {
  [key: string]: React.FC<any>;
} = {
  textBox: TextBox,
  textArea: TextArea,
  numberBox: NumberBox,
  tagBox: TagsBox,
  multiSelectBox: MultiSelect,
  selectBox: SingleSelect,
  emptyBox: EmptyBox,
  objectBox: ObjectBox,
  fileupload: FileUpload,
  checkBox: CheckBox,
  dateTimePicker: DatePickerBox,
  datepicker: DatePickerBox,
  contributorsBox: ArrayOfObjectBox,
  videoTimerBox: VideoTimingBox,
  mediaUploader: MediaUploader,
  imageBoxRatio: ImageUploader,
  imageBoxNoRatio: ImageUploader,
  searchableSelect: AssetSelector,
  editor: Editor,
};
const GetComponent: React.FC<dynamicFormComponent> = ({
  name,
  props,
  formData = "",
  setFormData,
  i18n,
  required,
  disablesFields,
}) => {
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);
  const Component = componentMap[props.uiElement] || TextBox;
  if (!Component) return null;
  return (
    <Component
      disablesFields={disablesFields}
      name={name}
      props={props}
      formData={formData}
      setFormData={setFormData}
      i18n={i18n ? i18n : meta.lang}
      required={required}
    />
  );
};

export default React.memo(GetComponent);
