import { loginError, loginSuccess, logoutSuccess } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import { getMetaConfig, getUser, login } from "helpers/mb-api_helper";

interface User {
  account: string;
  name: string;
  password: string;
}

export const loginUser =
  (
    user: User,
    history: any
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      let response: any;
      response = await login({
        account: user.account,
        name: user.name,
        password: user.password,
      });
      if (response) {
        dispatch(loginSuccess({ ...response.token }));
        window.location.href = "/dashboard";
      }
    } catch (error) {
      dispatch(loginError(error));
    }
  };

export const logoutUser = () => async (dispatch: Dispatch) => {
  try {
    localStorage.removeItem("authUser");
    dispatch(logoutSuccess(true));
  } catch (error) {
    dispatch(loginError(error));
  }
};
