import { createSelector } from "@reduxjs/toolkit";
import {
  BarChart3Icon,
  Book,
  BookOpenCheckIcon,
  FileSpreadsheetIcon,
  FileUp,
  FilmIcon,
  FolderClock,
  LayoutDashboard,
  ListChecks,
  LucideCircleEllipsis,
  LucideMonitorDot,
  Mail,
  User2Icon,
  Users2Icon,
  Video,
} from "lucide-react";
import { useSelector } from "react-redux";
import { validate } from "uuid";

const selectProperties = createSelector(
  (state: any) => state.User,
  (user) => ({
    meta: user.meta,
    acl: user.acl,
  })
);
export const useMenuData = () => {
  const { meta, acl } = useSelector(selectProperties);
  console.log("meta ==", meta);
  console.log("acl ==", acl);

  const menuData: any = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: <LayoutDashboard />,
      link: "/dashboard",
    },
    {
      label: "Content Management",
      isTitle: true,
    },

    {
      id: "assets",
      label: "Content",
      icon: <Video />,
      link: `/assets/${meta.assetTypes[0]}`,
      subItems: [
        ...meta?.assetTypes?.map((asset: string) => {
          return {
            id: asset,
            label: asset.replace("_", " "),
            icon: <Mail />, // Ensure that <Mail /> is a valid React component
            link: `/assets/${asset}`,
            parentId: "assets",
          };
        }),
      ],
      validate: "ASSET.LIST",
    },
    {
      id: "media",
      label: "Media",
      icon: <FilmIcon />,
      link: "/assets/media",
      validate: "ASSET.LIST",
    },
    // {
    //   id: "profile",
    //   label: "Transcoding Profile",
    //   icon: <Book />,
    //   link: "/profile",
    // },
    {
      id: "bulkUpload",
      label: "Bulk Upload",
      icon: <FileUp />,
      link: "/bulk-upload",
      validate: "JOB.LIST",
    },
    {
      label: "Store Front",
      isTitle: true,
    },
    {
      id: "platform",
      label: "Platforms",
      icon: <LucideMonitorDot />,
      link: "/platforms",
      validate: "PLATFORM.LIST",
    },
    {
      id: "views",
      label: "Pages",
      icon: <BookOpenCheckIcon />,
      link: "/views",
      validate: "VIEW.LIST",
    },
    {
      id: "menu",
      label: "Menus",
      icon: <LucideCircleEllipsis />,
      link: "/menus",
      validate: "MENU.LIST",
    },
    {
      id: "staticPage",
      label: "Static Pages",
      icon: <BookOpenCheckIcon />,
      link: "/assets/staticPage",
      validate: "ASSETS.LIST",
    },
    {
      label: "Entitlement",
      isTitle: true,
    },
    {
      id: "businessRules",
      label: "Business Rules",
      icon: <ListChecks />,
      link: "/business-rules",
      parentId: 2,
      validate: "RULE.LIST",
    },
    {
      id: "ad",
      label: "Ad Rules",
      icon: <ListChecks />,
      link: "/ad-rules",
      parentId: 2,
      validate: "ADS_RULE.LIST",
    },
    {
      label: "ACCESS CONTROL",
      isTitle: true,
    },
    {
      id: "adminUser",
      label: "Admin Users",
      icon: <User2Icon />,
      link: "/user/profile",
      parentId: 2,
      validate: "ADMIN.USER.LIST",
    },
    {
      id: "teams",
      label: "Roles",
      icon: <Users2Icon />,
      link: "/user/roles",
      parentId: 2,
      validate: "ADMIN.ROLE.LIST",
    },
    {
      id: "audit",
      label: "Audit",
      icon: <FolderClock />,
      link: "/audit",
      parentId: 2,
      validate: "AUDIT.LIST",
    },
  ];

  const filteredMenuData = acl?.includes("ALL")
    ? menuData
    : menuData.filter((item: any) => {
        // Titles and items without IDs are always included
        if (item.isTitle || !item.validate) return true;

        // Add your ACL condition
        return acl?.includes(`${item.validate}`);
      });
  return filteredMenuData;
};
// export { menuData };
