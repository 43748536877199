import { createSelector } from "@reduxjs/toolkit";
import RightDrawer from "Layout/RightDrawer";
import { Info, X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";

interface TimeComponent {
  hh: number | string;
  mm: number | string;
  sec: number | string;
}

interface InputProps {
  name: string;
  props: any;
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  i18n: string;
}

const VideoTimingBox: React.FC<InputProps> = ({
  name,
  props,
  formData = {},
  setFormData,
  i18n,
}) => {
  const [timeError, setTimeError] = useState({ message: "", active: false });
  const [showHelper, setShowHelper] = useState(false);
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);
  const [startTime, setStartTime] = useState<TimeComponent>({
    hh: "",
    mm: "",
    sec: "",
  });
  const [endTime, setEndTime] = useState<TimeComponent>({
    hh: "",
    mm: "",
    sec: "",
  });

  const startHhRef = useRef<HTMLInputElement>(null);
  const startMmRef = useRef<HTMLInputElement>(null);
  const startSecRef = useRef<HTMLInputElement>(null);
  const endHhRef = useRef<HTMLInputElement>(null);
  const endMmRef = useRef<HTMLInputElement>(null);
  const endSecRef = useRef<HTMLInputElement>(null);

  const [chips, setChips] = useState<string[]>([]);

  const handleStartTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    val: string
  ) => {
    const { value } = e.target;
    if (timeError.active) {
      setTimeError({ message: "", active: false });
    }
    if (/^\d*$/.test(value) && +value < 60)
      setStartTime((preVal) => {
        let newState = { ...preVal, [val]: value };
        if (val === "mm" && newState.hh === "") {
          newState = { ...preVal, [val]: value, hh: "00" };
        } else if (val === "sec" && newState.mm === "") {
          newState = { ...preVal, [val]: value, mm: "00" };
        }
        if (value.length === 2) {
          if (val === "hh" && startMmRef.current) {
            startMmRef.current.focus();
          } else if (val === "mm" && startSecRef.current) {
            startSecRef.current.focus();
          } else if (val === "sec" && endHhRef.current) {
            endHhRef.current.focus();
          }
        }

        return newState;
      });
  };

  const handleEndTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    val: string
  ) => {
    const { value } = e.target;
    if (timeError.active) {
      setTimeError({ message: "", active: false });
    }
    if (val === "mm") {
      if (endTime.hh < startTime.hh) {
        setTimeError({
          message: "end time should be greater than start time",
          active: true,
        });
        return;
      }
    }
    if (val === "sec") {
      if (endTime.hh <= startTime.hh && endTime.mm < startTime.mm) {
        setTimeError({
          message: "end time should be greater than start time",
          active: true,
        });
        return;
      }
    }
    if (value.length === 2 && val === "sec") {
      if (
        endTime.hh <= startTime.hh &&
        endTime.mm <= startTime.mm &&
        value < startTime.sec
      ) {
        setTimeError({
          message: "end time should be greater than start time",
          active: true,
        });
        return;
      }
    }

    if (/^\d*$/.test(value) && +value < 60)
      setEndTime((preVal) => {
        const newState = { ...preVal, [val]: value };

        if (value.length === 2) {
          if (val === "hh" && endMmRef.current) {
            endMmRef.current.focus();
          } else if (val === "mm" && endSecRef.current) {
            endSecRef.current.focus();
          } else if (val === "sec" && startHhRef.current) {
            const timeString = `${startTime.hh}:${startTime.mm}:${startTime.sec}/${endTime.hh}:${endTime.mm}:${value}`;
            if (!chips.includes(timeString)) {
              setChips([...chips, timeString]);
              setFormData({
                ...formData,
                [name]: [...chips, timeString.trim()],
              });
              setStartTime({
                hh: "",
                mm: "",
                sec: "",
              });
              setEndTime({
                hh: "",
                mm: "",
                sec: "",
              });
            }
            startHhRef.current.focus();
          }
        }

        return newState;
      });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && startHhRef.current) {
      e.preventDefault();
      const keys: (keyof typeof startTime)[] = ["hh", "mm", "sec"];
      if (keys.some((key) => startTime[key] === "" || endTime[key] === "")) {
        setTimeError({
          message: "Fill all fields with some values",
          active: true,
        });
        return;
      }
      const timeString = `${startTime.hh} : ${startTime.mm} : ${startTime.sec} / ${endTime.hh} : ${endTime.mm} : ${endTime.sec}`;
      if (!chips.includes(timeString)) {
        setChips([...chips, timeString]);
        setFormData({ ...formData, [name]: [...chips, timeString.trim()] });
        // setFormValues((prevValues) => ({
        //   ...prevValues,
        //   [field]: [...chips, timeString.trim()],
        // }));
        setStartTime({
          hh: "",
          mm: "",
          sec: "",
        });
        setEndTime({
          hh: "",
          mm: "",
          sec: "",
        });
        startHhRef.current.focus();
      } else {
        setTimeError({
          message: "This time already added",
          active: true,
        });
      }
    }
  };

  const handleDeleteChip = (chipToDelete: string) => {
    setChips(chips.filter((chip) => chip !== chipToDelete));
    setFormData({
      ...formData,
      [name]: [chips.filter((chip) => chip !== chipToDelete)],
    });
    // setFormValues((prevValues) => ({
    //   ...prevValues,
    //   [field]: [chips.filter((chip) => chip !== chipToDelete)],
    // }));
  };

  useEffect(() => {
    if (formData[name] && formData[name].length > 0) {
      setChips(() =>
        formData[name].map((item: string) => item.replace(/\s+/g, ""))
      );
    } else {
      setChips([]);
    }
  }, [formData[name]]);

  return (
    <div
      className={`flex flex-col gap-1 ${
        props.cols ? `lg:col-span-${props.cols}` : "lg:col-span-6"
      }`}
    >
      <div className="flex gap-1 items-center">
        <label className="text-sm">{props.name}</label>
        {props.longDesc && (
          <>
            <button
              className="text-mb-blue"
              onClick={() => setShowHelper(true)}
            >
              <Info size={15} />
            </button>
            <Popup
              open={showHelper}
              onClose={() => setShowHelper(false)}
              modal
              lockScroll
              closeOnDocumentClick
              contentStyle={{
                top: 0,
                right: 0,
                height: "100vh",
                position: "fixed",
              }}
              overlayStyle={{ background: "rgba(0,0,0,0.6)", zIndex: 999999 }}
            >
              <div className="h-screen bg-white w-[20rem] relative">
                <button
                  onClick={() => setShowHelper(false)}
                  className="absolute right-4 top-4"
                >
                  <X size={30} />
                </button>
                <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
                  {props.longDesc.title}
                </div>
                <div
                  className="text-sm text-gray-900 p-4 flex flex-col gap-4"
                  dangerouslySetInnerHTML={{ __html: props.longDesc.body }}
                ></div>
              </div>
            </Popup>
          </>
        )}
      </div>
      <span className="text-xs">{props.shortDesc}</span>
      <div
        aria-disabled={i18n !== meta.lang}
        className=" flex flex-wrap gap-4 border items-baseline aria-disabled:bg-gray-100 aria-disabled:cursor-not-allowed border-mb-blue bg-white rounded-lg p-2"
      >
        <div className="flex flex-wrap gap-3 items-center">
          {chips.map((chip, index) => (
            <div
              key={index}
              className="flex items-center bg-gray-100 text-black text-sm px-2 py-1 rounded-lg"
            >
              {chip}
              <button
                onClick={() => handleDeleteChip(chip)}
                className="ml-1 text-black hover:text-gray-200 focus:outline-none"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
        <div className="">
          <div className="flex gap-2 items-center flex-wrap">
            <div className="flex gap-2">
              <input
                disabled={i18n !== meta.lang}
                ref={startHhRef}
                type="text"
                value={startTime.hh}
                onChange={(e) => handleStartTimeChange(e, "hh")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
              <p> : </p>
              <input
                disabled={i18n !== meta.lang}
                ref={startMmRef}
                type="text"
                value={startTime.mm}
                onChange={(e) => handleStartTimeChange(e, "mm")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
              <p> : </p>
              <input
                disabled={i18n !== meta.lang}
                ref={startSecRef}
                type="text"
                value={startTime.sec}
                onChange={(e) => handleStartTimeChange(e, "sec")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
            </div>
            <p> / </p>
            <div className="flex gap-2">
              <input
                disabled={i18n !== meta.lang}
                ref={endHhRef}
                type="text"
                value={endTime.hh}
                onChange={(e) => handleEndTimeChange(e, "hh")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
              <p> : </p>
              <input
                disabled={i18n !== meta.lang}
                ref={endMmRef}
                type="text"
                value={endTime.mm}
                onChange={(e) => handleEndTimeChange(e, "mm")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
              <p> : </p>
              <input
                disabled={i18n !== meta.lang}
                ref={endSecRef}
                type="text"
                value={endTime.sec}
                onChange={(e) => handleEndTimeChange(e, "sec")}
                onKeyDown={handleKeyDown}
                placeholder="00"
                className="w-5 outline-none border-none"
              />
            </div>
          </div>
          {timeError.active && (
            <p className="text-sm text-red-600 mb-2">{timeError.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoTimingBox;
