import { createSelector } from "@reduxjs/toolkit";
import BreadCrumb from "Common/BreadCrumb";
import GetComponent from "components/commonComponents/getComponent";
import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import {
  deleteAssetById,
  getContent,
  updateDraftAssetByData,
  getDraftContentDataById,
  getMetaTemplateData,
  getMetaUiData,
  publishAssetById,
} from "helpers/mb-api_helper";
import SnackBar from "Layout/SnackBar";
import isoLangs from "lib/languages";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  Globe2,
  LucideProps,
  SaveAll,
  Settings,
  ShieldCheck,
  Trash2,
  Undo,
  X,
} from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}
///Show lucide Icon  Dynamically
const fallback = <div style={{ background: "#ddd", width: 24, height: 24 }} />;
interface IconProps extends Omit<LucideProps, "ref"> {
  name: keyof typeof dynamicIconImports;
}
const Icon = ({ name, ...props }: IconProps) => {
  const LucideIcon = React.lazy(dynamicIconImports[name]);
  return (
    <Suspense fallback={fallback}>
      <LucideIcon {...props} />
    </Suspense>
  );
};

///End show lucid icon dynamically
const AssetsEditPage = () => {
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
      acl: user.acl,
    })
  );
  let { meta, acl } = useSelector(selectProperties);
  const navigate = useNavigate();
  const { type, id, status } = useParams<{
    type: string;
    id: string;
    status: string;
  }>(); // Destructure `type` from useParams

  const [access, setAccess] = useState<any>({});
  const [assetTemplate, setAssetTemplate] = useState<AnyObject>({});
  const [i18n, setI18n] = useState(meta.lang);
  const [assetUI, setAssetUI] = useState<AnyObject>({});
  const [assetData, setAssetData] = useState<AnyObject>({});
  const [formData, setFormData] = useState<AnyObject>({});
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [openDeleteViewPopup, setOpenDeleteViewPopup] = useState(false);
  const [openCreateDraftPopup, setOpenCreateDraftPopup] = useState(false);
  const [selectedSection, setSelectedSection] = useState(0);
  const [popupError, setPopupError] = useState("");
  const [publishedView, setPublishedView] = useState(false);
  const [originalAssetData, setOriginalAssetData] = useState<AnyObject>({});
  const [saveDraftData, setSaveDraftData] = useState(false);

  const handleCloseDeletePopup = () => {
    setOpenDeleteViewPopup(false);
    setPopupError("");
  };

  const handleCloseCreateDraftPopup = () => {
    if (!assetData.id) navigate(`/assets/${type}`);
  };

  const handleDeleteView = async () => {
    try {
      await deleteAssetById(id, type);
      handleCloseDeletePopup();
      setSuccess("successfully deleted");
      setTimeout(() => {
        navigate(`/assets/${type}`);
      }, 1000);
    } catch (e: any) {
      console.error("error ===== 0000", e);
      setPopupError("Unauthorized: you don't have permission to delete");
    }
  };

  const checkValidations = (data: any) => {
    // Find missing fields
    const missingFields = assetTemplate.required?.filter((field: any) => {
      const value = data[field];

      if (Array.isArray(value)) {
        // Return true if the array is empty
        return value.length === 0;
      } else if (typeof value === "string") {
        // Return true if the string is empty or whitespace
        return value.trim() === "";
      } else if (value !== null && typeof value === "object") {
        // Return true if the object has no keys
        return Object.keys(value).length === 0;
      }

      // Return true for null, undefined, or invalid types
      return value == null;
    });

    if (missingFields.length > 0) {
      // Set error message if any field is missing
      setError(
        `The following fields are empty: ${missingFields.join(
          ", "
        )}. Please fill them and then submit your data.`
      );
      setSaveDraftData(false);
      return false;
    }

    // Clear error and proceed
    setError(""); // Clear any previous error
    return true;
  };

  const handleCreateDraft = async () => {
    try {
      await updateDraftAssetByData(id, originalAssetData);
      setSuccess("Successfully created draft");
      setOpenCreateDraftPopup(false);
      setAssetData(originalAssetData);
      setFormData(originalAssetData);
    } catch (err) {
      setPopupError(
        "something went wrong we cannot create draft view try letter"
      );
    }
  };

  const changeTab = useCallback(async () => {
    if (JSON.stringify(assetData) !== JSON.stringify(formData)) {
      try {
        let updateRes = await updateDraftAssetByData(assetData.id, formData);
        setAssetData({ ...updateRes });
        setFormData({ ...updateRes });
        setSuccess("Asset saved successfully!");
      } catch (e) {
        console.error(e);
        setError("There was a problem saving asset.");
      }
    }
  }, [assetData, formData, type]);

  const saveDraft = useCallback(async () => {
    try {
      let updateRes = await updateDraftAssetByData(assetData.id, formData);
      setAssetData({ ...updateRes });
      setFormData({ ...updateRes });
      setSaveDraftData(true);
      setSuccess("Asset saved successfully!");
    } catch (e) {
      console.error(e);
      setError("There was a problem saving asset.");
    }
  }, [assetData, formData, type]);

  const publishAsset = useCallback(async () => {
    if (!assetData.v_published)
      if (checkValidations(formData))
        try {
          const assetData = await publishAssetById(id, formData);
          setAssetData(assetData);
          setFormData(assetData);
          setSuccess("Asset published successfully!");
          setTimeout(() => {
            navigate(`/assets/${type}`);
          }, 1000);
        } catch (err) {
          setError("Error publishing asset");
        }
  }, [assetData, formData, type]);

  useEffect(() => {
    const getData = async () => {
      const metaUIRes: any = await getMetaUiData(type);
      const metaTemplateRes: any = await getMetaTemplateData(type);
      const assetDetails: AnyObject = await getContent(id, type);
      setOriginalAssetData(assetDetails);
      if (status === "published") {
        setPublishedView(true);
        setAssetUI(metaUIRes);
        setAssetTemplate(metaTemplateRes);
        setAssetData(assetDetails);
        setFormData(assetDetails);
        return;
      }
      try {
        const assetData = await getDraftContentDataById(id);
        setAssetUI(metaUIRes);
        setAssetTemplate(metaTemplateRes);
        setAssetData(assetData);
        setFormData(assetData);
      } catch (err) {
        try {
          setOpenCreateDraftPopup(true);
          setAssetUI(metaUIRes);
          setAssetTemplate(metaTemplateRes);
        } catch (err) {
          setError("something went wrong please try again");
        }
      }
    };
    getData();
  }, [type, id]);

  useEffect(() => {
    setAccess(() => ({
      edit:
        acl.includes("ALL") ||
        acl.includes("DRAFT_ASSET.CREATE") ||
        acl.includes("DRAFT_ASSET.UPDATE"),
      delete: acl.includes("ALL") || acl.includes("ASSET.DELETE"),
      publish: acl.includes("ALL") || acl.includes("ASSET.PUBLISH"),
    }));
  }, [acl]);

  return (
    <React.Fragment>
      <div className="w-full min-h-screen flex flex-col">
        <BreadCrumb
          backRoute={`/assets/${type}`}
          title={`Edit ${capitalizeFirstLetter(type)
            .replace(/([A-Z])/g, " $1")
            .trim()}`}
          pageTitle="Asset Management"
        />
        <div className="errors success">
          {success && (
            <>
              <SnackBar message={success} onClose={() => setSuccess("")} />
            </>
          )}
          {error && (
            <>
              <SnackBar
                message={error}
                duration={8000}
                onClose={() => setError("")}
                className="bg-red-500 text-white"
              />
            </>
          )}
        </div>
        {assetTemplate.id && assetUI.id && (
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between items-center card px-4 py-2">
                <div className="flex flex-col gap-1">
                  <h2 className="text-lg font-bold">
                    Editing : {assetData.title} -{" "}
                    <span className="text-xs">(In {isoLangs[i18n].name})</span>
                  </h2>
                  {status === "draft" && (
                    <span
                      className={`text-xs font-semibold ${
                        assetData.v_published ? "text-mb-red" : "text-mb-blue"
                      }`}
                    >
                      You are editing{" "}
                      {assetData.v_published ? "published" : "draft"} version.
                    </span>
                  )}
                </div>

                <div className="flex items-center justify-end gap-4">
                  <Popup
                    arrow
                    on={"click"}
                    position={"bottom right"}
                    closeOnDocumentClick
                    trigger={
                      <button
                        title={"Change language"}
                        className="text-mb-blue"
                      >
                        <Globe2 size={15} />
                      </button>
                    }
                  >
                    <div className="w-[200px] flex flex-col gap-0 border border-gray-300 bg-white mt-2 rounded-md overflow-hidden">
                      <div className="bg-mb-blue text-white py-1 px-2 font-bold">
                        Change Language
                      </div>
                      {meta.languages.map((l: string, i: number) => (
                        <button
                          key={i}
                          onClick={() => {
                            setI18n(l);
                          }}
                          className="px-4 py-2 bg-slate-100 border-b border-b-slate-300 hover:bg-mb-blue hover:text-white text-left flex items-center gap-1"
                        >
                          {i18n === l ? <Check size={10} /> : <></>}
                          {isoLangs[l].name}
                        </button>
                      ))}
                    </div>
                  </Popup>
                  {status === "draft" && (
                    <Popup
                      arrow
                      on={"click"}
                      position={"bottom right"}
                      trigger={
                        <button title={"Manage"} className="text-mb-blue">
                          <Settings size={15} />
                        </button>
                      }
                    >
                      <div className="w-[200px] flex flex-col gap-0 border border-gray-300 bg-white mt-2 rounded-md overflow-hidden">
                        <div className="bg-mb-blue text-white py-1 px-2 font-bold">
                          Manage - {assetData.title}
                        </div>
                        <button
                          onClick={() => {
                            if (checkValidations(formData)) {
                              setSuccess(
                                "mandatory field validated successfully"
                              );
                            }
                          }}
                          className="px-4 py-2 bg-slate-100 border-b border-b-slate-300 hover:bg-mb-blue hover:text-white text-left flex items-center gap-1"
                        >
                          <Check size={15} />
                          Validate Fields
                        </button>
                        {access?.delete && (
                          <button
                            onClick={() => setOpenDeleteViewPopup(true)}
                            className="px-4 py-2 bg-slate-100 border-b border-b-slate-300 hover:bg-mb-blue hover:text-white text-left flex items-center gap-1"
                          >
                            <Trash2 size={15} />
                            Delete
                          </button>
                        )}
                        <button
                          onClick={() => {
                            i18n === meta.lang
                              ? setFormData({ ...assetData })
                              : setI18n(meta.lang);
                          }}
                          className="px-4 py-2 bg-slate-100 border-b border-b-slate-300 hover:bg-mb-blue hover:text-white text-left flex items-center gap-1"
                        >
                          <Undo size={15} />
                          Undo All Changes
                        </button>
                      </div>
                    </Popup>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap my-2">
                {status === "draft" && (
                  <button
                    onClick={() => setPublishedView(false)}
                    className={`${
                      !publishedView
                        ? "bg-white border-b-0 text-mb-blue"
                        : "bg-mb-blue/10 hover:bg-mb-blue/20"
                    } rounded-tl-md border border-slate-300 px-4 py-2 flex items-center justify-center gap-2 text-nowrap min-w-[150px] text-center transition-all duration-500`}
                  >
                    Draft view
                  </button>
                )}
                {status === "published" && (
                  <button
                    onClick={() => setPublishedView(true)}
                    className={`${
                      publishedView
                        ? "bg-white border-b-0 text-mb-blue"
                        : "bg-mb-blue/10 hover:bg-mb-blue/20"
                    } rounded-tr-md border border-slate-300 px-4 py-2 flex items-center justify-center gap-2 text-nowrap min-w-[150px] text-center transition-all duration-500
                  ${!originalAssetData.v_published ? "cursor-not-allowed" : ""}
                  `}
                  >
                    {originalAssetData?.v_status.charAt(0).toUpperCase() +
                      originalAssetData?.v_status.slice(1).toLowerCase()}{" "}
                    View{" "}
                  </button>
                )}
              </div>
              <div className="tabs flex flex-nowrap items-center justify-start gap-0 max-w-full overflow-x-auto scrollbar-hide">
                {assetUI.section.map((section: AnyObject, i: number) => {
                  return (
                    <button
                      onClick={() => {
                        changeTab();
                        setSelectedSection(i);
                      }}
                      key={i}
                      className={`${
                        selectedSection === i
                          ? "bg-white border-b-0 text-mb-blue"
                          : "bg-mb-blue/10 hover:bg-mb-blue/20"
                      } ${i == 0 ? "rounded-tl-md" : ""} ${
                        i === assetUI.section.length - 1 ? "rounded-tr-md" : ""
                      } border border-slate-300 px-4 py-2 flex items-center justify-center gap-2 text-nowrap min-w-[150px] text-center transition-all duration-500`}
                    >
                      <Icon name={section.icon} size={15} className="" />
                      {section.name}
                    </button>
                  );
                })}
              </div>
              <div className="w-full grid lg:grid-cols-12 gap-6 border border-slate-300 p-4 -mt-[2px] min-w-full">
                {assetUI.section[selectedSection].fields.map(
                  (field: string, i: number) => {
                    if (
                      assetTemplate.properties[field] &&
                      assetTemplate.properties[field].showInUi === true
                    ) {
                      return (
                        <GetComponent
                          key={`${assetUI.section[selectedSection].name}-${i}`}
                          i18n={i18n}
                          disablesFields={publishedView}
                          name={field}
                          formData={
                            publishedView ? originalAssetData : formData
                          }
                          setFormData={setFormData}
                          props={assetTemplate.properties[field]}
                          required={assetTemplate?.required}
                        />
                      );
                    }
                  }
                )}
              </div>
              <div className="mt-4 overflow-x-auto scrollbar-hide">
                <div className="flex gap-4 items-center justify-start lg:justify-end font-bold">
                  <button
                    disabled={selectedSection === 0}
                    onClick={() => {
                      setSelectedSection(selectedSection - 1);
                    }}
                    className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                  >
                    <ChevronLeft size={15} /> Prev
                  </button>
                  {!publishedView && (
                    <>
                      <button
                        onClick={saveDraft}
                        className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                      >
                        <SaveAll size={15} />
                        Save Draft
                      </button>
                      {access?.publish && saveDraftData && (
                        <button
                          onClick={publishAsset}
                          className="bg-mb-green rounded-md text-white hover:bg-mb-green/30 hover:text-mb-green px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                        >
                          <ShieldCheck size={15} />
                          {assetData.v_published ? "Save" : "Publish"}
                        </button>
                      )}
                    </>
                  )}
                  <button
                    disabled={selectedSection === assetUI.section.length - 1}
                    onClick={() => {
                      setSelectedSection(selectedSection + 1);
                    }}
                    className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                  >
                    Next <ChevronRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Create draft view pop up */}
      <Popup
        open={openCreateDraftPopup}
        onClose={handleCloseCreateDraftPopup}
        modal
        lockScroll
        closeOnDocumentClick
        overlayStyle={{ background: "rgba(0,0,0,0.6)", zIndex: 999999 }}
      >
        <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
          <button
            onClick={handleCloseCreateDraftPopup}
            className="absolute right-4 top-4"
          >
            <X size={30} />
          </button>
          <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
            Draft View Not Available
          </div>
          <div className="text-sm text-gray-900 p-4">
            There is no draft view available. Do you want to create one from the
            published version?
          </div>
          <div className="flex justify-end gap-4 p-4">
            <button
              className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1 "
              onClick={handleCreateDraft}
            >
              Create Draft
            </button>
            <button
              className="bg-gray-300 text-gray-900 rounded-md hover:bg-gray-300/30 hover:text-gray-900 px-4 py-2 flex items-center gap-1"
              onClick={handleCloseCreateDraftPopup}
            >
              Cancel
            </button>
          </div>
          <div className="flex justify-end p-4">
            {popupError && <p className="text-red-500"> {popupError}</p>}
          </div>
        </div>
      </Popup>

      {/* delete Po up */}
      <Popup
        open={openDeleteViewPopup}
        onClose={handleCloseDeletePopup}
        modal
        lockScroll
        closeOnDocumentClick
        overlayStyle={{ background: "rgba(0,0,0,0.6)", zIndex: 999999 }}
      >
        <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
          <button
            onClick={handleCloseDeletePopup}
            className="absolute right-4 top-4"
          >
            <X size={30} />
          </button>
          <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
            Delete Selected Item
          </div>
          <div className="text-sm text-gray-900 p-4">
            Are you sure you want to delete the selected item. This action
            cannot be undone.
          </div>
          <div className="flex justify-end gap-4 p-4">
            <button
              className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1 "
              onClick={handleDeleteView}
            >
              Delete
            </button>
            <button
              className="bg-gray-300 text-gray-900 rounded-md hover:bg-gray-300/30 hover:text-gray-900 px-4 py-2 flex items-center gap-1"
              onClick={handleCloseDeletePopup}
            >
              Cancel
            </button>
          </div>
          <div className="flex justify-end p-4">
            {popupError && <p className="text-red-500"> {popupError}</p>}
          </div>
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default AssetsEditPage;
