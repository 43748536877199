import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import isoLangs from "lib/languages";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import CreatableSelect from "react-select/creatable";
import { SelectPicker } from "rsuite";

interface Component {
  title: string;
  setMappingJason: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  dropDownData: any;
}

const NewBulkMulSelectOption: React.FC<Component> = ({
  dropDownData,
  title,
  setMappingJason,
}) => {
  const selectUserMeta = (state: any) => state.User.meta;
  const meta = useSelector(selectUserMeta);
  const [selectedKey, setSelectedKey] = useState<string>("");
  const [selectedSubKey, setSelectedSubKey] = useState<string>("");
  const [isMul, setIsMUl] = useState<boolean>(false);
  const [isSubMul, setIsSubMUl] = useState<boolean>(false);
  const [isArray, setIsArray] = useState<boolean>(false);
  const [subValue, setSubValue] = useState([]);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [selectedMulLangMainValue, setSelectedMulLangMainValue] =
    useState<string>("");
  const [pathString, setPathString] = useState<string>("");

  const languagesOptions = meta.languages.map((key: any) => ({
    value: key,
    label: isoLangs[key].name,
  }));

  const handleKeySelect = (value: any | null) => {
    const selectedOption: any =
      dropDownOptions.find((option: any) => option.value === value) || null;

    setSelectedKey(value ? value : "");
    if (selectedOption?.isMul) {
      setIsMUl(true);
    } else {
      setIsMUl(false);
    }
    if (selectedOption?.subValue) {
      setSubValue(selectedOption.subValue);
    } else {
      setSubValue([]);
      setSelectedSubKey("");
    }
  };
  const handleSubKeySelect = (value: any | null) => {
    const selectedOption: any =
      subValue.find((option: any) => option.value === value) || null;

    if (selectedOption?.isMul) {
      setIsSubMUl(true);
    } else {
      setIsSubMUl(false);
    }
    setSelectedSubKey(value ? value : "");
    if (selectedOption?.isArray) setIsArray(true);
  };

  const handleChangeMainLanguages = (selectedOption: any | null) => {
    setSelectedMulLangMainValue(selectedOption ? selectedOption : "");
  };

  // Update pathString whenever dependencies change
  useEffect(() => {
    let path = selectedKey ? `${selectedKey}` : "";

    if (selectedKey && isArray) {
      path += `;`;
    }

    if (selectedSubKey) {
      path += `.${selectedSubKey}`;
    }

    if (
      (isMul || isSubMul) &&
      selectedMulLangMainValue &&
      selectedMulLangMainValue !== "en"
    ) {
      path = `i18n.${selectedMulLangMainValue}.${selectedKey}${
        selectedSubKey ? "." + selectedSubKey : ""
      }`;
    }

    setMappingJason((preVal) => ({
      ...preVal,
      [title.replace("\r", "")]: path,
    }));

    setPathString(path);
  }, [selectedKey, selectedMulLangMainValue, selectedSubKey]);

  useEffect(() => {
    const keysData: any = Object.entries(dropDownData).map(
      ([key, value]: any) => ({
        label: capitalizeFirstLetter(key),
        value: key,
        isMul: value?.isMultiLingual ?? false,
        isObj: value?.type === "object" ? true : false,
        subValue:
          value?.type === "object"
            ? Object.entries(value?.properties)?.map(([key, value]: any) => ({
                label: capitalizeFirstLetter(key),
                value: key,
                isMul: value?.isMultiLingual ?? false,
              }))
            : [],
      })
    );
    setDropDownOptions(keysData);
  }, [dropDownData]);

  return (
    <div className="grid grid-cols-2 p-2">
      <p className="truncate pt-2">{title} : </p>
      <div className="flex flex-col gap-3">
        <SelectPicker
          className="border !border-mb-blue !text-black rounded-md w-full"
          id="choices-single-no-search"
          name="choices-single-no-search"
          data={dropDownOptions}
          onChange={handleKeySelect}
          // value={
          //   dropDownOptions.find(
          //     (option: any) => option.value === selectedKey
          //   ) || null
          // }
          value={selectedKey}
        />
        {selectedKey && isMul && (
          <SelectPicker
            className="border !border-mb-blue !text-black rounded-md w-full"
            id="choices-single-no-search"
            name="choices-single-no-search"
            placeholder="language"
            data={languagesOptions}
            onChange={handleChangeMainLanguages}
            value={selectedMulLangMainValue}
          />
        )}
        {subValue?.length > 0 && (
          <SelectPicker
            className="border !border-mb-blue !text-black rounded-md w-full"
            id="choices-single-no-search"
            name="choices-single-no-search"
            data={subValue}
            onChange={handleSubKeySelect}
            value={selectedSubKey}
          />
        )}
        {selectedSubKey && isSubMul && (
          <SelectPicker
            className="border !border-mb-blue !text-black rounded-md w-full"
            id="choices-single-no-search"
            name="choices-single-no-search"
            placeholder="language"
            data={languagesOptions}
            onChange={handleChangeMainLanguages}
            // value={
            //   languagesOptions.find(
            //     (option: any) => option.value === selectedMulLangMainValue
            //   ) || null
            // }
            value={selectedMulLangMainValue}
          />
        )}
      </div>
    </div>
  );
};

export default NewBulkMulSelectOption;
