import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { createSelector } from "@reduxjs/toolkit";
import { slugify } from "helpers/utility";
import isoLangs from "lib/languages";
import { Info, X } from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";

interface InputProps {
  name: string;
  props: any;
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  i18n: string;
  disablesFields: boolean;
  required?: string[];
}

const Editor: React.FC<InputProps> = ({
  name,
  props,
  formData = {},
  setFormData,
  i18n,
  required,
  disablesFields,
}) => {
  const editorRef = useRef<HTMLDivElement | null>(null); // Type the ref

  const [showHelper, setShowHelper] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [value, setValue] = useState("");

  const isRequired = required
    ? required?.length > 1
      ? required.includes(name)
      : false
    : false;

  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);
  useEffect(() => {
    if (i18n !== meta.lang) {
      if (!props.isMultiLingual) {
        const newValue =
          props.autoGenerate && !formData[name]
            ? slugify(formData[props.autoGenerateBy])
            : formData[name] || "";
        setValue(newValue);
        setIsDisabled(true);
      } else {
        // For multilingual case
        const i18nData = formData.i18n?.[i18n]?.[name] || "";
        if (!formData.i18n || !formData.i18n[i18n]) {
          setFormData({
            ...formData,
            i18n: {
              ...formData.i18n,
              [i18n]: { ...formData.i18n?.[i18n], [name]: "" },
            },
          });
          setValue("");
        } else {
          setValue(i18nData);
        }
      }
    } else {
      // When i18n === meta.lang
      const newValue =
        props.autoGenerate && !formData[name]
          ? slugify(formData[props.autoGenerateBy])
          : formData[name] || "";
      setValue(newValue);
      setIsDisabled(false);
    }
  }, [i18n]);

  const updateFormData = useCallback(() => {
    if (props.isMultiLingual && i18n !== meta.lang) {
      let newFormData = formData;
      newFormData.i18n[i18n][name] = value;
      setFormData({ ...newFormData });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }, [value]);

  useEffect(() => {
    // Adjusts the height of the CKEditor by manipulating its internal class styles dynamically.
    if (editorRef.current) {
      const editableElement = editorRef.current.querySelector(
        ".ck.ck-editor__main > .ck-editor__editable"
      ) as HTMLElement | null; // Assert type as HTMLElement

      if (editableElement) {
        editableElement.style.height = "120px"; // Safely access style
      }
    }
  }, []);
  return (
    <div
      className={`flex flex-col gap-1 ${
        props.cols ? `lg:col-span-${props.cols}` : "lg:col-span-12"
      }`}
    >
      <div className="flex gap-1 items-center">
        <label className="text-sm">
          {props.name}{" "}
          {isRequired && <span className="text-red-600 text-base">*</span>}
        </label>
        {props.longDesc && (
          <>
            <button
              className="text-mb-blue"
              onClick={() => setShowHelper(true)}
            >
              <Info size={15} />
            </button>
            <Popup
              open={showHelper}
              onClose={() => setShowHelper(false)}
              modal
              lockScroll
              closeOnDocumentClick
              contentStyle={{
                top: 0,
                right: 0,
                height: "100vh",
                position: "fixed",
              }}
              overlayStyle={{ background: "rgba(0,0,0,0.6)", zIndex: 999999 }}
            >
              <div className="h-screen bg-white w-[20rem] relative">
                <button
                  onClick={() => setShowHelper(false)}
                  className="absolute right-4 top-4"
                >
                  <X size={30} />
                </button>
                <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
                  {props.longDesc.title}
                </div>
                <div
                  className="text-sm text-gray-900 p-4 flex flex-col gap-4"
                  dangerouslySetInnerHTML={{ __html: props.longDesc.body }}
                ></div>
              </div>
            </Popup>
          </>
        )}
      </div>
      <span className="text-xs">{props.shortDesc}</span>
      {meta.languages.map(
        (lang: any) =>
          i18n === lang && (
            <div ref={editorRef}>
              <CKEditor
                onChange={(e: any, editor: any) => {
                  const data = editor.getData();
                  setValue(data);
                }}
                disabled={disablesFields || isDisabled}
                onBlur={updateFormData}
                editor={ClassicEditor}
                data={value || ""}
              />
            </div>
          )
      )}
      {i18n !== meta.lang && props.isMultiLingual && (
        <div className="text-xs">
          <span className="font-bold">{isoLangs[meta.lang].name} Value: </span>
          {formData[name]}
        </div>
      )}
    </div>
  );
};

export default Editor;
