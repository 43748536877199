import { Image, MonitorPlay } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
interface Component {
  title: string;
  image: string;
  id: string;
  type: string;
  asset: any;
  editAccess: boolean;
}

const MbTitle: React.FC<Component> = ({
  title,
  image,
  id,
  type,
  asset,
  editAccess,
}) => {
  return (
    <Link
      className="transition-all flex items-center gap-2 duration-150 ease-linear text-mb-blue hover:text-mb-blue/60"
      to={
        !editAccess
          ? "#"
          : asset.v_status.toUpperCase() === "DRAFT"
          ? `/assets/${type}/draft/${asset.id}`
          : `/assets/${type}/published/${asset.id}`
      }
    >
      {image !== "" ? (
        <img
          alt={"asset icon"}
          src={image}
          width={30}
          height={30}
          className="rounded-full object-cover border-slate-300 border w-[30px] h-[30px]"
        />
      ) : (
        <span className="p-1 rounded-full border border-slate-300">
          <Image size={30} className="text-slate-300" />{" "}
        </span>
      )}
      {title}
    </Link>
  );
};

export default MbTitle;
