import BreadCrumb from "Common/BreadCrumb";
import Alert from "Common/Components/Alert";
import MbLoader from "Common/Components/Icons/MbLoader";
import { getRoles, updateRoles } from "helpers/mb-api_helper";
import { ShieldCheck } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const unwantedRowsNames = [
  "META",
  "TERMS",
  "AUDIT",
  "AMI",
  "STORAGE",
  "MAPPING",
];
const defaultActions = [
  "META.READ",
  "USER.AUTH",
  "USER.READ",
  "STORAGE.WRITE",
  "TERMS.READ",
];
const headers = ["read", "draftWrite", "publishWrite", "delete", "bulkUpload"];
const initialData: any = {
  asset: {
    read: {
      dependencies: [
        "asset-delete",
        "asset-publishWrite",
        "asset-draftWrite",
        "asset-bulkUpload",
        "platform-read",
        "platform-draftWrite",
        "platform-publishWrite",
        "platform-delete",
        "pages-read",
        "pages-draftWrite",
        "pages-publishWrite",
        "pages-delete",
        "menus-read",
        "menus-draftWrite",
        "menus-publishWrite",
        "menus-delete",
      ],
      values: ["ASSET.LIST", "ASSET.READ", "DRAFT_ASSET.READ"],
    },
    draftWrite: {
      dependencies: ["asset-delete", "asset-publishWrite", "asset-bulkUpload"],
      values: ["DRAFT_ASSET.READ", "DRAFT_ASSET.CREATE", "DRAFT_ASSET.UPDATE"],
    },
    publishWrite: {
      dependencies: ["asset-delete", "asset-bulkUpload"],
      values: ["ASSET.PUBLISH", "ASSET.UNPUBLISH"],
    },
    delete: {
      dependencies: ["asset-bulkUpload"],
      values: ["ASSET.DELETE"],
    },
    bulkUpload: {
      dependencies: [],
      values: [
        "JOB.CREATE",
        "JOB.READ",
        "JOB.LIST",
        "MAPPING.CREATE",
        "MAPPING.READ",
        "MAPPING.LIST",
      ],
    },
  },
  platform: {
    read: {
      dependencies: [
        "platform-draftWrite",
        "platform-publishWrite",
        "platform-delete",
        "pages-read",
        "pages-draftWrite",
        "pages-publishWrite",
        "pages-delete",
        "menus-read",
        "menus-draftWrite",
        "menus-publishWrite",
        "menus-delete",
      ],
      values: [
        "PLATFORM.LIST",
        "PLATFORM.READ",
        "DRAFT_PLATFORM.LIST",
        "DRAFT_PLATFORM.READ",
      ],
    },
    draftWrite: {
      dependencies: ["platform-delete", "platform-publishWrite"],
      values: [
        "DRAFT_PLATFORM.LIST",
        "DRAFT_PLATFORM.READ",
        "DRAFT_PLATFORM.CREATE",
        "DRAFT_PLATFORM.UPDATE",
      ],
    },
    publishWrite: {
      dependencies: ["platform-delete"],
      values: [
        "PLATFORM.LIST",
        "PLATFORM.READ",
        "PLATFORM.CREATE",
        "PLATFORM.UPDATE",
        "PLATFORM.DELETE",
        "PLATFORM.PUBLISH",
        "DRAFT_PLATFORM.LIST",
        "DRAFT_PLATFORM.READ",
        "DRAFT_PLATFORM.CREATE",
        "DRAFT_PLATFORM.UPDATE",
      ],
    },
    delete: {
      dependencies: [],
      values: ["PLATFORM.DELETE", "PLATFORM.LIST", "PLATFORM.READ"],
    },
  },
  pages: {
    read: {
      dependencies: [
        "pages-delete",
        "pages-publishWrite",
        "pages-draftWrite",
        "menus-read",
        "menus-delete",
        "menus-publishWrite",
        "menus-draftWrite",
      ],
      values: [
        "VIEW.LIST",
        "VIEW.READ",
        "DRAFT_VIEW.LIST",
        "DRAFT_VIEW.READ",
        "RAIL_INSTANCE.READ",
        "RAIL_INSTANCE.LIST",
      ],
    },
    draftWrite: {
      dependencies: ["pages-delete", "pages-publishWrite"],
      values: [
        "DRAFT_VIEW.LIST",
        "DRAFT_VIEW.READ",
        "DRAFT_VIEW.CREATE",
        "DRAFT_VIEW.UPDATE",
        "RAIL_INSTANCE.CREATE",
        "RAIL_INSTANCE.UPDATE",
        "RAIL_INSTANCE.READ",
        "RAIL_INSTANCE.LIST",
        "DRAFT_RAIL_INSTANCE.READ",
        "DRAFT_RAIL_INSTANCE.LIST",
      ],
    },
    publishWrite: {
      dependencies: ["pages-delete"],
      values: [
        "VIEW.LIST",
        "VIEW.READ",
        "VIEW.CREATE",
        "VIEW.UPDATE",
        "VIEW.DELETE",
        "VIEW.PUBLISH",

        "RAIL_INSTANCE.CREATE",
        "RAIL_INSTANCE.UPDATE",
        "RAIL_INSTANCE.READ",
        "RAIL_INSTANCE.LIST",
        "DRAFT_RAIL_INSTANCE.READ",
        "DRAFT_RAIL_INSTANCE.LIST",
      ],
    },
    delete: {
      dependencies: [],
      values: [
        "VIEW.DELETE",
        "VIEW.LIST",
        "VIEW.READ",
        "RAIL_INSTANCE.READ",
        "RAIL_INSTANCE.LIST",
      ],
    },
  },
  menus: {
    read: {
      dependencies: ["menus-delete", "menus-publishWrite", "menus-draftWrite"],
      values: ["MENU.LIST", "MENU.READ"],
    },
    draftWrite: {
      dependencies: ["menus-delete", "menus-publishWrite"],
      values: [
        "DRAFT_MENU.LIST",
        "DRAFT_MENU.READ",
        "DRAFT_MENU.CREATE",
        "DRAFT_MENU.UPDATE",
        "MENU.LIST",
        "MENU.READ",
      ],
    },
    publishWrite: {
      dependencies: ["menus-delete"],
      values: [
        "MENU.LIST",
        "MENU.READ",
        "MENU.CREATE",
        "MENU.UPDATE",
        "MENU.DELETE",
        "DRAFT_MENU.LIST",
        "DRAFT_MENU.READ",
        "DRAFT_MENU.CREATE",
        "DRAFT_MENU.UPDATE",
        "MENU.PUBLISH",
      ],
    },
    delete: {
      dependencies: [],
      values: [
        "MENU.LIST",
        "MENU.READ",
        "DRAFT_MENU.LIST",
        "DRAFT_MENU.READ",
        "MENU.DELETE",
      ],
    },
  },
  businessRules: {
    read: {
      dependencies: ["businessRules-delete", "businessRules-publishWrite"],
      values: ["RULE.LIST", "RULE.READ"],
    },
    publishWrite: {
      dependencies: ["businessRules-delete"],
      values: ["RULE.LIST", "RULE.READ", "RULE.CREATE", "RULE.UPDATE"],
    },
    delete: {
      dependencies: [],
      values: ["RULE.LIST", "RULE.READ", "RULE.DELETE"],
    },
  },
  adRules: {
    read: {
      dependencies: ["adRules-delete", "adRules-publishWrite"],
      values: ["ADS_RULE.LIST", "ADS_RULE.READ"],
    },
    publishWrite: {
      dependencies: ["adRules-delete"],
      values: [
        "ADS_RULE.LIST",
        "ADS_RULE.READ",
        "ADS_RULE.CREATE",
        "ADS_RULE.UPDATE",
      ],
    },
    delete: {
      dependencies: [],
      values: ["ADS_RULE.LIST", "ADS_RULE.READ", "ADS_RULE.DELETE"],
    },
  },
  adminUser: {
    read: {
      dependencies: ["adminUser-publishWrite"],
      values: ["ADMIN.USER.LIST", "ADMIN.USER.READ"],
    },
    publishWrite: {
      dependencies: [],
      values: [
        "ADMIN.USER.CREATE",
        "ADMIN.USER.UPDATE",
        "ADMIN.USER.LIST",
        "ADMIN.USER.READ",
      ],
    },
  },
  adminRoles: {
    read: {
      dependencies: ["adminRoles-publishWrite","adminUser-publishWrite","adminUser-read"],
      values: ["ADMIN.ROLE.LIST", "ADMIN.ROLE.READ"],
    },
    publishWrite: {
      dependencies: [],
      values: [
        "ADMIN.ROLE.CREATE",
        "ADMIN.ROLE.UPDATE",
        "ADMIN.ROLE.LIST",
        "ADMIN.ROLE.READ",
      ],
    },
  },
  audit: {
    read: {
      dependencies: [],
      values: ["AUDIT.LIST"],
    },
  },
};

const RoleEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const selectUserMeta = (state: any) => state.User.meta;
  const meta = useSelector(selectUserMeta);

  const [allPermissions, setAllPermissions] = useState(false);
  const [selectedColData, setSelectedColData] = useState<any>([]);
  const [rowNames, setRowNames] = useState<string[]>([]);
  const [columnName, setColumnName] = useState<string[]>([]);
  const [roleData, setRoleData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleToggleRoleSinglePermission = (
    checked: any,
    row: string,
    name: string
  ) => {
    if (checked) {
      const dependentValues: string[] = [];
      Object.keys(initialData).map((keyName) =>
        headers?.forEach((header) => {
          if (
            initialData[keyName][header]?.dependencies?.includes(
              `${row}-${name}`
            )
          ) {
            dependentValues.push(`${keyName}-${header}`);
          }
        })
      );

      setSelectedColData((preVal: any) => [
        ...preVal,
        ...dependentValues,
        `${row}-${name}`,
      ]);
    } else {
      setSelectedColData((prevRoleData: any) =>
        prevRoleData?.filter((data: string) => data !== `${row}-${name}`)
      );
    }
  };

  // const toggleAllRolePermission = (isChecked: boolean, value: string) => {
  //   const updatedActions = isChecked
  //     ? addPermissions(value)
  //     : removePermissions(value);

  //   setRoleData((prevRoleData: any) => ({
  //     ...prevRoleData,
  //     acl: { actions: updatedActions },
  //   }));
  // };

  // const addPermissions = (value: string) => {
  //   const allReadArray = rowNames.map((name) => `${name}.${value}`);
  //   return [...new Set([...(roleData?.acl?.actions || []), ...allReadArray])];
  // };

  // const removePermissions = (value: string) => {
  //   return (
  //     roleData?.acl?.actions.filter(
  //       (item: string) => !item.endsWith(`.${value}`)
  //     ) || []
  //   );
  // };

  const toggleAllRolePermission = (isChecked: boolean) => {
    setAllPermissions(isChecked);
    if (!isChecked) {
      setSelectedColData([]);
      return;
    }
    setSelectedColData([]);
  };

  const handleSeparatedRowAndColumn = () => {
    const beforeDot: string[] = [];
    const afterDot: string[] = [];
    const actions = meta?.acl.actions;

    actions.forEach((action: any) => {
      const lastDotIndex = action.lastIndexOf("."); // Find the last occurrence of a dot
      if (lastDotIndex !== -1) {
        beforeDot.push(action.slice(0, lastDotIndex)); // Add part before the last dot
        afterDot.push(action.slice(lastDotIndex + 1)); // Add part after the last dot
      }
    });
    const uniqueBeforeDotValues: string[] = [...new Set(beforeDot)];
    setRowNames(() =>
      uniqueBeforeDotValues.filter(
        (value: string) => !unwantedRowsNames.includes(value)
      )
    );
    setColumnName(() => [...new Set(afterDot)]);
  };

  const handleSubmit = async () => {
    try {
      const selectedColsValues = selectedColData?.map((value: string) => {
        const [row, name] = value.split("-");

        return initialData[row][name]?.values;
      });
      const uniqueItems = allPermissions
        ? ["ALL"]
        : [...new Set([...selectedColsValues.flat(), ...defaultActions])];

      await updateRoles(
        {
          name: roleData?.name,
          acl: {
            ...roleData?.acl,
            actions: uniqueItems,
            selectedKeys: selectedColData,
          },
        },
        id
      );
      setSuccess("Update data successfully ");
      setTimeout(() => {
        setSuccess("");
      }, 5000);
      navigate("/user/roles");
    } catch (error) {
      setError("Unable to save role data");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        const roles: any = await getRoles(id);
        setRoleData(roles);
        setSelectedColData(roles?.acl?.selectedKeys ?? []);
        const allIncludes = roles?.acl?.actions?.includes("ALL");
        setAllPermissions(allIncludes);
        setLoading(false);
      } catch (error) {
        setError("Unable to get roles list");
        setTimeout(() => {
          setError("");
        }, 5000);
        setLoading(false);
      }
    };
    handleSeparatedRowAndColumn();
    fetchRoleData();
  }, [id]);
  return (
    <React.Fragment>
      <BreadCrumb
        backRoute={`/user/roles`}
        title="Edit Role"
        pageTitle="Role"
      />

      {loading ? (
        <MbLoader />
      ) : (
        <div className=" p-4 border rounded-lg shadow-lg">
          <div className=" p-4 border border-slate-300">
            <label className="inline-block mb-2 text-base font-medium">
              Name
            </label>
            <input
              type="text"
              className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
              value={roleData?.name || ""}
              onChange={(e) => {
                setRoleData((prevRoleData: any) => ({
                  ...prevRoleData,
                  name: e.target.value,
                }));
              }}
            />
            <div className="overflow-x-auto scrollbar-hide border border-slate-200 rounded-md mt-6 p-4 shadow-lg bg-white">
              <div className="flex gap-4">
                <input
                  className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-blue-600 checked:border-blue-600"
                  type="checkbox"
                  value="true"
                  checked={allPermissions}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    toggleAllRolePermission(isChecked);
                  }}
                />
                <p>All Permissions</p>
              </div>
              <table className="w-full">
                <thead className="ltr:text-left rtl:text-right ">
                  <tr>
                    <th className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                      Name
                    </th>
                    {headers?.map((name: string) => (
                      <th
                        key={name}
                        className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                      >
                        <div className="flex item-center gap-2 ">
                          {/* <input
                            className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-blue-600 checked:border-blue-600"
                            type="checkbox"
                            value="true"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              toggleAllRolePermission(isChecked, name);
                            }}
                          /> */}
                          <p className="capitalize">
                            {name.replace(/([A-Z])/g, " $1")}
                          </p>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(initialData).map((row) => (
                    <tr className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600">
                      <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                        <p className="capitalize">
                          {row.replace(/([A-Z])/g, " $1")}
                        </p>
                      </td>
                      {headers?.map((header: string) => (
                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                          {initialData[row][header] ? (
                            <input
                              className={`peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-blue-600 checked:border-blue-600 ${
                                allPermissions ||
                                initialData[row][header]?.dependencies?.some(
                                  (item: any) => selectedColData?.includes(item)
                                )
                                  ? "checked:bg-gray-300 checked:border-gray-300"
                                  : ""
                              }`}
                              type="checkbox"
                              disabled={
                                allPermissions ||
                                initialData[row][header]?.dependencies?.some(
                                  (item: any) => selectedColData?.includes(item)
                                )
                              }
                              checked={
                                allPermissions ||
                                selectedColData?.includes(`${row}-${header}`)
                              }
                              value="true"
                              onChange={(e) =>
                                handleToggleRoleSinglePermission(
                                  e.target.checked,
                                  row,
                                  header
                                )
                              }
                            />
                          ) : (
                            <div className="px-2">-</div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full flex justify-end p-5">
            <button
              type="button"
              className={`bg-mb-green rounded-md text-white hover:bg-mb-green/30 hover:text-mb-green px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white ${
                selectedColData?.length > 0 ? "" : "bg-gray-400"
              }`}
              onClick={handleSubmit}
              disabled={
                !allPermissions && (loading || !selectedColData?.length)
              }
            >
              <ShieldCheck size={15} /> Save
            </button>
          </div>
        </div>
      )}

      <div>
        {success && (
          <Alert className="relative px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
            <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-green-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
            <Alert.Bold>Success! </Alert.Bold>
            {success}
          </Alert>
        )}
        {error && (
          <Alert className="relative px-4 py-3 text-sm text-orange-500 border border-transparent rounded-md bg-orange-50 dark:bg-orange-400/20">
            <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-orange-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
            <Alert.Bold>Error! </Alert.Bold>
            {error}
          </Alert>
        )}
      </div>
    </React.Fragment>
  );
};

export default RoleEdit;
