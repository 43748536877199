import BreadCrumb from "Common/BreadCrumb";
import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import { getAdRulesById, updateAdRule } from "helpers/mbentcms-api_helper";
import SnackBar from "Layout/SnackBar";
import countries from "lib/countries";
import isoLangs from "lib/languages";
import { LucideProps, SaveAll } from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import React, { Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SelectPicker, TagInput, TagPicker, Toggle } from "rsuite";
interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}
///Show lucide Icon  Dynamically
const fallback = <div style={{ background: "#ddd", width: 24, height: 24 }} />;
interface IconProps extends Omit<LucideProps, "ref"> {
  name: keyof typeof dynamicIconImports;
}
const Icon = ({ name, ...props }: IconProps) => {
  const LucideIcon = React.lazy(dynamicIconImports[name]);
  return (
    <Suspense fallback={fallback}>
      <LucideIcon {...props} />
    </Suspense>
  );
};

const sections = [
  {
    name: "Rule Details",
    icon: "crown",
    fields: [
      {
        name: "ruleID",
        type: "string",
        title: "Rule ID",
        showInUI: false,
        uiElement: "tagBox",
      },
      {
        name: "title",
        type: "string",
        title: "Title",
        showInUI: true,
        uiElement: "textBox",
      },
      {
        name: "details",
        type: "string",
        title: "Ad URL",
        showInUI: true,
        uiElement: "textBox",
      },
      {
        name: "priority",
        type: "number",
        title: "Rule Priority",
        showInUI: true,
        uiElement: "numberBox",
      },
      {
        name: "pre_roll",
        title: "Pre Roll",
        type: "boolean",
        showInUI: true,
        labels: "Yes|No",
        uiElement: "toggleBox",
        col: "12",
      },
      {
        name: "pre_roll_ad_count",
        type: "string",
        title: "Pre Roll Ad Count",
        showInUI: true,
        uiElement: "numberBox",
        dependencyField: "pre_roll",
        dependencyValue: 1,
      },
      {
        name: "pre_roll_url",
        type: "string",
        title: "Pre Roll URL",
        showInUI: true,
        uiElement: "textBox",
        dependencyField: "pre_roll",
        dependencyValue: 1,
      },

      {
        name: "mid_roll",
        title: "Mid Roll",
        type: "boolean",
        showInUI: true,
        labels: "Yes|No",
        uiElement: "toggleBox",
        col: "12",
      },
      {
        name: "mid_roll_ad_count",
        type: "string",
        title: "Mid Roll Ad Count",
        showInUI: true,
        uiElement: "numberBox",
        dependencyField: "mid_roll",
        dependencyValue: 1,
      },
      {
        name: "mid_roll_interval",
        type: "string",
        title: "Mid Roll interval",
        showInUI: true,
        uiElement: "textBox",
        dependencyField: "mid_roll",
        dependencyValue: 1,
      },
      {
        name: "mid_roll_url",
        type: "string",
        title: "Mid Roll URL",
        showInUI: true,
        uiElement: "textBox",
        dependencyField: "mid_roll",
        dependencyValue: 1,
      },
      {
        name: "post_roll",
        title: "Post Roll",
        type: "boolean",
        showInUI: true,
        labels: "Yes|No",
        uiElement: "toggleBox",
        col: "12",
      },
      {
        name: "post_roll_ad_count",
        type: "string",
        title: "Post Roll Ad Count",
        showInUI: true,
        uiElement: "numberBox",
        dependencyField: "post_roll",
        dependencyValue: 1,
      },
      {
        name: "post_roll_url",
        type: "string",
        title: "post Roll interval",
        showInUI: true,
        uiElement: "textBox",
        dependencyField: "post_roll",
        dependencyValue: 1,
      },
    ],
  },
  {
    name: "Content Parameters",
    icon: "film",
    fields: [
      { name: "a1", title: "Asset ID", showInUI: true, uiElement: "tagBox" },
      {
        name: "a2",
        title: "Asset Type",
        showInUI: true,
        uiElement: "multiSelectBox",
        validValues: [
          "movies",
          "shows",
          "seasons",
          "episodes",
          "channels",
          "videos",
          "sports",
        ],
      },
      {
        name: "a3",
        title: "Category",
        showInUI: true,
        uiElement: "multiSelectBox",
        validValues: [
          "Movies",
          "Short Films",
          "Action",
          "Adventure",
          "Animated",
          "Biography",
          "Comedy",
          "Crime",
          "Dance",
          "Disaster",
          "Documentary",
          "Drama",
          "Erotic",
          "Family",
          "Fantasy",
          "Found Footage",
          "Historical",
          "Horror",
          "Independent",
          "Legal",
          "Live Action",
          "Martial Arts",
          "Musical",
          "Mystery",
          "Noir",
          "Performance",
          "Political",
          "Romance",
          "Satire",
          "Science Fiction",
          "Short",
          "Silent",
          "Slasher",
          "Sports",
          "Spy",
          "Superhero",
          "Supernatural",
          "Suspense",
          "Teen",
          "Thriller",
          "War",
          "Western",
        ],
      },
      {
        name: "a4",
        title: "Genre",
        showInUI: true,
        uiElement: "multiSelectBox",
        validValues: [
          "Movies",
          "Short Films",
          "Action",
          "Adventure",
          "Animated",
          "Biography",
          "Comedy",
          "Crime",
          "Dance",
          "Disaster",
          "Documentary",
          "Drama",
          "Erotic",
          "Family",
          "Fantasy",
          "Found Footage",
          "Historical",
          "Horror",
          "Independent",
          "Legal",
          "Live Action",
          "Martial Arts",
          "Musical",
          "Mystery",
          "Noir",
          "Performance",
          "Political",
          "Romance",
          "Satire",
          "Science Fiction",
          "Short",
          "Silent",
          "Slasher",
          "Sports",
          "Spy",
          "Superhero",
          "Supernatural",
          "Suspense",
          "Teen",
          "Thriller",
          "War",
          "Western",
        ],
      },
      {
        name: "a5",
        title: "Language",
        showInUI: true,
        uiElement: "multiSelectBox",
        validValuesStatic: "languages",
      },
      { name: "a6", title: "Tags", showInUI: true, uiElement: "tagBox" },
      {
        name: "a7",
        title: "Age Rating",
        showInUI: true,
        uiElement: "multiSelectBox",
        validValues: ["U", "UA", "A", "R"],
      },
      { name: "a8", title: "Partner", showInUI: true, uiElement: "tagBox" },
      {
        name: "a9",
        title: "Pricing Rule",
        showInUI: true,
        uiElement: "tagBox",
      },
      {
        name: "a10",
        title: "Sub Type",
        showInUI: true,
        uiElement: "textBox",
      },
      {
        name: "a11",
        title: "Ad group",
        showInUI: true,
        uiElement: "tagBox",
      },
    ],
  },
  {
    name: "User Parameters",
    icon: "user-cog",
    fields: [
      {
        name: "u1",
        title: "Geo",
        showInUI: true,
        uiElement: "multiSelectBox",
        validValuesStatic: "countries",
      },
      { name: "u2", title: "plans/pack", showInUI: true, uiElement: "tagBox" },
      { name: "u3", title: "User Type", showInUI: true, uiElement: "tagBox" },
      {
        name: "u4",
        title: "User Profile",
        showInUI: true,
        uiElement: "tagBox",
      },
      {
        name: "u5",
        title: "Device Profile",
        showInUI: true,
        uiElement: "tagBox",
      },
    ],
  },
];
const EditAdRule = () => {
  const { id } = useParams<{ id: string }>(); // Destructure `type` from useParams
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState(0);
  const [formData, setFormData] = useState<AnyObject>({});
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const saveDraft = async () => {
    try {
      await updateAdRule(
        {
          ...formData,
          title: formData.title,
        },
        id
      );
      setSuccess("Rule updated successfully!");
      setTimeout(() => {
        navigate("/ad-rules");
      }, 1000);
    } catch (e) {
      console.error(e);
      setError("Something went wrong to save Rule");
    }
  };

  useEffect(() => {
    const getDataById = async () => {
      try {
        const response: any = await getAdRulesById(id);
        setFormData(response);
      } catch (err) {
        console.error("Error fetching data", err);
        return;
      }
    };
    getDataById();
  }, [id]);

  return (
    <React.Fragment>
      <div className="w-full min-h-screen">
        {success && (
          <SnackBar message={success} onClose={() => setSuccess("")} />
        )}
        {error && (
          <SnackBar
            className="bg-red-400"
            message={error}
            onClose={() => setError("")}
          />
        )}
        <BreadCrumb
          title="Edit Ad Rules"
          pageTitle="Ad Rule"
          backRoute="/Ad-rules"
        />
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between items-center card px-4 py-2">
              <div className="flex flex-col gap-1">
                <h2 className="text-lg font-bold">
                  Editing : {formData.title}
                </h2>
              </div>
            </div>
            <div className="tabs flex flex-nowrap items-center justify-start gap-0 max-w-full overflow-x-auto scrollbar-hide">
              {sections.map((section: any, i: number) => {
                return (
                  <button
                    onClick={() => setSelectedSection(i)}
                    key={i}
                    className={`${
                      selectedSection === i
                        ? "bg-white border-b-0 text-mb-blue"
                        : "bg-mb-blue/10 hover:bg-mb-blue/20"
                    } ${i === 0 ? "rounded-tl-md" : ""} ${
                      i === sections.length - 1 ? "rounded-tr-md" : ""
                    } border border-slate-300 px-4 py-2 flex items-center justify-center gap-2 text-nowrap min-w-[150px] text-center`}
                  >
                    <Icon name={section.icon} size={15} className="" />
                    {section.name}
                  </button>
                );
              })}
            </div>

            <div className="w-full grid grid-cols-1 lg:grid-cols-12 gap-6 border border-slate-300 p-4 -mt-[2px]">
              {sections[selectedSection].fields.map(
                (field: AnyObject) =>
                  field?.showInUI &&
                  (field?.dependencyField
                    ? formData[field.dependencyField] === field.dependencyValue
                    : true) && (
                    <div
                      className={`flex flex-col gap-2 lg:col-span-${
                        field?.col || "6"
                      }`}
                    >
                      <label className="text-sm">{field.title}</label>
                      {field?.uiElement === "textBox" && (
                        <input
                          type="text"
                          className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [field.name]: e.target.value,
                            })
                          }
                          value={
                            formData[field.name] ? formData[field.name] : []
                          }
                        />
                      )}
                      {field?.uiElement === "numberBox" && (
                        <input
                          type="number"
                          className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                          onChange={(e) => {
                            if (+e.target.value > 0)
                              setFormData({
                                ...formData,
                                [field.name]: e.target.value,
                              });
                          }}
                          value={
                            formData[field.name] ? formData[field.name] : []
                          }
                        />
                      )}
                      {field?.uiElement === "tagBox" && (
                        <TagInput
                          block
                          className="border !border-mb-blue !disabled:border-none rounded-md w-full"
                          onChange={(v, e) =>
                            setFormData({ ...formData, [field.name]: v })
                          }
                          value={
                            formData[field.name] ? formData[field.name] : []
                          }
                        ></TagInput>
                      )}

                      {field?.uiElement === "toggleBox" && (
                        <Toggle
                          // checked={formData[field.name]}
                          checked={formData[field.name]}
                          //defaultValue={field.labels.split("|")[1]}
                          value={formData[field.name]}
                          checkedChildren={field.labels.split("|")[0]}
                          unCheckedChildren={field.labels.split("|")[1]}
                          onChange={(v) =>
                            setFormData({
                              ...formData,
                              [field.name]: v ? 1 : 0,
                            })
                          }
                        />
                      )}
                      {field?.uiElement === "checkBox" && (
                        <Toggle
                          // checked={formData[field.name]}
                          checked={
                            formData[field.name]?.toLowerCase() ===
                            field.labels.split("|")[0]?.toLowerCase()
                          }
                          //defaultValue={field.labels.split("|")[1]}
                          value={formData[field.name]}
                          checkedChildren={field.labels.split("|")[0]}
                          unCheckedChildren={field.labels.split("|")[1]}
                          onChange={(v) =>
                            setFormData({
                              ...formData,
                              [field.name]: v
                                ? field.labels.split("|")[0]
                                : field.labels.split("|")[1],
                            })
                          }
                        />
                      )}
                      {field?.uiElement === "multiSelectBox" && (
                        <TagPicker
                          data={
                            field.validValues
                              ? field.validValues.map((v: string) => ({
                                  value: v,
                                  label: capitalizeFirstLetter(v),
                                }))
                              : field.validValuesStatic &&
                                field.validValuesStatic === "languages"
                              ? Object.keys(isoLangs).map((k: string) => ({
                                  value: k,
                                  label: isoLangs[k].name,
                                }))
                              : field.validValuesStatic &&
                                field.validValuesStatic === "countries"
                              ? Object.keys(countries).map((k: string) => ({
                                  value: k,
                                  label: countries[k],
                                }))
                              : []
                          }
                          className="border !border-mb-blue rounded-md w-full"
                          onChange={(v, e) => {
                            if (v.length === 0) {
                              setFormData({
                                ...formData,
                                [field.name]: ["ANY"],
                              });
                            } else {
                              setFormData({
                                ...formData,
                                [field.name]: v.filter(
                                  (item: any) => item.toLowerCase() !== "any"
                                ),
                              });
                            }
                          }}
                          value={
                            formData[field.name] ? formData[field.name] : []
                          }
                        ></TagPicker>
                      )}
                      {field?.uiElement === "singleSelectBox" && (
                        <SelectPicker
                          data={
                            field.validValues
                              ? field.validValues.map((v: string) => ({
                                  value: v,
                                  label: capitalizeFirstLetter(v),
                                }))
                              : field.validValuesStatic &&
                                field.validValuesStatic === "languages"
                              ? Object.keys(isoLangs).map((k: string) => ({
                                  value: k,
                                  label: isoLangs[k].name,
                                }))
                              : field.validValuesStatic &&
                                field.validValuesStatic === "countries"
                              ? Object.keys(countries).map((k: string) => ({
                                  value: k,
                                  label: countries[k],
                                }))
                              : []
                          }
                          className="border !border-mb-blue rounded-md w-full"
                          onChange={(v, e) =>
                            setFormData({ ...formData, [field.name]: v })
                          }
                          value={
                            formData[field.name] ? formData[field.name] : []
                          }
                        ></SelectPicker>
                      )}
                    </div>
                  )
              )}
            </div>
            <div className="mt-4 overflow-x-auto scrollbar-hide">
              <div className="flex gap-4 items-center justify-start lg:justify-end font-bold">
                <button
                  onClick={saveDraft}
                  className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
                >
                  <SaveAll size={15} />
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditAdRule;
