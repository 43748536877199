import { createSelector } from "@reduxjs/toolkit";
import GetComponent from "components/commonComponents/getComponent";
import { capitalizeFirstLetter } from "coustumFuntions/capitalFirstLetter";
import { Info, PlusCircle, X } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import SingleSelect from "./SingleSelect";
import { SelectPicker } from "rsuite";
import { Label } from "@headlessui/react/dist/components/label/label";
import { assetByPageNumber } from "helpers/mb-api_helper";

interface InputProps {
  name: string;
  props: any;
  formData?: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  i18n: string;
  disablesFields: boolean;
}

const ArrayOfObjectBox: React.FC<InputProps> = ({
  name,
  props,
  formData = {},
  setFormData,
  i18n,
  disablesFields,
}) => {
  let selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  let { meta } = useSelector(selectProperties);
  const [showHelper, setShowHelper] = useState(false);
  const [show, setShow] = useState(false);
  const [internalFormData, setInternalFormData] = useState([{}]);
  const [copyAssetType, setCopyAssetType] = useState("");
  const [copyAssetName, setCopyAssetName] = useState("");
  const [copySearchResults, setCopySearchResults] = useState([]);
  useEffect(() => {
    setShow(!show);
  }, [i18n]);
  useEffect(() => {
    if (!show) {
      if (i18n !== meta.lang) {
        if (
          !formData.i18n ||
          !formData.i18n[i18n] ||
          !formData.i18n[i18n][name] ||
          !formData[name] ||
          formData[name].length == 0
        ) {
          let newFormData = formData[name]
            ? formData[name].map((obj: any, i: number) => ({
                ...formData[name][i],
                i18n: { [i18n]: {} },
              }))
            : [];
          setInternalFormData([...newFormData]);
          setShow(true);
        } else {
          let newFormData = formData[name].map((obj: any, i: number) => {
            obj["i18n"] = { [i18n]: formData.i18n[i18n][name][i] };
            return obj;
          });
          setInternalFormData([...newFormData]);
          setShow(true);
        }
      } else {
        setInternalFormData(formData[name] || []);
        setShow(true);
      }
    }
  }, [show]);
  useEffect(() => {
    if (show) {
      if (i18n === meta.lang) {
        setFormData({ ...formData, [name]: internalFormData });
      } else {
        let newFromData = formData;
        let newInternalFormData = internalFormData.map(
          (obj: any, i: number) => {
            return obj.i18n[i18n] || {};
          }
        );
        if (!newFromData.i18n) {
          newFromData["i18n"] = {
            [i18n]: { [name]: [...newInternalFormData] },
          };
        } else if (!newFromData.i18n[i18n]) {
          newFromData.i18n = {
            ...newFromData.i18n,
            [i18n]: { [name]: [...newInternalFormData] },
          };
        } else {
          newFromData.i18n[i18n][name] = newInternalFormData;
        }
        setFormData({ ...newFromData });
      }
    }
  }, [internalFormData]);
  const searchAsset = useCallback(
    async (e: any) => {
      setCopyAssetName(e.target.value);
      let results: any = await assetByPageNumber(
        copyAssetType,
        1,
        e.target.value,
        "v_tt:desc"
      );
      if (i18n !== meta.lang) {
        setCopySearchResults(
          results.list?.map((data: any) => {
            if (!data.i18n || !data.i18n[i18n] || !data.i18n[i18n][name]) {
              return { id: data.id, title: data.title, [name]: [] };
            } else {
              return {
                id: data.id,
                title: data.title,
                [name]: data.i18n[i18n][name],
              };
            }
          })
        );
      } else {
        setCopySearchResults(
          results.list?.map((data: any) => ({
            id: data.id,
            title: data.title,
            [name]: data[name],
          }))
        );
      }
    },
    [copyAssetType]
  );
  const copyFromAsset = useCallback(
    async (data: any) => {
      setCopySearchResults([]);
      setCopyAssetName("");
      setCopyAssetType("");
      if (i18n !== meta.lang) {
        let newFromData = formData;
        if (!newFromData.i18n) {
          newFromData["i18n"] = { [i18n]: { [name]: [] } };
        } else if (!newFromData.i18n[i18n]) {
          newFromData.i18n = { ...newFromData.i18n, [i18n]: { [name]: [] } };
        } else {
          newFromData.i18n[i18n][name] = data[name];
        }
        setFormData({ ...newFromData });
      } else setFormData({ ...formData, [name]: data[name] });
      setShow(!show);
    },
    [copyAssetType, name, formData, show]
  );
  return (
    <>
      {show && (
        <div
          className={`flex card bg-gray-50 pt-4 flex-col gap-4 ${
            props.cols ? `lg:col-span-${props.cols}` : "lg:col-span-6"
          }`}
        >
          <div className="flex gap-1 px-4 items-center">
            <label className="text-sm font-bold">{props.name}</label>
            {props.longDesc && (
              <>
                <button
                  className="text-mb-blue"
                  onClick={() => setShowHelper(true)}
                >
                  <Info size={15} />
                </button>
                <Popup
                  open={showHelper}
                  onClose={() => setShowHelper(false)}
                  modal
                  lockScroll
                  closeOnDocumentClick
                  contentStyle={{
                    top: 0,
                    right: 0,
                    height: "100vh",
                    position: "fixed",
                  }}
                  overlayStyle={{
                    background: "rgba(0,0,0,0.6)",
                    zIndex: 999999,
                  }}
                >
                  <div className="h-screen bg-white w-[20rem] relative">
                    <button
                      onClick={() => setShowHelper(false)}
                      className="absolute right-4 top-4"
                    >
                      <X size={30} />
                    </button>
                    <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
                      {props.longDesc.title}
                    </div>
                    <div
                      className="text-sm text-gray-900 p-4 flex flex-col gap-4"
                      dangerouslySetInnerHTML={{ __html: props.longDesc.body }}
                    ></div>
                  </div>
                </Popup>
              </>
            )}
          </div>
          {[...Array(internalFormData.length).keys()].map((int) => (
            <div
              key={int + name}
              className="lg:grid-cols-12 grid lg:col-span-12 gap-4 p-4 relative bg-slate-100"
            >
              <button
                disabled={disablesFields || i18n !== meta.lang}
                onClick={() =>
                  setInternalFormData(
                    internalFormData.filter((ifd: any, i: number) => int !== i)
                  )
                }
                className="bg-mb-red absolute disabled:bg-gray-100 rounded-full right-1 top-1 text-white p-px"
              >
                <X size={15} />
              </button>
              {Object.keys(props.items.properties).map(
                (k: string, i: number) => (
                  <GetComponent
                    disablesFields={disablesFields}
                    i18n={i18n}
                    props={props.items.properties[k]}
                    key={k + i}
                    name={k}
                    formData={internalFormData[int] || {}}
                    setFormData={(e) => {
                      let oldFormData: any = internalFormData;
                      oldFormData[int][k] = e[k];
                      setInternalFormData([...oldFormData]);
                    }}
                  />
                )
              )}
            </div>
          ))}
          <div className="lg:col-start-12 flex justify-between w-full items-center gap-4 px-4 pb-4">
            <div className="flex justify-start gap-4 items-center">
              <label className="">Copy From</label>
              <SelectPicker
                value={copyAssetType}
                disabled={disablesFields}
                data={meta.assetTypes.map((at: string) => ({
                  value: at,
                  label: capitalizeFirstLetter(at),
                }))}
                className="border !border-mb-blue w-32 !text-black rounded-md"
                onChange={(v: string | null, e) => setCopyAssetType(v || "")}
              ></SelectPicker>
              {copyAssetType && (
                <div className="relative">
                  <input
                    disabled={disablesFields}
                    type="text"
                    className="h-9 border rounded-md border-mb-blue px-2 py-1"
                    name=""
                    value={copyAssetName}
                    onChange={searchAsset}
                  />
                  {copySearchResults.length > 0 && (
                    <div className="absolute w-full top-full left-0 bg-white flex flex-col gap-0">
                      {copySearchResults.map((res: any, i: number) => (
                        <button
                          disabled={disablesFields}
                          onClick={() => copyFromAsset(res)}
                          className="w-full px-4 py-2 border-b border-b-mb-blue/20"
                          key={res.id}
                        >
                          {res.title}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <button
              disabled={disablesFields || i18n !== meta.lang}
              onClick={() => setInternalFormData([...internalFormData, {}])}
              className="bg-mb-blue disabled:bg-gray-100 disabled:text-gray-200 text-white flex items-center lg:col-start-11 px-4 py-2 gap-2 rounded-md"
            >
              <PlusCircle size={15} /> Add{" "}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ArrayOfObjectBox;
