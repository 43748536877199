import React, { useEffect } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";

const Analytics = () => {
  const { type } = useParams<{ type: string }>(); // Destructure `type` from useParams
  const navigate = useNavigate();

  // Redirect to the respective analytics page based on the `type`
  useEffect(() => {
    navigate(`/dashboard`);
  }, []);
  return (
    <React.Fragment>
      <BreadCrumb
        title={`${type ? type.toUpperCase() : ""} DASHBOARD`}
        pageTitle="Dashboards"
      />
    </React.Fragment>
  );
};

export default Analytics;
