import { createSelector } from "@reduxjs/toolkit";
import BreadCrumb from "Common/BreadCrumb";
import Alert from "Common/Components/Alert";
import MbLoader from "Common/Components/Icons/MbLoader";
import { formatDate } from "coustumFuntions/capitalFirstLetter";
import {
  createPlatform,
  deletePlatformById,
  getPlatforms,
} from "helpers/mb-api_helper";
import { slugify } from "helpers/utility";
import SnackBar from "Layout/SnackBar";
import {
  ArrowDownUp,
  Cog,
  Copy,
  Expand,
  Menu,
  NotebookText,
  Trash2Icon,
  X,
} from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}
const headerFields: { [key: string]: any } = {
  id: {
    name: "ID",
    optional: true,
  },
  name: {
    name: "Name",
    optional: false,
  },
  slug: {
    name: "Slug",
    optional: false,
  },
  v_tt: {
    name: "Updated At",
    optional: false,
  },
  v_status: {
    name: "Status",
    optional: false,
  },
  v_parent: {
    name: "Parent Platform",
    optional: true,
  },
  v_can_extend: {
    name: "Can Extend",
    optional: true,
  },
};

const headers = [
  "name",
  "v_status",
  "slug",
  "v_tt",
  "id",
  "v_can_extend",
  "v_parent",
];
const ListPlatform: React.FC = () => {
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      acl: user.acl,
    })
  );

  const { acl } = useSelector(selectProperties);

  const [editAccess, setEditAccess] = useState<any>(false);
  const [deleteAccess, setDeleteAccess] = useState<any>(false);
  const [openDeleteViewPopup, setOpenDeleteViewPopup] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDebounced, setSearchTermDebounced] = useState("");
  const [sort, setSort] = useState("v_tt:desc");
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [initialHeaders, setInitialHeaders] = useState([
    "name",
    "v_status",
    "slug",
    "v_tt",
  ]);
  const [createError, setCreateError] = useState("");
  const [title, setTitle] = useState("");
  const [parentPlatform, setParentPlatform] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [allPlatForms, setAllPlatforms] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<AnyObject>({
    size: 20,
    count: 1,
    from: 1,
    to: 20,
  });

  const handleDeletePlatform = async () => {
    try {
      await deletePlatformById(selectedId);
      const updatedData = allPlatForms?.filter(
        (platform: any) => platform?.id !== selectedId
      );
      setAllPlatforms(updatedData);
      handleCloseDeletePopup();
    } catch (e) {
      console.error("error", e);
      setDeleteError("Unauthorized: you don't have permission to delete");
    }
  };

  const handleCloseDeletePopup = () => {
    setSelectedId("");
    setOpenDeleteViewPopup(false);
    setDeleteError("");
  };

  useEffect(() => {
    const getAllPlatforms = async () => {
      setLoading(true);
      try {
        if (searchTermDebounced) {
          let platformResults: any = await getPlatforms(
            { name: searchTerm + "*" },
            sort
          );
          setAllPlatforms(platformResults.list);
        } else {
          let platformResults: any = await getPlatforms({}, sort);
          setAllPlatforms(platformResults.list);
        }
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
        setSuccess("");
        setError("Unable to get platform list");
      }
    };
    getAllPlatforms();
  }, [searchTermDebounced, sort]);
  useEffect(() => {
    let timeout = setTimeout(() => {
      setSearchTermDebounced(searchTerm);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchTerm]);
  const createNewPlatform = useCallback(async () => {
    if (!title) {
      setCreateError("Please enter valid name for platform");
      return;
    }
    let slug = slugify(title);

    try {
      const parentPlatformData = allPlatForms?.find(
        (platform: any) => platform?.id === parentPlatform
      );
      let createResult: any = await createPlatform({
        configuration: parentPlatformData?.configuration,
        name: title,
        slug: slug,
        id: slug,
        v_parent: parentPlatform,
        v_can_extend: false,
        v_can_clone: false,
      });
      setAllPlatforms([...allPlatForms, { ...createResult }]);
      setOpenAddPopup(false);
      setParentPlatform("");
      setTitle("");
      setSuccess(`Platform ${title} created successfully!`);
    } catch (e) {
      setCreateError("Unable to create platform. Please try again");
    }
  }, [title, parentPlatform]);
  useEffect(() => {
    setEditAccess(() => {
      return (
        acl.includes("ALL") ||
        acl.includes("DRAFT_PLATFORM.CREATE") ||
        acl.includes("DRAFT_PLATFORM.UPDATE")
      );
    });
    setDeleteAccess(() => {
      return acl.includes("ALL") || acl.includes("PLATFORM.DELETE");
    });
  }, [acl]);

  return (
    <React.Fragment>
      {success && <SnackBar message={success} onClose={() => setSuccess("")} />}
      <div className="w-full min-h-screen flex flex-col text-xs">
        <BreadCrumb
          title={`Manage Platforms`}
          pageTitle="Platform Management"
        />

        <div className="card">
          <div className="card-body">
            <div className="search-filters card p-4 flex items-center justify-between gap-4">
              <input
                type="text"
                value={searchTerm}
                onChange={(e: any) => setSearchTerm(e.target.value)}
                name="search"
                placeholder="Search by platform name..."
                className="h-8 w-1/2 px-4 border border-gray-300 rounded-md grow-0"
              />

              <div className="flex flex-row gap-4 justify-end items-center">
                <Popup
                  arrow
                  on={"click"}
                  position={"bottom right"}
                  trigger={
                    <p title={`Sort`}>
                      <ArrowDownUp
                        size={15}
                        className="text-mb-blue cursor-pointer"
                      />
                    </p>
                  }
                >
                  <div className="w-[200px] flex flex-col gap-0 border border-gray-300 bg-white mt-2 rounded-md overflow-hidden">
                    <div className="bg-mb-blue text-white py-1 px-2 font-bold">
                      Choose Sort Order
                    </div>

                    <div>
                      <div className="flex items-center gap-4  px-4 py-1">
                        <input
                          onChange={(e) =>
                            e.target.checked ? setSort(`name:asc`) : setSort("")
                          }
                          checked={sort === `name:asc`}
                          type="checkbox"
                          id={`sortable-asc-title`}
                        />
                        <label htmlFor={`sortable-asc-title`}>Title ASC</label>
                      </div>
                      <div className="flex items-center gap-4  px-4 py-1">
                        <input
                          onChange={(e) =>
                            e.target.checked
                              ? setSort(`name:desc`)
                              : setSort("")
                          }
                          checked={sort === `name:desc`}
                          type="checkbox"
                          id={`sortable-desc-title`}
                        />
                        <label htmlFor={`sortable-desc-title`}>
                          Title Desc
                        </label>
                      </div>
                      <div className="flex items-center gap-4  px-4 py-1">
                        <input
                          onChange={(e) =>
                            e.target.checked ? setSort(`v_tt:asc`) : setSort("")
                          }
                          checked={sort === `v_tt:asc`}
                          type="checkbox"
                          id={`sortable-asc-v_tt`}
                        />
                        <label htmlFor={`sortable-asc-v_tt`}>
                          Updated Date ASC
                        </label>
                      </div>
                      <div className="flex items-center gap-4  px-4 py-1">
                        <input
                          onChange={(e) =>
                            e.target.checked
                              ? setSort(`v_tt:desc`)
                              : setSort("")
                          }
                          checked={sort === `v_tt:desc`}
                          type="checkbox"
                          id={`sortable-desc-v_tt`}
                        />
                        <label htmlFor={`sortable-desc-v_tt`}>
                          Updated Date Desc
                        </label>
                      </div>
                      <div className="flex items-center gap-4  px-4 py-1">
                        <input
                          onChange={(e) =>
                            e.target.checked
                              ? setSort(`v_status:asc`)
                              : setSort("")
                          }
                          checked={sort === `v_status:asc`}
                          type="checkbox"
                          id={`sortable-asc-v_status`}
                        />
                        <label htmlFor={`sortable-asc-v_status`}>
                          Status ASC
                        </label>
                      </div>
                      <div className="flex items-center gap-4  px-4 py-1">
                        <input
                          onChange={(e) =>
                            e.target.checked
                              ? setSort(`v_status:desc`)
                              : setSort("")
                          }
                          checked={sort === `v_status:desc`}
                          type="checkbox"
                          id={`sortable-desc-v_status`}
                        />
                        <label htmlFor={`sortable-desc-v_status`}>
                          Status Desc
                        </label>
                      </div>
                    </div>
                  </div>
                </Popup>
              </div>
            </div>
            <div className="errors success">
              {success && (
                <Alert className="relative px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
                  <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-green-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                  <Alert.Bold>Success! </Alert.Bold>
                  {success}
                </Alert>
              )}
              {error && (
                <Alert className="relative px-4 py-3 text-sm text-orange-500 border border-transparent rounded-md bg-orange-50 dark:bg-orange-400/20">
                  <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-orange-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                  <Alert.Bold>Error! </Alert.Bold>
                  {error}
                </Alert>
              )}
            </div>
            {!loading && (
              <div className="overflow-x-auto scrollbar-hide border border-slate-200 rounded-md">
                <table className="w-full">
                  <thead className="ltr:text-left rtl:text-right ">
                    <tr>
                      {initialHeaders.map((header: any, i: number) => (
                        <th
                          key={i}
                          className="px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                        >
                          {headerFields[header].name}
                        </th>
                      ))}
                      <th className="px-3.5 sticky right-0 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPlatForms.map((asset: any, i: number) => {
                      return (
                        <tr
                          key={asset.id}
                          className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                        >
                          {initialHeaders.map((header: any, j: number) => {
                            return (
                              <td
                                key={asset.id + j}
                                className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                              >
                                {header === "v_status" ? (
                                  <span
                                    className={`${
                                      asset[header] === "draft"
                                        ? "bg-gray-200"
                                        : "bg-mb-green"
                                    } p-1`}
                                  >
                                    {asset[header].toUpperCase()}
                                  </span>
                                ) : typeof asset[header] === "boolean" ? (
                                  <span
                                    className={
                                      asset[header]
                                        ? "text-mb-blue"
                                        : "text-mb-red"
                                    }
                                  >
                                    {asset[header].toString().toUpperCase()}
                                  </span>
                                ) : header === "v_tt" ? (
                                  formatDate(asset[header]).toString()
                                ) : (
                                  asset[header]
                                )}
                              </td>
                            );
                          })}
                          <td className="px-3.5 py-2.5 sticky right-0 border z-50 bg-white border-slate-200 dark:border-zink-500">
                            <div className="w-full flex gap-2 justify-end items-center">
                              {editAccess && asset.v_can_extend && (
                                <button
                                  data-tooltip="Extend Platform"
                                  onClick={() => {
                                    setParentPlatform(asset.id);
                                    setOpenAddPopup(true);
                                  }}
                                  title={`Extend ${asset.name} Platform`}
                                  className="w-auto py-0.5 font-bold text-mb-blue"
                                >
                                  <Expand
                                    className="inline-block mr-2"
                                    size={15}
                                  />
                                </button>
                              )}
                              {asset.v_can_clone && (
                                <button
                                  title={`Clone ${asset.name}`}
                                  className="w-auto py-0.5 font-bold text-mb-blue"
                                >
                                  <Copy
                                    className="inline-block mr-2"
                                    size={15}
                                  />
                                </button>
                              )}

                              <Link
                                to={`/platforms/config/${asset.id}`}
                                title={`Update Configuration`}
                                className="w-auto py-0.5 font-bold text-mb-blue"
                              >
                                <Cog className="inline-block mr-2" size={15} />
                              </Link>
                              <div>
                                <Link
                                  title="Manage Menus"
                                  to={`/menus/${asset.id}`}
                                  className="w-auto py-0.5 font-bold text-mb-blue"
                                >
                                  <Menu
                                    className="inline-block mr-2"
                                    size={15}
                                  />
                                </Link>
                              </div>
                              <div>
                                <Link
                                  title="Manage Pages"
                                  to={`/views/${asset.id}`}
                                  className="w-auto py-0.5 font-bold text-mb-blue"
                                >
                                  <NotebookText
                                    className="inline-block mr-2"
                                    size={15}
                                  />
                                </Link>
                              </div>

                              {editAccess && deleteAccess && (
                                <div>
                                  <button
                                    onClick={() => {
                                      setSelectedId(asset?.id);
                                      setOpenDeleteViewPopup(true);
                                    }}
                                    title={`Delete ${asset.name} Platform`}
                                    className="w-auto py-0.5 font-bold text-mb-red"
                                  >
                                    <Trash2Icon
                                      className="inline-block mr-2"
                                      size={15}
                                    />
                                  </button>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {loading && <MbLoader />}
        <Popup
          modal
          overlayStyle={{ background: "rgba(0,0,0,0.7)", zIndex: 9999999999 }}
          lockScroll
          open={openAddPopup}
          onClose={() => setOpenAddPopup(false)}
          closeOnDocumentClick
        >
          <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
            <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
              <h6>Add New</h6>
              <button onClick={() => setOpenAddPopup(false)}>
                <X size={15} />
              </button>
            </div>
            <div className="flex flex-col gap-2 px-4 py-8">
              {createError && (
                <p className="text-xs text-mb-red">{createError}</p>
              )}
              <label htmlFor="addTitle">Title</label>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                className="px-1 py-2 h-10 border border-mb-blue rounded-md"
              />
            </div>
            <div className="flex py-2 px-4 border-t border-t-mb-blue/50 justify-end gap-4 items-center">
              <button
                className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1"
                onClick={() => setOpenAddPopup(false)}
              >
                Cancel
              </button>
              <button
                className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                onClick={createNewPlatform}
              >
                Submit
              </button>
            </div>
          </div>
        </Popup>

        {/* delete warning pop up */}
        <Popup
          open={openDeleteViewPopup}
          onClose={handleCloseDeletePopup}
          modal
          lockScroll
          closeOnDocumentClick
          overlayStyle={{ background: "rgba(0,0,0,0.6)", zIndex: 999999 }}
        >
          <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
            <button
              onClick={handleCloseDeletePopup}
              className="absolute right-4 top-4"
            >
              <X size={30} />
            </button>
            <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
              Delete Selected Item
            </div>
            <div className="text-sm text-gray-900 p-4">
              Are you sure you want to delete the selected item. This action
              cannot be undone.
            </div>
            <div className="flex justify-end gap-4 p-4">
              <button
                className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1 "
                onClick={handleDeletePlatform}
              >
                Delete
              </button>
              <button
                className="bg-gray-300 text-gray-900 rounded-md hover:bg-gray-300/30 hover:text-gray-900 px-4 py-2 flex items-center gap-1"
                onClick={handleCloseDeletePopup}
              >
                Cancel
              </button>
            </div>
            <div className="flex justify-end p-4">
              {deleteError && <p className="text-red-500"> {deleteError}</p>}
            </div>
          </div>
        </Popup>
      </div>
    </React.Fragment>
  );
};

export default ListPlatform;
