// dashboard
import Ecommerce from "pages/Dashboards/Ecommerce";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import ListPlatform from "pages/PlatformManagement/ListPlatform";
import ListView from "pages/PlatformManagement/ListView";
import ListViews from "pages/PlatformManagement/ListViews";
import ListMenus from "pages/PlatformManagement/ListMenus";
import MenuSection from "pages/PlatformManagement/MenuSection";
import PlatformMenuIdEdit from "pages/PlatformManagement/PlatformMenuIdEdit";
import BulkUpload from "pages/BulkUpload/BulkUpload";
import BulkUploadAddSection from "pages/BulkUpload/BulkUploadAddSection";
import AssetsListingPage from "pages/Assets/listsing";
import AssetsEditPage from "pages/Assets/edit";
import BusinessRules from "pages/Entitlement/BusinessRules";
import EditBusinessRules from "pages/Entitlement/EditBusinessRules";
import Configuration from "pages/PlatformManagement/Configuration";
import ListRails from "pages/PlatformManagement/ListRails";
import ListRoles from "pages/Roles/ListRoles";
import RoleEdit from "pages/Roles/RoleEdit";
import UserList from "pages/Admin/UserList";
import UserEdit from "pages/Admin/UserEdit";
import Audit from "pages/Audit/Audit";
import AdRules from "pages/Entitlement/AdRules";
import EditAdRule from "pages/Entitlement/EditAdRule";
import Dashboard from "pages/Dashboards/Ecommerce/Dashboard";
import Profile from "pages/Profile/Profile";
import EditProfile from "pages/Profile/EditProfile";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },
  { path: "/dashboard/:type", component: Ecommerce },
  { path: "/reports", component: Ecommerce },
  { path: "/reports/:type", component: Ecommerce },
  { path: "/view/platforms", component: ListPlatform },
  { path: "/view/menu", component: ListPlatform },
  { path: "/assets/:type/:status/:id", component: AssetsEditPage },
  { path: "/assets/:type", component: AssetsListingPage },
  { path: "/platforms", component: ListPlatform },
  { path: "/views", component: ListViews },
  { path: "/profile", component: Profile },
  { path: "/profile/:id", component: EditProfile },
  { path: "/menus", component: ListMenus },
  { path: "/menus/:id", component: MenuSection },
  { path: "/views/:id", component: ListView },
  { path: "/platforms/config/:id", component: Configuration },
  { path: "/views/edit/:viewId", component: ListRails },
  { path: "/platforms/menus/:id", component: MenuSection },
  { path: "/platforms/menus/:id/:menuId", component: PlatformMenuIdEdit },
  { path: "/bulk-upload", component: BulkUpload },
  { path: "/bulk-upload/add", component: BulkUploadAddSection },
  { path: "/business-rules", component: BusinessRules },
  { path: "/business-rules/:id", component: EditBusinessRules },
  { path: "/ad-rules", component: AdRules },
  { path: "/ad-rules/:id", component: EditAdRule },
  { path: "/user/roles", component: ListRoles },
  { path: "/user/roles/:id", component: RoleEdit },
  { path: "/user/profile", component: UserList },
  { path: "/user/profile/:id", component: UserEdit },
  { path: "/audit", component: Audit },
];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
