import { createSelector } from "@reduxjs/toolkit";
import isoLangs from "lib/languages";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, SelectPicker, TagInput, TagPicker } from "rsuite";

interface CommonProps {
  //   handleSelectFilterData: (v: any, index: number) => void;
  index: number;
  data: any;
  disabled?: boolean;
  handleChangeFilterDataValue: (
    index: number,
    field: string,
    value: any
  ) => void;
  filterOption: any[]; // Array of valid filter options
}

const FilterEditorialSection: React.FC<CommonProps> = ({
  index,
  data,
  handleChangeFilterDataValue,
  filterOption,
  disabled = false,
}) => {
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
    })
  );
  const { meta } = useSelector(selectProperties);

  const [operatorOption, setOperatorOption] = useState([]);
  const [filterValidValues, setFilterValidValues] = useState<any>([]);
  const [filterValueUiElement, setFilterValueUiElement] = useState("");

  const handleSetOptionValidValues = (value: any, index: number) => {
    const selectedFilter = meta?.filterOptions.find(
      (obj: any) => obj.name === value
    );

    setOperatorOption(selectedFilter?.operators);

    setFilterValueUiElement(selectedFilter?.uiElement);

    if (selectedFilter?.uiElement === "multiSelect") {
      if (selectedFilter.validValuesStatic) {
        const langOptions = Object.keys(isoLangs).map((k: string) => ({
          value: k,
          label: isoLangs[k].name,
        }));
        setFilterValidValues(langOptions);
      } else {
        const validValuesOptions = selectedFilter?.validValues?.map(
          (value: string) => ({
            label: value,
            value: value,
          })
        );

        setFilterValidValues(validValuesOptions);
      }
    }
  };

  const handleSelectFilterData = (value: any, index: number) => {
    handleChangeFilterDataValue(index, "filterData", value);
    handleChangeFilterDataValue(index, "filterOperator", ""); // clear the data when change the filter data value
    handleChangeFilterDataValue(index, "filterValues", "");
    handleSetOptionValidValues(value, index);
  };

  useEffect(() => {
    handleSetOptionValidValues(data.filterData, index);
  }, []);

  return (
    <>
      <div className="flex flex-col gap-px">
        <label className="text-xs">Filter</label>
        <SelectPicker
          disabled={disabled}
          data={filterOption || []}
          className="border !border-mb-blue rounded-md w-full"
          onChange={(v) => handleSelectFilterData(v, index)}
          value={data?.filterData || ""}
        />
      </div>

      <div className="flex flex-col gap-px">
        <label className="text-xs">Operator</label>
        <SelectPicker
          disabled={disabled}
          // data={operatorOption[index] || []}
          data={operatorOption || []}
          className="border !border-mb-blue rounded-md w-full"
          onChange={(v) =>
            handleChangeFilterDataValue(index, "filterOperator", v)
          }
          value={data?.filterOperator || ""}
        />
      </div>

      <div className="flex flex-col gap-px">
        <label className="text-xs">Value</label>
        {filterValueUiElement === "multiSelect" && (
          <TagPicker
            disabled={disabled}
            data={filterValidValues || []}
            className="border !border-mb-blue rounded-md w-full"
            onChange={(v, e) =>
              handleChangeFilterDataValue(index, "filterValues", v)
            }
            value={data?.filterValues || []}
          ></TagPicker>
        )}
        {filterValueUiElement === "tagBox" && (
          <TagInput
            disabled={disabled}
            block
            className="border !border-mb-blue !disabled:border-none rounded-md w-full"
            onChange={(v, e) =>
              handleChangeFilterDataValue(index, "filterValues", v)
            }
            value={data?.filterValues || []}
          ></TagInput>
        )}
        {filterValueUiElement === "textbox" && (
          <input
            disabled={disabled}
            className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
            type={"text"}
            value={data?.filterValues || ""}
            onChange={(e) =>
              handleChangeFilterDataValue(index, "filterValues", e.target.value)
            }
          />
        )}
        {filterValueUiElement === "datepicker" && (
          <DatePicker
            disabled={disabled}
            oneTap
            format="MM/dd/yyyy HH:mm"
            className="border !border-mb-blue rounded-md w-full"
            onChange={(v, e) =>
              handleChangeFilterDataValue(index, "filterValues", v)
            }
            value={new Date(data?.filterValues) || null}
          />
        )}
      </div>
    </>
  );
};

export default FilterEditorialSection;
