import BreadCrumb from "Common/BreadCrumb";
import Alert from "Common/Components/Alert";
import MbLoader from "Common/Components/Icons/MbLoader";
import { access } from "fs/promises";
import { getRoles, getUsers, updateUsers } from "helpers/mb-api_helper";
import SnackBar from "Layout/SnackBar";
import { ShieldCheck, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { SelectPicker, Toggle } from "rsuite";

const fields = [
  // { title: "Role", accessKey: "role", isReadOnly: true },
  { title: "Email", accessKey: "email", isReadOnly: true },
  { title: "Name", accessKey: "name", isReadOnly: false },
  { title: "Mobile", accessKey: "mobile", isReadOnly: false },
  // { title: "Gender", accessKey: "gender", isReadOnly: false },
  // { title: "Date Of Birth", accessKey: "dob", isReadOnly: false },
];

const UserEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [openPasswordPopup, setOpenPasswordPopup] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [createError, setCreateError] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [rolesData, setRolesData] = useState<
    { value: string; label: string }[]
  >([]);

  const handleSubmit = async () => {
    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (userData?.email && !emailPattern.test(userData.email)) {
      return setError("Please enter a valid email address");
    }
    if (!userData?.role || userData?.role === "") {
      return setError("Please select a role");
    }

    try {
      await updateUsers(userData, id);
      setSuccess("user successfully updated");
      setTimeout(() => {
        setSuccess("");
      }, 5000);
      navigate("/user/profile");
    } catch (error) {
      setError("An error occurred while updating the user");
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  const handleChangePassword = async () => {
    if (confirmPassword.length < 3) {
      return setCreateError("Password must be at least 3 characters long");
    }
    if (confirmPassword !== userData.password) {
      return setCreateError("Passwords do not match");
    }
    try {
      await updateUsers(userData, id);
      setSuccess("Passwords updated");
      setTimeout(() => {
        setSuccess("");
      }, 5000);
      handleClosePasswordPopup();
    } catch (e) {
      setError("An error occurred while updating the user password");
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  const handleOpenPasswordPopup = () => {
    setOpenPasswordPopup(true);
  };
  const handleClosePasswordPopup = () => {
    setConfirmPassword("");
    setCreateError("");
    setOpenPasswordPopup(false);
  };

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const data = await getUsers(id);
        setUserData(data);
        setLoading(false);
      } catch (e) {
        setError("Failed to fetch user data");
        setLoading(false);
      }
    };

    const fetchRolesData = async () => {
      try {
        const roles: any = await getRoles();
        setRolesData(
          roles?.list.map((v: any) => ({ value: v.name, label: v?.name }))
        );
        setLoading(false);
        setSuccess("");
      } catch (error) {
        setError("Unable to get users list");
        setLoading(false);
      }
    };

    fetchRolesData();

    fetchUsersData();
  }, [id]);

  return (
    <React.Fragment>
      <BreadCrumb
        backRoute={`/user/profile`}
        title="Edit User"
        pageTitle="User Information"
      />
      {error && (
        <SnackBar
          className="bg-red-400"
          message={error}
          onClose={() => setError("")}
        />
      )}
      {success && (
        <Alert className="relative px-4 py-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
          <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-green-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
          <Alert.Bold>Success! </Alert.Bold>
          {success}
        </Alert>
      )}
      {loading ? (
        <MbLoader />
      ) : (
        <div className=" p-4 border rounded-lg shadow-lg">
          <div className="flex bg- justify-end p-4">
            <button
              onClick={handleOpenPasswordPopup}
              className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
            >
              Change Password
            </button>
            {/* <Toggle
              checked={userData.active}
              value={"true"}
              checkedChildren={"Active"}
              unCheckedChildren={"Inactive"}
              onChange={(v: any) =>
                setUserData((preVal: any) => ({
                  ...preVal,
                  active: v ? 1 : 0,
                }))
              }
            /> */}
          </div>
          <div className=" p-4 border border-slate-300 grid md:grid-cols-2 gap-6 ">
            <div className="flex flex-col gap-2">
              <label htmlFor="addTitle">Role</label>
              <SelectPicker
                data={rolesData}
                menuStyle={{ zIndex: 9999999999999999 }}
                // onChange={(v, e) => setFormData({ ...formData, role: v })}
                onChange={(v, e) => {
                  setUserData((preVal: any) => ({
                    ...preVal,
                    role: v,
                  }));
                }}
                value={userData?.role ? userData?.role : []}
                placeholder="Select options"
                className="border !border-mb-blue !text-black rounded-md w-full"
              ></SelectPicker>
            </div>

            {fields?.map((field) => (
              <div>
                <label className="inline-block mb-2 text-base font-medium">
                  {field?.title}
                </label>
                <input
                  type="text"
                  readOnly={field?.isReadOnly}
                  className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                  value={userData[field?.accessKey] || ""}
                  onChange={(e) => {
                    setUserData((preVal: any) => ({
                      ...preVal,
                      [field?.accessKey]: e.target.value,
                    }));
                  }}
                />
              </div>
            ))}
          </div>
          <div className="w-full flex justify-end p-5">
            <button
              type="button"
              className="bg-mb-green rounded-md text-white hover:bg-mb-green/30 hover:text-mb-green px-4 py-2 flex items-center gap-1 text-nowrap disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:text-white"
              onClick={handleSubmit}
            >
              <ShieldCheck size={15} /> Save
            </button>
          </div>
        </div>
      )}
      <Popup
        modal
        overlayStyle={{ background: "rgba(0,0,0,0.7)", zIndex: 9999999999 }}
        lockScroll
        open={openPasswordPopup}
        onClose={handleClosePasswordPopup}
        closeOnDocumentClick={false}
      >
        <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
          <div className="flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
            <h6>Add New</h6>
            <button onClick={handleClosePasswordPopup}>
              <X size={15} />
            </button>
          </div>
          <div className="flex flex-col gap-2 px-4 py-8">
            <div className="flex flex-col gap-2">
              <label htmlFor="addTitle">Password</label>
              <input
                value={userData?.password}
                onChange={(e) => {
                  setUserData({ ...userData, password: e.target.value });
                }}
                type="text"
                className="px-1 py-2 h-10 border border-mb-blue rounded-md"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="addTitle">Confirm Password</label>
              <input
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                type="text"
                className="px-1 py-2 h-10 border border-mb-blue rounded-md"
              />
            </div>
          </div>
          <div className="flex py-2 px-4 border-t border-t-mb-blue/50 justify-end gap-4 items-center">
            <button
              className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1"
              onClick={() => {
                // handleClosePasswordPopup();
              }}
            >
              Cancel
            </button>
            <button
              className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
              onClick={handleChangePassword}
            >
              Submit
            </button>
          </div>
          <div className="flex justify-end m-2">
            {createError && <p className=" text-mb-red">{createError}</p>}
          </div>
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default UserEdit;
