import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "Common/BreadCrumb";
import Select from "react-select";
import {
  getViews,
  getPlatformById,
  createView,
  searchAssets,
  filterViewByQuay,
  getPreSignedUrl,
  uploadFile,
  getMetaTemplateData,
  deleteViewById,
  assetByPageNumber,
} from "helpers/mb-api_helper";
import { slugify } from "helpers/utility";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// Icons
import {
  Search,
  Plus,
  CheckCircle,
  Loader,
  X,
  ChevronsRight,
  ChevronRight,
  ChevronLeft,
  ChevronsLeft,
  Trash2Icon,
  BadgeX,
  BadgeCheck,
  Edit3Icon,
  Expand,
} from "lucide-react";
import Popup from "reactjs-popup";
import {
  capitalizeFirstLetter,
  formatDate,
} from "coustumFuntions/capitalFirstLetter";
import { SelectPicker, Toggle } from "rsuite";

interface AnyObject {
  [key: string]: any; // Allows any key-value pairs in the user object
}

// columns
const Status = ({ item }: any) => {
  switch (item) {
    case "live":
      return (
        <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
          <CheckCircle className="size-3 mr-1.5" />
          {item}
        </span>
      );
    case "draft":
      return (
        <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
          <Loader className="size-3 mr-1.5" />
          {item}
        </span>
      );
    default:
      return (
        <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-mb-green dark:bg-mb-green/20 dark:border-transparent inline-flex items-center status">
          <CheckCircle className="size-3 mr-1.5" />
          {item}
        </span>
      );
  }
};

const viewTypeOptions = [
  { value: "single", label: "Single" },
  { value: "listing", label: "Listing" },
];

const columnObject: any = {
  id: {
    header: "Content Id",
    accessorKey: "id",
    enableColumnFilter: false,
  },
  name: {
    header: "Name",
    accessorKey: "name",
    enableColumnFilter: false,
  },
  Slug: {
    header: "Slug",
    accessorKey: "slug",
    enableColumnFilter: false,
  },
  parentView: {
    header: "Parent View",
    accessorKey: "v_parent",
    enableColumnFilter: false,
  },
  viewType: {
    header: "View Type",
    accessorKey: "view_type",
    enableColumnFilter: false,
  },
  status: {
    header: "Status",
    accessorKey: "v_status",
    enableColumnFilter: false,
    enableSorting: true,
    cell: (cell: any) => <Status item={cell.getValue()} />,
  },
};

const ListView: React.FC = () => {
  const { id } = useParams();
  const selectProperties = createSelector(
    (state: any) => state.User,
    (user) => ({
      meta: user.meta,
      acl: user.acl,
    })
  );
  const { meta, acl } = useSelector(selectProperties);
  const menuPortalRef = useRef();

  const [access, setAccess] = useState<any>({});
  const [openDeleteViewPopup, setOpenDeleteViewPopup] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [subContentTypeOptions, setSubContentTypeOptions] = useState<any>([]);
  const [subContentTypeValue, setSubContentTypeValue] = useState<any>("");
  const [views, setViews] = useState<any>([]); // State to store views data
  const [parentViews, setParentViews] = useState<any>([]); // State to store parent views data for tab change logic
  const [currViews, setCurrViews] = useState<any>([]); // State to store views data for tab change logic
  const [platformData, setPlatformData] = useState<any>([]); // State to store platform data
  const [show, setShow] = useState<boolean>(false);
  const [canExtendDisabled, setCanExtendDisabled] = useState<boolean>(false);
  const [extendFromOptions, setExtendFromOptions] = useState<any>([]);
  const [viewContentType, setViewContentType] = useState<any>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [viewContentIdOptions, setViewContentIdOptions] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0); // State to store total
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedSection, setSelectedSection] = useState(0);
  const [file, setFile] = useState("");
  const [tableHeaders] = useState([
    "name",
    "status",
    "Slug",
    "viewType",
    "id",
    "parentView",
  ]);
  const [formErrorMassage, setFormErrorMassage] = useState("");
  const [pagination, setPagination] = useState<AnyObject>({
    size: 20,
    count: 1,
    from: 1,
    to: 20,
  });
  const [parentPagination, setParentPagination] = useState<AnyObject>({
    size: 20,
    count: 0,
    from: 0,
    to: 0,
    totalCount: 0,
  });
  const [currPagination, setCurrPagination] = useState<AnyObject>({
    size: 20,
    count: 1,
    from: 1,
    to: 20,
    totalCount: 0,
  });
  const [viewContentTypeOptions, setViewContentTypeOptions] = useState(
    meta.assetTypes
      ? meta.assetTypes.map((v: any) => {
          return { label: v, value: v };
        })
      : []
  );

  const [formData, setFormData] = useState<any>({
    name: "",
    viewType: null,
    viewContentType: null,
    viewContentId: null,
    // canExtend: false,
    extendFrom: null,
    status: "draft",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    og_image: "",
  });

  const handleDeleteView = async () => {
    try {
      await deleteViewById(selectedId);
      const updatedData = views?.filter(
        (platform: any) => platform?.id !== selectedId
      );
      setViews(updatedData);
      setCurrViews(updatedData);
      handleCloseDeletePopup();
    } catch (e: any) {
      console.error("error ===== 0000", e);
      setDeleteError("Unauthorized: you don't have permission to delete");
    }
  };

  const handleCloseDeletePopup = () => {
    setSelectedId("");
    setOpenDeleteViewPopup(false);
    setDeleteError("");
  };

  const handlePageChange = async (value: number) => {
    // if (value !== pageNumber)
    try {
      const filterQuay = `filters=${
        searchTerm !== "" ? `name:${searchTerm};` : ""
      }v_platform:${
        selectedSection === 1 ? platformData?.v_parent : platformData?.id
      }&page:${value}`;
      const response: any = await filterViewByQuay(filterQuay);
      const list = response?.list;

      const updatedParentViews = list.map((extView: any) => {
        const isExtended = currViews.some(
          (curView: any) => extView.id === curView.v_parent
        );

        // Return a new object with the additional key
        return {
          ...extView,
          isExtended: isExtended,
        };
      });

      setTotalCount(response.total);
      // setPlatforms(response.list);

      let pages = Math.ceil(response.total / pagination.size);
      let f = (value - 1) * 20 + 1;
      let t = value * 20;
      if (response.total < t) t = response.total;

      if (selectedSection === 0) {
        setViews(list);
        setCurrViews(list);
        setCurrPagination({
          ...currPagination,
          count: pages,
          from: f,
          to: t,
          totalCount: response.total,
        });
      } else {
        setViews(updatedParentViews);
        setParentViews([...updatedParentViews]);
        setParentPagination({
          ...parentPagination,
          count: pages,
          from: f,
          to: t,
          totalCount: response.total,
        });
      }
      setPageNumber(value);
    } catch (e) {
      console.error("Error fetching assets:", e);
      if (selectedSection === 0) {
        setCurrPagination({
          size: 0,
          count: 0,
          from: 0,
          to: 0,
          totalCount: 0,
        });
      } else {
        setParentPagination({
          size: 0,
          count: 0,
          from: 0,
          to: 0,
          totalCount: 0,
        });
      }
    }
  };

  const handleChangeTab = async (tabIndex: number) => {
    if (tabIndex !== selectedSection) {
      setSelectedSection(tabIndex);
      setPageNumber(1);
      if (searchTerm !== "") {
        //when searchKey filtered data and we want real data thats why we called empty searchKey and called fun
        await handleSearchView("");
        setSearchTerm("");
      }
      if (tabIndex === 1) {
        setViews(parentViews);
        setPagination(parentPagination);
        setTotalCount(parentPagination?.totalCount);
      } else {
        setViews(currViews);
        setPagination(currPagination);
        setTotalCount(currPagination?.totalCount);
      }
    }
  };

  const handleSearchView = async (searchKey: string) => {
    try {
      let currentPage = 1;
      setSearchTerm(searchKey);
      const filterQuay = `filters=${
        searchKey !== "" ? `name:${searchKey}` : ""
      };v_platform:${
        selectedSection === 1 ? platformData?.v_parent : platformData?.id
      }`;
      const response: any = await filterViewByQuay(filterQuay);
      const list = response?.list;
      const updatedParentViews = list.map((extView: any) => {
        const isExtended = currViews.some(
          (curView: any) => extView.id === curView.v_parent
        );
        // Return a new object with the additional key
        return {
          ...extView,
          isExtended: isExtended,
        };
      });
      setPageNumber(1);
      setTotalCount(response.total);

      let pages = Math.ceil(response.total / pagination.size);
      let f = (currentPage - 1) * 20 + 1;
      let t = currentPage * 20;
      if (response.total < t) t = response.total;

      if (selectedSection === 0) {
        setViews(list);
        setCurrViews(list);
        setCurrPagination({
          ...currPagination,
          count: pages,
          from: f,
          to: t,
          totalCount: response.total,
        });
      } else {
        setViews(updatedParentViews);
        setParentViews([...updatedParentViews]);
        setParentPagination({
          ...parentPagination,
          count: pages,
          from: f,
          to: t,
          totalCount: response.total,
        });
      }
    } catch (e) {
      console.error("Error fetching assets:", e);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData?.name === "") {
      setFormErrorMassage("Please enter a name for the view.");
      return;
    }
    if (formData?.viewType === "single")
      if (!viewContentType || viewContentType === "") {
        setFormErrorMassage("Please select content type.");
        return;
      }

    try {
      const data = {
        name: formData?.name,
        slug: slugify(formData?.name),
        id:
          formData?.viewType === "single"
            ? formData.viewContentId
              ? `details-${formData?.viewContentId}`
              : `details-${viewContentType}${
                  subContentTypeValue ? `-${subContentTypeValue}` : ""
                }`
            : slugify(formData?.name),
        // v_can_extend: formData?.canExtend,
        v_status: formData?.status,
        v_parent: formData?.extendFrom,
        v_platform: id,
        view_type: formData?.viewType,
      };

      const postData =
        formData?.viewType === "single"
          ? {
              ...data,
              view_content_type: viewContentType,
              view_content_id: formData?.viewContentId,
              view_content_sub_type: subContentTypeValue,
            }
          : data;

      const response: any = await createView(JSON.stringify(postData));
      const currentPage = 1;
      let pages = Math.ceil((currPagination.totalCount + 1) / pagination.size);
      let f = (currentPage - 1) * 20 + 1;
      let t = currentPage * 20;
      if (currPagination.totalCount + 1 < t) t = currPagination.totalCount + 1;
      setFile("");
      setTotalCount(currPagination.totalCount + 1);

      setViews([response, ...currViews]);
      setCurrViews((prev: any) => [response, ...prev]);
      setCurrPagination({
        ...currPagination,
        count: pages,
        from: f,
        to: t,
        totalCount: currPagination.totalCount + 1,
      });
      setPagination({
        ...currPagination,
        count: pages,
        from: f,
        to: t,
        totalCount: currPagination.totalCount + 1,
      });

      setSelectedSection(0);
      setFormData({
        name: "",
        viewType: null,
        viewContentType: null,
        viewContentId: null,
        // canExtend: false,
        extendFrom: null,
        status: "draft",
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
        og_image: "",
      });
      setCanExtendDisabled(false);
      setViewContentType(null);
      setFormErrorMassage("");
      setSubContentTypeValue("");
      setShow(false);
    } catch (error) {
      console.error(error);
      setFormErrorMassage("Something went wrong please try again");
      setTimeout(() => {
        setFormErrorMassage("");
      }, 6000);
    }
  };

  const handleExtendView = async (data: any) => {
    try {
      const postData = {
        name: data.name,
        slug: data.slug,
        id:
          data?.view_type === "single"
            ? data.view_content_id
              ? `details-${data?.view_content_id}`
              : `details-${data.view_content_type}${
                  data?.view_content_sub_type
                    ? `-${data?.view_content_sub_type}`
                    : ""
                }`
            : slugify(data?.name),
        v_can_extend: false,
        v_status: "draft",
        v_parent: data.id,
        v_platform: id,
        view_type: data.view_type,
        view_content_type: data.view_content_type,
        view_content_id: data.view_content_id,
      };
      const response: any = await createView(JSON.stringify(postData)); // Await the API call to create views
      const newViewData = response;

      let pages = Math.ceil((currPagination.totalCount + 1) / pagination.size);
      let f = (pageNumber - 1) * 20 + 1;
      let t = pageNumber * 20;
      if (currPagination.totalCount + 1 < t) t = currPagination.totalCount + 1;

      setCurrPagination({
        ...currPagination,
        count: pages,
        from: f,
        to: t,
        totalCount: currPagination.totalCount + 1,
      });
      setPagination({
        ...currPagination,
        count: pages,
        from: f,
        to: t,
        totalCount: currPagination.totalCount + 1,
      });

      const filteredRecords = currViews.filter(
        (view: any) => view.id !== newViewData.v_parent
      );
      filteredRecords.push(newViewData);

      const updatedParentViews =
        parentViews?.length > 0
          ? parentViews.map((extView: any) => {
              const isExtended = filteredRecords.some(
                (curView: any) => extView.id === curView.v_parent
              );

              // Return a new object with the additional key
              return {
                ...extView,
                isExtended: isExtended,
              };
            })
          : [];
      setTotalCount(currPagination.totalCount + 1);
      setViews(filteredRecords);
      setCurrViews(filteredRecords);
      setParentViews(updatedParentViews);
      setSelectedSection(0);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenPopup = (value: boolean) => {
    if (!value) {
      setFormData({
        name: "",
        viewType: null,
        viewContentType: null,
        viewContentId: null,
        status: "draft",
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
        og_image: "",
      });
      setFile("");
      setShow(false);
      setCanExtendDisabled(false);
    } else {
      setShow(true);
    }
  };

  const handleChangeName = (value: string, key: string) => {
    if (formErrorMassage !== "") setFormErrorMassage("");
    setFormData(() => ({ ...formData, [key]: value }));
  };

  const onUpload = async (e: any) => {
    let file = e.target.files[0];
    let fileName = uuidv4();
    try {
      let signedUrl: any = await getPreSignedUrl(
        `images/${fileName}.${file.type.replace("image/", "")}`
      );
      let uploadFileRes = await uploadFile(signedUrl.url, file);
      setFile(signedUrl.file);
      setFormData({ ...formData, og_image: signedUrl.file });
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeViewType = (selected: any) => {
    setFormData(() => ({ ...formData, viewType: selected.value }));
  };
  const handleChangeViewContentType = async (value: any) => {
    setFormErrorMassage("");
    setViewContentType(value);
    try {
      const metaTemplateRes: any = await getMetaTemplateData(value);
      const properties = metaTemplateRes?.properties?.subType?.validValues;

      if (properties)
        setSubContentTypeOptions(
          properties.map((data: any) => ({
            label: capitalizeFirstLetter(data),
            value: data,
          }))
        );
    } catch (e) {}
  };
  const handleChangeViewContentId = (selected: any) => {
    setFormData(() => ({ ...formData, viewContentId: selected.value }));
  };
  const handleChangeSearch = (newValue: any) => {
    setSearchQuery(newValue);
  };

  useEffect(() => {
    let getData = async () => {
      if (viewContentType) {
        try {
          // Await the API call to fetch assets of type
          let assetsRes: any;
          assetsRes = await assetByPageNumber(
            viewContentType, //assetType
            1, //page number
            searchQuery ?? "", //search query
            "v_tt:desc" //sort option
          );

          let assets = assetsRes.list;

          const fetchedOptions = assets.map((item: any) => ({
            value: item.id,
            label: item.title,
          }));
          //
          setViewContentIdOptions(fetchedOptions);
        } catch (e) {
          console.error(e);
        }
      }
    };
    getData();
  }, [searchQuery, viewContentType]);

  // useEffect(() => {
  //
  //
  // }, [views, extendFromOptions]);

  useEffect(() => {
    let getData = async () => {
      let currentPage = 1;
      try {
        const platRes: any = await getPlatformById(id); // Await the API call to fetch view by id
        setPlatformData(platRes);
        if (platRes.v_parent) {
          try {
            // parent view views
            let parentPlatFilters: any = {
              v_platform: platRes.v_parent,
            };
            const parentViewRes: any = await getViews(parentPlatFilters); // Await the API call to fetch view by view
            let parentViewRecords = parentViewRes.list;
            //
            let pages = Math.ceil(parentViewRes.total / pagination.size);
            let f = (currentPage - 1) * 20 + 1;
            let t = currentPage * 20;
            if (parentViewRes.total < t) t = parentViewRes.total;
            setParentPagination({
              ...parentPagination,
              count: pages,
              from: f,
              to: t,
              totalCount: parentViewRes.total,
            });

            try {
              // current view views
              let currPlatFilters: any = {
                v_platform: id,
              };
              const currViewRes: any = await getViews(currPlatFilters); // Await the API call to fetch view by view
              let currViewRecords = currViewRes.list;

              let pages = Math.ceil(currViewRes.total / pagination.size);
              let f = (currentPage - 1) * 20 + 1;
              let t = currentPage * 20;
              if (currViewRes.total < t) t = currViewRes.total;

              setCurrPagination({
                ...currPagination,
                count: pages,
                from: f,
                to: t,
                totalCount: currViewRes.total,
              });
              setPagination({
                ...currPagination,
                count: pages,
                from: f,
                to: t,
              });

              const updatedParentViews = parentViewRecords.map(
                (extView: any) => {
                  const isExtended = currViewRecords.some(
                    (curView: any) => extView.id === curView.v_parent
                  );

                  // Return a new object with the additional key
                  return {
                    ...extView,
                    isExtended: isExtended,
                  };
                }
              );
              setTotalCount(currViewRes.total);
              setViews(currViewRecords);
              setCurrViews(currViewRecords);
              setParentViews(updatedParentViews);
              setExtendFromOptions(
                Object.keys(currViewRecords).map((v: any) => {
                  return {
                    value: currViewRecords[v].id,
                    label: currViewRecords[v].id,
                  };
                })
              );
            } catch (e) {
              console.error(e);
              setCurrPagination({
                size: 0,
                count: 0,
                from: 0,
                to: 0,
                totalCount: 0,
              });
              setCurrViews([]);
            }
          } catch (e) {
            console.error(e);
            setParentPagination({
              size: 0,
              count: 0,
              from: 0,
              to: 0,
              totalCount: 0,
            });
            setParentViews([]);
          }
        } else {
          try {
            // current view views
            let currPlatFilters: any = {
              v_platform: id,
            };
            const currViewRes: any = await getViews(currPlatFilters); // Await the API call to fetch view by view
            let currViewRecords = currViewRes.list;

            setViews([...currViewRecords]);
            setTotalCount(currViewRes.total);
            setExtendFromOptions(
              Object.keys(currViewRecords).map((v: any) => {
                return {
                  value: currViewRecords[v].id,
                  label: currViewRecords[v].id,
                };
              })
            );
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    getData();
  }, []);
  useEffect(() => {
    setAccess(() => ({
      edit:
        acl.includes("ALL") ||
        acl.includes("DRAFT_VIEW.CREATE") ||
        acl.includes("DRAFT_VIEW.UPDATE"),
      create: acl.includes("ALL") || acl.includes("VIEW.CREATE"),
      delete: acl.includes("ALL") || acl.includes("VIEW.DELETE"),
    }));
  }, [acl]);
  return (
    <React.Fragment>
      <BreadCrumb
        backRoute="/views"
        title={`Manage Pages (Platform ${id})`}
        pageTitle="Storefront"
      />
      {/* <ToastContainer closeButton={false} limit={1} /> */}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12 text-xs">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="search-filters card p-4 flex items-center justify-between gap-4">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => handleSearchView(e.target.value)}
                  name="search"
                  placeholder="Search or Filter anything..."
                  className="h-8 w-1/2 px-4 border border-gray-300 rounded-md grow-0"
                />
                {access?.create && (
                  <div className="flex flex-row gap-4 justify-end items-center">
                    <button
                      onClick={() => handleOpenPopup(true)}
                      className="bg-mb-blue rounded-md text-white hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
                    >
                      Add New <Plus size={10} />{" "}
                    </button>
                  </div>
                )}
              </div>

              <div className="tabs flex flex-nowrap items-center justify-start gap-0 max-w-full overflow-x-auto scrollbar-hide">
                <button
                  onClick={() => {
                    handleChangeTab(0);
                  }}
                  className={`${
                    selectedSection === 0
                      ? "bg-white border-b-0 text-mb-blue"
                      : "bg-mb-blue/10 hover:bg-mb-blue/20"
                  } rounded-tl-md border border-slate-200 px-4 py-2 flex items-center justify-center gap-2 text-nowrap min-w-[150px] text-center transition-all duration-500`}
                >
                  <p className="capitalize">{platformData?.name} (Current)</p>
                </button>
                {platformData?.v_parent && (
                  <button
                    onClick={() => {
                      setSelectedSection(1);
                      handleChangeTab(1);
                    }}
                    className={`${
                      selectedSection === 1
                        ? "bg-white border-b-0 text-mb-blue"
                        : "bg-mb-blue/10 hover:bg-mb-blue/20"
                    }  rounded-tr-md border border-slate-200 px-4 py-2 flex items-center justify-center gap-2 text-nowrap min-w-[150px] text-center transition-all duration-500`}
                  >
                    <p className="capitalize">
                      {platformData?.v_parent} (Parent)
                    </p>
                  </button>
                )}
              </div>

              {views && views.length > 0 ? (
                <>
                  <div
                    className={`overflow-x-auto border border-slate-200 rounded-md -mt-[2px] rounded-tl-none`}
                  >
                    <table className="w-full">
                      <thead className="ltr:text-left rtl:text-right ">
                        <tr>
                          {tableHeaders.map((header: any, i: number) => (
                            <th
                              key={i}
                              className=" px-3.5 min-w-[200px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                            >
                              <p className="capitalize">
                                {columnObject[header].header}
                              </p>
                            </th>
                          ))}
                          {access?.edit && (
                            <th className="px-3.5 sticky right-0 min-w-[100px] py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right bg-white">
                              Actions
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {views.map((view: any, i: number) => (
                          <tr
                            key={view.id + i}
                            className={`${
                              view?.isExtended
                                ? "!bg-gray-200 cursor-not-allowed"
                                : ""
                            } even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600`}
                          >
                            {tableHeaders.map((header: any, j: number) => {
                              return (
                                <td
                                  key={header}
                                  className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500"
                                >
                                  {(j === 0 || header === "id") &&
                                  header !== "title" &&
                                  !view?.isExtended ? (
                                    access?.edit ? (
                                      <Link
                                        className="transition-all duration-150 ease-linear text-mb-blue hover:text-mb-blue/60"
                                        to={`/views/edit/${view.id}`}
                                      >
                                        {view[columnObject[header].accessorKey]}
                                      </Link>
                                    ) : (
                                      <p>
                                        {view[columnObject[header].accessorKey]}
                                      </p>
                                    )
                                  ) : header === "v_tt" ? (
                                    formatDate(
                                      view[columnObject[header].accessorKey]
                                    ).toString()
                                  ) : header === "v_status" ? (
                                    <span
                                      className={`${
                                        view[
                                          columnObject[header].accessorKey
                                        ] === "draft"
                                          ? "bg-gray-200"
                                          : "bg-mb-green"
                                      } p-1`}
                                    >
                                      {view[
                                        columnObject[header].accessorKey
                                      ].toUpperCase()}
                                    </span>
                                  ) : (
                                    view[columnObject[header].accessorKey]
                                  )}
                                </td>
                              );
                            })}
                            {access?.edit && (
                              <td
                                className={`px-3.5 py-2.5 sticky right-0 border z-50 ${
                                  view?.isExtended ? "" : "bg-white"
                                } border-slate-200 dark:border-zink-500`}
                              >
                                {view.v_platform === id ? (
                                  <div className="w-full flex gap-2 justify-end">
                                    <div>
                                      <Link
                                        to={`/views/edit/${view.id}`}
                                        className="w-auto py-0.5 font-bold text-mb-blue"
                                      >
                                        <Edit3Icon
                                          className="inline-block mr-2"
                                          size={15}
                                        />
                                      </Link>
                                    </div>
                                    {access?.delete && (
                                      <div>
                                        <button
                                          onClick={() => {
                                            setSelectedId(view?.id);
                                            setOpenDeleteViewPopup(true);
                                          }}
                                          className="w-auto py-0.5 font-bold text-mb-red"
                                        >
                                          <Trash2Icon
                                            className="inline-block mr-2"
                                            size={15}
                                          />
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="w-full flex gap-2 justify-end items-center">
                                    <div>
                                      {view?.isExtended ? (
                                        <span className="text-gray-500">
                                          Extended
                                        </span>
                                      ) : (
                                        <button
                                          // to={`/platforms/views/${id}/${view.id}`}
                                          onClick={() => handleExtendView(view)}
                                          className="w-auto py-0.5 font-bold text-mb-blue"
                                        >
                                          <Expand
                                            className="inline-block mr-2"
                                            size={15}
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {pagination.count > 0 && (
                    <div className="pagination flex items-center justify-between py-4">
                      <div className="flex flex-col gap-2">
                        <p className="">
                          Showing From {pagination.from} to {pagination.to} of{" "}
                          {totalCount}
                        </p>
                        <div className="flex gap-2">
                          <p className="">Go to Page</p>
                          <select
                            className=""
                            onChange={(e: any) =>
                              handlePageChange(e.target.value)
                            }
                          >
                            {[...Array(pagination.count).keys()].map((int) => (
                              <option
                                selected={pageNumber === int + 1}
                                value={int + 1}
                              >
                                {int + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <ul className="flex flex-wrap items-center gap-2">
                        {pageNumber > 1 && (
                          <li>
                            <button
                              onClick={() => handlePageChange(1)}
                              className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                            >
                              <ChevronsLeft className="size-4 rtl:rotate-180" />
                            </button>
                          </li>
                        )}
                        {pageNumber > 1 && (
                          <li>
                            <button
                              onClick={() => handlePageChange(pageNumber - 1)}
                              className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                            >
                              <ChevronLeft className="size-4 rtl:rotate-180" />
                            </button>
                          </li>
                        )}
                        {[...Array(pagination.count).keys()].map((int) => {
                          return int + 1 >= pageNumber - 1 &&
                            int + 1 <= pageNumber + 1 ? (
                            <li
                              className={pageNumber === int + 1 ? "active" : ""}
                            >
                              <button
                                onClick={() => handlePageChange(int + 1)}
                                className={`${
                                  pageNumber === int + 1 ? "active" : ""
                                } inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto`}
                              >
                                {int + 1}
                              </button>
                            </li>
                          ) : (
                            <li>.</li>
                          );
                        })}

                        {pageNumber < pagination.count && (
                          <li>
                            <button
                              onClick={() => handlePageChange(pageNumber + 1)}
                              className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                            >
                              <ChevronRight className="size-4 rtl:rotate-180" />
                            </button>
                          </li>
                        )}
                        {pageNumber < pagination.count && (
                          <li>
                            <button
                              onClick={() => handlePageChange(pagination.count)}
                              className="inline-flex items-center justify-center bg-white size-8 transition-all duration-150 ease-linear border rounded border-slate-200 text-slate-500 hover:text-custom-500 hover:bg-custom-50 focus:bg-custom-50 focus:text-custom-500 [&.active]:text-custom-50 [&.active]:bg-custom-500 [&.active]:border-custom-500 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto"
                            >
                              <ChevronsRight className="size-4 rtl:rotate-180" />
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </>
              ) : (
                <div className="noresult -mt-[2px] card rounded-tl-none">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-mb-blue fill-sky-100 dark:mb-blue/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* add view Modal */}
      <Popup
        modal
        className="addPagePopup"
        open={show}
        onClose={() => handleOpenPopup(false)}
        overlayStyle={{
          background: "rgba(0,0,0,0.7)",
          zIndex: 9999999999999,
        }}
        closeOnDocumentClick={false}
      >
        <div className="bg-white flex flex-col rounded-md md:w-[30rem] h-full max-h-[80vh] overflow-y-scroll">
          <div className=" sticky top-0 bg-white flex py-2 px-4 border-b border-b-mb-blue/50 justify-between items-center">
            <h6> Add Page</h6>
            <button onClick={() => handleOpenPopup(false)}>
              <X size={15} />
            </button>
          </div>

          <div className="mx-4 mt-3">
            <div className="mb-3">
              <label
                htmlFor="name"
                className="inline-block mb-2 text-base font-medium"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="px-1 py-2 h-9 border !border-mb-blue rounded-md w-full disabled:bg-gray-100 disabled:cursor-not-allowed"
                placeholder="Enter name"
                name="name"
                onChange={(e) => handleChangeName(e.target.value, "name")}
                value={formData?.name}
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="type"
                className="inline-block mb-2 text-base font-medium"
              >
                Type
              </label>
              <Select
                value={
                  formData?.viewType
                    ? { value: formData?.viewType, label: formData?.viewType }
                    : null
                }
                onChange={handleChangeViewType}
                options={viewTypeOptions}
                placeholder="Select options"
                className="selectize capitalize border !border-mb-blue !text-black rounded-md w-full"
              />
            </div>

            {formData?.viewType === "single" && (
              <>
                <div className="mb-3 z-20">
                  <label
                    htmlFor="contentType"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Content Type
                  </label>
                  <SelectPicker
                    value={viewContentType}
                    onChange={handleChangeViewContentType}
                    menuStyle={{ zIndex: 9999999999999999 }}
                    data={viewContentTypeOptions}
                    placeholder="Select options"
                    className="border !border-mb-blue !text-black rounded-md w-full"
                  />
                </div>
                {viewContentType && (
                  <div className="mb-3">
                    <label
                      htmlFor="contentSubType"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Content Sub Type
                    </label>
                    <SelectPicker
                      value={subContentTypeValue}
                      onChange={(selected: any) =>
                        setSubContentTypeValue(selected)
                      }
                      menuStyle={{ zIndex: 9999999999999999 }}
                      data={subContentTypeOptions}
                      placeholder="Select options"
                      className="selectize capitalize border !border-mb-blue !text-black rounded-md w-full"
                    />
                  </div>
                )}
                <div className="mb-3">
                  <label
                    htmlFor="contentId"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Content
                  </label>
                  <Select
                    value={
                      formData?.viewContentId
                        ? viewContentIdOptions.filter(
                            (value: any) =>
                              value.value === formData.viewContentId
                          )
                        : null
                    }
                    onChange={handleChangeViewContentId}
                    onInputChange={handleChangeSearch}
                    options={viewContentIdOptions}
                    placeholder="Select options"
                    className="selectize capitalize border !border-mb-blue !text-black rounded-md w-full"
                  />
                </div>
              </>
            )}
          </div>
          <p className="my-2 px-4 text-red-700">{formErrorMassage}</p>

          <div className=" sticky bottom-0 bg-white flex py-2 px-4 border-t border-t-mb-blue/50 justify-end gap-4 items-center">
            <button
              className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1"
              onClick={() => handleOpenPopup(false)}
            >
              Cancel
            </button>
            <button
              className="bg-mb-blue text-white rounded-md hover:bg-mb-blue/30 hover:text-mb-blue px-4 py-2 flex items-center gap-1"
              onClick={handleSubmit}
            >
              Add
            </button>
          </div>
        </div>
      </Popup>

      {/* delete Po up */}
      <Popup
        open={openDeleteViewPopup}
        onClose={handleCloseDeletePopup}
        modal
        lockScroll
        closeOnDocumentClick
        overlayStyle={{ background: "rgba(0,0,0,0.6)", zIndex: 999999 }}
      >
        <div className="bg-white flex flex-col rounded-md md:w-[30rem]">
          <button
            onClick={handleCloseDeletePopup}
            className="absolute right-4 top-4"
          >
            <X size={30} />
          </button>
          <div className="text-xl text-mb-blue border-b border-b-mb-blue font-bold p-4">
            Delete Selected Item
          </div>
          <div className="text-sm text-gray-900 p-4">
            Are you sure you want to delete the selected item. This action
            cannot be undone.
          </div>
          <div className="flex justify-end gap-4 p-4">
            <button
              className="bg-mb-red text-white rounded-md hover:bg-mb-red/30 hover:text-mb-red px-4 py-2 flex items-center gap-1 "
              onClick={handleDeleteView}
            >
              Delete
            </button>
            <button
              className="bg-gray-300 text-gray-900 rounded-md hover:bg-gray-300/30 hover:text-gray-900 px-4 py-2 flex items-center gap-1"
              onClick={handleCloseDeletePopup}
            >
              Cancel
            </button>
          </div>
          <div className="flex justify-end p-4">
            {deleteError && <p className="text-red-500"> {deleteError}</p>}
          </div>
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default ListView;
